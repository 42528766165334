import { NftOwnerState } from 'state/types'

const getSortOption = (t): Array<{
  label: string
  value: string
  id: string
}> => [
  {
    label: `${t('Time')}: ${t('High to Low')}`,
    value: '-start_time',
    id: '-start_time',
  },
  {
    label: `${t('Time')}: ${t('Low to High')}`,
    value: 'start_time',
    id: 'start_time',
  },
  {
    label: `${t('Price')}: ${t('High to Low')}`,
    value: '-max_price_usd',
    id: '-max_price_usd',
  },
  {
    label: `${t('Price')}: ${t('Low to High')}`,
    value: 'max_price_usd',
    id: 'max_price_usd',
  },
  {
    label: `${t('Level')}: ${t('High to Low')}`,
    value: '-metadata.properties.level',
    id: '-metadata.properties.level',
  },
  {
    label: `${t('Level')}: ${t('Low to High')}`,
    value: 'metadata.properties.level',
    id: 'metadata.properties.level',
  },
  {
    label: `${t('Power')}: ${t('High to Low')}`,
    value: '-metadata.properties.power',
    id: '-metadata.properties.power',
  },
  {
    label: `${t('Power')}: ${t('Low to High')}`,
    value: 'metadata.properties.power',
    id: 'metadata.properties.power',
  },
]

const getSortHistoryOption = (t): Array<{
  label: string
  value: string
  id: string
}> => [
  {
    label: `${t('Time')}: ${t('High to Low')}`,
    value: '-final_time',
    id: '-final_time',
  },
  {
    label: `${t('Time')}: ${t('Low to High')}`,
    value: 'final_time',
    id: 'final_time',
  },
  {
    label: `${t('Price')}: ${t('High to Low')}`,
    value: '-final_price_usd',
    id: '-final_price_usd',
  },
  {
    label: `${t('Price')}: ${t('Low to High')}`,
    value: 'final_price_usd',
    id: 'final_price_usd',
  },
  {
    label: `${t('Level')}: ${t('High to Low')}`,
    value: '-metadata.properties.level',
    id: '-metadata.properties.level',
  },
  {
    label: `${t('Level')}: ${t('Low to High')}`,
    value: 'metadata.properties.level',
    id: 'metadata.properties.level',
  },
  {
    label: `${t('Power')}: ${t('High to Low')}`,
    value: '-metadata.properties.power',
    id: '-metadata.properties.power',
  },
  {
    label: `${t('Power')}: ${t('Low to High')}`,
    value: 'metadata.properties.power',
    id: 'metadata.properties.power',
  },
]

const getFillterBagOption = (t): Array<{
  label: string
  value: NftOwnerState|string
  id: NftOwnerState|string
}> => [
  {
    label: t('All'),
    value: 'all',
    id: 'all',
  },
  {
    label: t('Normal'),
    value: NftOwnerState.NORMAL,
    id: NftOwnerState.NORMAL,
  },
  {
    label: t('Selling'),
    value: NftOwnerState.SELLING,
    id: NftOwnerState.SELLING,
  },
  {
    label: t('Earning'),
    value: NftOwnerState.STAKING,
    id: NftOwnerState.STAKING,
  },
  {
    label: t('Unopened'),
    value: NftOwnerState.WAIT_OPEN_BOX,
    id: NftOwnerState.WAIT_OPEN_BOX,
  },
]


export enum methods {
  NFT_GET_SORT_OPTION = 'nft/getSortOption',
  NFT_GET_HISTORY_OPTION = 'nft/getSortHistoryOption',
  NFT_GET_FILTER_BAG_OPTION = 'nft/getFillterBagOption',
}

export default {
  [methods.NFT_GET_SORT_OPTION]: getSortOption,
  [methods.NFT_GET_HISTORY_OPTION]: getSortHistoryOption,
  [methods.NFT_GET_FILTER_BAG_OPTION]: getFillterBagOption,
}
