import invariant from 'tiny-invariant'
import { ChainId } from '../chainId'
import { validateAndParseAddress } from '../utils'
import { Currency } from './currency'

/**
 * Represents an ERC20 token with a unique address and some metadata.
 */
export class Token extends Currency {
  public readonly chainId: ChainId
  public readonly address: string

  public constructor(chainId: ChainId, address: string, decimals: number, symbol?: string, name?: string) {
    super(decimals, symbol, name)
    this.chainId = chainId
    this.address = validateAndParseAddress(address)
  }

  /**
   * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
   * @param other other token to compare
   */
  public equals(other: Token): boolean {
    // short circuit on reference equality
    if (this === other) {
      return true
    }
    return this.chainId === other.chainId && this.address === other.address
  }

  /**
   * Returns true if the address of this token sorts before the address of the other token
   * @param other other token to compare
   * @throws if the tokens have the same address
   * @throws if the tokens are on different chains
   */
  public sortsBefore(other: Token): boolean {
    invariant(this.chainId === other.chainId, 'CHAIN_IDS')
    invariant(this.address !== other.address, 'ADDRESSES')
    return this.address.toLowerCase() < other.address.toLowerCase()
  }
}

/**
 * Compares two currencies for equality
 */
export function currencyEquals(currencyA: Currency, currencyB: Currency): boolean {
  if (currencyA instanceof Token && currencyB instanceof Token) {
    return currencyA.equals(currencyB)
  } else if (currencyA instanceof Token) {
    return false
  } else if (currencyB instanceof Token) {
    return false
  } else {
    return currencyA === currencyB
  }
}

export interface TokenForChainId {
  [ChainId.MAINNET]?: Token
  [ChainId.TESTNET]?: Token
  [ChainId.MATIC_MAINNET]?: Token
  [ChainId.MATIC_TESTNET]?: Token
}

// export const MBT = {
//   [ChainId.MAINNET]: new Token(
//     ChainId.MAINNET,
//     '0x165c1eb65ea235dd75b26bca48bc8b1be8e82ec2',
//     18,
//     'MBT',
//     'Mbt',
//   ),
//   [ChainId.MATIC_TESTNET]: new Token(
//     ChainId.MATIC_TESTNET,
//     '0x165c1eb65ea235dd75b26bca48bc8b1be8e82ec2',
//     18,
//     'MBT',
//     'Mbt',
//   ),
//   [ChainId.MATIC_MAINNET]: new Token(
//     ChainId.MATIC_MAINNET,
//     '0x48F1935187D71990ded5c5c3fC65F2b9896380b5', // TODO: 更换地址
//     18,
//     'MBT',
//     'Mbt',
//   ),
//   [ChainId.TESTNET]: new Token(
//     ChainId.TESTNET,
//     '0x48F1935187D71990ded5c5c3fC65F2b9896380b5',
//     18,
//     'MBT',
//     'Mbt',
//   ),
// }
