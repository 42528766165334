import aggregatorABI from 'config/abi/aggregator.json'
import dsgnftAbi from 'config/abi/dsgnft.json'
import erc20ABI from 'config/abi/erc20.json'
import uniq from 'lodash/uniq'
import multicall from 'utils/multicall'
import { getAddress, getAggregatorAddress, getDsgAddress, getNftMarketAddress } from 'utils/addressHelpers'
import { getNftsList } from 'config/axios'
import { nftSupportSymbols } from 'config/constants/nftsConfig'
import { NftInfo } from 'state/types'
import { ETHER } from 'dsgswap-sdk'

const fetchUserMiningInfos = async (account: string) => {
  const calls = [
    {
      address: getAggregatorAddress(),
      name: 'getUserMiningInfos', // Function name on the contract (example: balanceOf)
      params: [account],
    },
  ]

  const [data] = await multicall(aggregatorABI, calls)
  const res = data[0][0]
  return {
    userAmount: res.userAmount.toJSON().hex,
    userUnclaimedReward: res.userUnclaimedReward.toJSON().hex,
    userAccReward: res.userAccReward.toJSON().hex,
    poolType: res.poolType,
    pid: res.pid.toJSON().hex,
    pair: res.pair,
    totalAmount: res.totalAmount.toJSON().hex,
    rewardsPerBlock: res.rewardsPerBlock.toJSON().hex,
    allocPoint: res.allocPoint.toJSON().hex,
    token0: res.token0,
    name0: res.name0,
    symbol0: res.symbol0,
    decimals0: res.decimals0,
    token1: res.token1,
    name1: res.name1,
    symbol1: res.symbol1,
    decimals1: res.decimals1,
  }
}

export const fetchNftUserData = async (account) => {
  try {
    const nfts = await getNftsList(account)
    const data = (nfts || []).map((item: NftInfo) => ({
      ...item,
      key: `${item.properties.token}_${item.properties.token_id}_${item.properties.owner_status}`,
    }))
    return {
      data,
    }
  } catch (error) {
    return {
      data: [],
    }
  }
}

export const fetchNftAllowance = async (account, nftInfos: NftInfo[]) => {
  const tokens = nftInfos.map((farm) => farm.properties.token)
  const tokensUniq = uniq(tokens)
  const dsgAddress = getDsgAddress()
  try {
    const calls = tokensUniq.map((token) => {
      return {
        address: dsgAddress,
        name: 'allowance',
        params: [account, token],
      }
    })
    const userInfo = await multicall(erc20ABI, calls)
    return userInfo.map((item, index) => ({
      token: tokensUniq[index],
      isAllowanceNft: item[0].toJSON().hex,
    }))
  } catch (error) {
    return []
  }
}

export const fetchNftInfoOfSumRoyalties = async (account, nftInfos: NftInfo[]) => {
  const tokens = nftInfos.map((farm) => farm.properties.token)
  const tokensUniq = uniq(tokens)
  const dsgAddress = getDsgAddress()
  try {
    const calls = tokensUniq.map((token) => {
      return {
        address: token,
        name: 'allowance',
        params: [account, token],
      }
    })
    const userInfo = await multicall(erc20ABI, calls)
    return userInfo.map((item, index) => ({
      token: tokensUniq[index],
      isAllowanceNft: item[0].toJSON().hex,
    }))
  } catch (error) {
    return []
  }
}

export const fetchaMarketllowance = async (account) => {
  try {
    const masterChefAddress = getNftMarketAddress()
    const address = nftSupportSymbols.filter((item) => item.value.symbol !== ETHER.symbol)
    const calls = address.map((symbol) => {
      return {
        address: symbol.value.address,
        name: 'allowance', // Function name on the contract (example: balanceOf)
        params: [account, masterChefAddress],
      }
    })
    const rawLpAllowances = await multicall(erc20ABI, calls)
    const parsedLpAllowances = rawLpAllowances.map((lpBalance, index) => {
      return {
        allowance: lpBalance[0].toJSON().hex,
        currency: calls[index].address,
        currencySymbol: address[index].value.symbol,
        currencyDecimals: address[index].value.decimals,
      }
    })
    return parsedLpAllowances
  } catch (error) {
    console.error(error)
    return []
  }
}

export const fetchNftApprovalMarket = async (account, farmsToFetch: NftInfo[]) => {
  const marketAddress = getNftMarketAddress()
  const tokens = farmsToFetch.map((farm) => farm.properties.token)
  const tokensUniq = uniq(tokens)
  try {
    const calls = tokensUniq.map((token) => {
      return {
        address: token,
        name: 'isApprovedForAll',
        params: [account, marketAddress],
      }
    })
    const userInfo = await multicall(dsgnftAbi, calls)
    return userInfo.map((item, index) => ({
      token: tokensUniq[index],
      isApprovedMarket: item[0],
    }))
  } catch (error) {
    return []
  }
}

// export default fetchUserMiningInfos
