import React, { useMemo } from 'react'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import Balance from 'components/Balance'
import { CurrencyLogo } from 'components/Logo'
import { useTranslation } from 'contexts/Localization'
import { Token } from 'dsgswap-sdk'
import { Card, Flex, Box, Text } from 'pancake-uikit'
// import { Token } from 'config/constants/types'
import { getFullDisplayBalance, formatLocalisedCompactNumber } from 'utils/formatBalance'
import NftCardImg, { NftCardImgPorps } from './NftCardImg'
import StatusTag from './StatusTag'

export enum CardShowType {
  INFO = 'info',
  MARKET = 'market',
  BAG = 'bag',
  ERC20 = 'erc20',
  HISTORY = 'history',
}

interface NFTCardPorps {
  bg?: string
  price?: BigNumber
  token?: Token
  nft: NftCardImgPorps
}

const CardNft = styled(Card)`
  padding: 12px;
`

const NftCard: React.FC<NFTCardPorps> = ({ bg, token, price, nft, ...props }) => {
  const { t } = useTranslation()

  const priceDisplay = useMemo(() => {
    const displayVal = getFullDisplayBalance(price, token?.decimals, 4)
    return formatLocalisedCompactNumber(Number(displayVal), 8)
    return displayVal
  }, [price, token?.decimals])
  return (
    <CardNft {...props}>
      <NftCardImg {...nft} />
      {
        Boolean(token || price) && (
          <Flex mt="16px" alignItems="center">
            <CurrencyLogo symbol={token?.symbol} />
            <Box ml="8px">
              <Text fontSize="14px" color="textSubtle">
                {t('Price')} {token?.symbol}
              </Text>
              <Text bold color="textPrimary">
                {priceDisplay}
              </Text>
            </Box>
          </Flex>
        )
      }
    </CardNft>
  )
}
export default NftCard
