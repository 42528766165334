import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { Route, useRouteMatch, useLocation } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { Heading, Toggle, Button, Text, Flex, Spinner, Empty } from 'pancake-uikit'
import styled from 'styled-components'
import FlexLayout from 'components/Layout/Flex'
import Page from 'components/Layout/Page'
import { useFarms, usePollFarmsData, usePriceDsgBusd, usePriceSymbolBusd } from 'state/farms/hooks'
import useToast from 'hooks/useToast'
import { useFetchNftUserData } from 'state/nfts/hooks'
import { USDC } from 'dsgswap-sdk'
import { Erc20Farm, Farm, FarmPoolType, SinglePoolView } from 'state/types'
import { useTranslation } from 'contexts/Localization'
import { getSinglePoolApr } from 'utils/apr'
import { useFetchSinglePollData, useSinglePollData } from 'state/pools/hooks'
import FlexAutoWarpper from 'components/Layout/FlexAutoWarpper'
import { getAddress, getToken } from 'utils/addressHelpers'
import { useBlock } from 'state/block/hooks'
import { formatDisplayApr, getBalanceAmount } from 'utils/formatBalance'
import { usePoolsOptions, optionType } from 'hooks/useOptions'
import { poolsSortType } from 'config/constants/optonsConfig/pools'
import { latinise } from 'utils/latinise'
import { BIG_ZERO } from 'utils/bigNumber'
import { DEFAULT_TOKEN_DECIMAL } from 'config'
import { orderBy } from 'lodash'
import PageHeader from 'components/PageHeader'
import SearchInput from 'components/SearchInput'
import Select, { OptionProps } from 'components/Select/Select'
import PageSection from 'components/PageSection'
import { FarmTypes } from 'config/constants/types'
import Loading from 'components/Loading'
import {
  useUseNestGet,useUseNestPledge
} from 'state/user/hooks'
import FarmCard, { FarmWithStakedValue } from './components/FarmCard/FarmCard'



const PageContainer = styled(Page)`
  padding-top: 0;
`

const ControlContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;

  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 32px;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    flex-wrap: wrap;
    /* padding: 0 32px; */
    margin-bottom: 0;
  }
`

const FilterWrapper = styled(Flex)`
  margin-top: 10px;
  margin-right: 16px;
  align-items: center;
  &:last-child {
    margin-right: 0;
  }
`

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0px;
  flex-wrap: wrap;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
    padding: 0;
  }
`

const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.cardBorder};
  height: 1px;
  margin: 16px 0 32px;
  width: 100%;
`

const ViewControls = styled.div`
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;

  > div {
    padding: 0px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    justify-content: flex-start;
    width: auto;

    > div {
      padding: 0px;
      margin: 0;
    }
  }
`

const getDisplayApr = (cakeRewardsApr?: number) => {

  if (cakeRewardsApr) {
    return formatDisplayApr(cakeRewardsApr)
  }
  return null
}

const usdc = getToken(USDC)

const Pools: React.FC = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const cakePrice = usePriceDsgBusd()
  const [query, setQuery] = useState('')
  const [isTiped, setIsTiped] = useState(false)
  const { account } = useWeb3React()
  const { currentBlock } = useBlock()
  const { toastInfo } = useToast()

  const poolsSortOption = usePoolsOptions(optionType.POOLS_GET_SORT_OPTION)
  const [sortOption, setSortOption] = useState(poolsSortOption[0].value)

  const isArchived = pathname.includes('archived')
  const isInactive = pathname.includes('history')
  const isActive = !isInactive && !isArchived

  useFetchSinglePollData()
  const { data, userDataLoaded, farmsDataLoaded } = useSinglePollData()

  // Users with no wallet connected should see 0 as Earned amount
  // Connected users should see loading indicator until first userData has loaded

  // const [stakedOnly, setStakedOnly] = useState(!isActive)
  const [stakedOnly, setStakedOnly] = useUseNestPledge()
  // useEffect(() => {
  //   setStakedOnly(!isActive)
  // }, [isActive])
  const [historyOnly, setHistoryOnly] = useUseNestGet()

  const viewHistoryStaked = useCallback(
    () => {
      setHistoryOnly(true)
      setStakedOnly(true)
    },
    [setHistoryOnly, setStakedOnly],
  )

  // 在用户有已完成并且有质押的时候提示用户
  useEffect(() => {
    if (!isTiped && !historyOnly) {
      const isHistoryErc20Farms = data.filter((farm) => Number(farm.bonusEndBlock || 0) < currentBlock)
      const isHistoryStakeErc20Farms = isHistoryErc20Farms.filter(
        (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
      )
      if (isHistoryStakeErc20Farms.length) {
        setIsTiped(true)
        toastInfo(t('Tip'), <Flex>
          <Text>
            {t('You have Magic house not harvested in finished!')}
            <Button ml="4px" onClick={viewHistoryStaked} variant="textline">{t('View')}</Button>
          </Text>
        </Flex>)
      }
    }
    if (historyOnly) {
      setIsTiped(true)
    }
  }, [historyOnly, data, currentBlock, toastInfo, t, isTiped, viewHistoryStaked])

  const farmsList = useCallback(
    (farmsToDisplay: SinglePoolView[]): SinglePoolView[] => {

      let farmsToDisplayWithAPR: FarmWithStakedValue[] = farmsToDisplay.map((farm) => {
        if (!farm.depositTokenPrice) {
          return farm
        }
        const depositTokenPriceBusd = getBalanceAmount(new BigNumber(farm.depositTokenPrice), usdc.decimals)
        const rewardsTokenPriceBusd = getBalanceAmount(new BigNumber(farm.rewardsTokenPrice), usdc.decimals)
        const totalLiquidity = getBalanceAmount(depositTokenPriceBusd.times(farm.totalAmount), Number(farm.depositDecimals))
        const rewardsPerBlock = new BigNumber(farm.rewardsPerBlock)
        const { cakeRewardsApr } = isActive
          ? getSinglePoolApr(
            rewardsTokenPriceBusd,
            rewardsPerBlock,
            totalLiquidity,
          )
          : { cakeRewardsApr: 0 }
        return {
          ...farm,
          depositTokenPriceBusd,
          rewardsTokenPriceBusd,
          apr: cakeRewardsApr,
          liquidity: totalLiquidity
        }
      })

      if (query) {
        const lowercaseQuery = latinise(query.toLowerCase())
        farmsToDisplayWithAPR = farmsToDisplayWithAPR.filter((farm: FarmWithStakedValue) => {
          return latinise(farm.lpSymbol.toLowerCase()).includes(lowercaseQuery)
        })
      }
      return farmsToDisplayWithAPR
    },
    [query, isActive],
  )

  const handleChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }

  /**
   * 渲染ff 列表
   */
  const chosenFfFarmsMemoized = useMemo(() => {
    if (!currentBlock) return []
    let chosenFarms = []

    const sortFarms = (farms: FarmWithStakedValue[]): FarmWithStakedValue[] => {
      const farmsTemp = orderBy(farms, (farm: FarmWithStakedValue) => farm.isRemoved, 'desc')
      switch (sortOption) {
        case poolsSortType.APR:
          return orderBy(farmsTemp, (farm: FarmWithStakedValue) => farm.apr, 'desc')
        // case poolsSortType.MULTIPLIER:
        //   return orderBy(
        //     farms,
        //     (farm: FarmWithStakedValue) => (farm.multiplier ? Number(farm.multiplier.slice(0, -1)) : 0),
        //     'desc',
        //   )
        case poolsSortType.EARNED:
          return orderBy(
            farmsTemp,
            (farm: FarmWithStakedValue) => (farm.userData ? Number(farm.userData.earnings) : 0),
            'desc',
          )
        case poolsSortType.TOTAL:
          return orderBy(farmsTemp, (farm: FarmWithStakedValue) => Number(farm.liquidity), 'desc')
        case poolsSortType.HOT:
          return orderBy(farmsTemp, (farm: FarmWithStakedValue) => farm.lpSymbol.includes('MBT') ? 10 : 1, 'desc')
        default:
          return farmsTemp
      }
    }
    const isActiveErc20Farms = data.filter((farm) => Number(farm.bonusEndBlock || 0) >= currentBlock)
    // const isActiveErc20Farms = data.map((farm) => {
    //   if (Number(farm.bonusEndBlock || 0) < currentBlock) {
    //     return {
    //       ...farm,
    //       isRemoved: true,
    //     }
    //   }
    //   return farm
    // })
    const isHistoryErc20Farms = data.filter((farm) => Number(farm.bonusEndBlock || 0) < currentBlock)

    const isActiveStakeErc20Farms = isActiveErc20Farms.filter(
      (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
    )
    const isHistoryStakeErc20Farms = isHistoryErc20Farms.filter(
      (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
    )

    if (historyOnly) {
      chosenFarms = stakedOnly ? farmsList(isHistoryStakeErc20Farms) : farmsList(isHistoryErc20Farms)
    } else {
      chosenFarms = stakedOnly ? farmsList(isActiveStakeErc20Farms) : farmsList(isActiveErc20Farms)
    }
    return sortFarms(chosenFarms)
  }, [data, historyOnly, sortOption, stakedOnly, farmsList, currentBlock])


  const isLoadingData = useMemo(() => {

    return !(farmsDataLoaded) || !currentBlock
  }, [farmsDataLoaded, currentBlock])

  const showNoData = useMemo(() => {
    return farmsDataLoaded && !chosenFfFarmsMemoized.length && !!currentBlock
  }, [farmsDataLoaded, chosenFfFarmsMemoized, currentBlock])

  const renderContent = (): JSX.Element => {
    return (
      <FlexLayout>
        <FlexAutoWarpper lineMax={3}>
          {chosenFfFarmsMemoized.map((farm) => (
            <FarmCard
              key={`${farm.poolAddress}`}
              farm={farm}
              displayApr={getDisplayApr(farm.apr)}
              cakePrice={cakePrice}
              account={account}
              userDataLoaded={userDataLoaded}
              removed={historyOnly || farm.isRemoved}
            />
          ))}
        </FlexAutoWarpper>
      </FlexLayout>
    )
  }

  const handleSortOptionChange = (option: OptionProps): void => {
    setSortOption(option.value)
  }

  return (
    <PageSection index={2} width="100%" backgroundPosition="right" background="bgimage8">
      <PageHeader>
        <Heading color='white_black' as="h1" scale="xl" mb="16px">
          {t('Magic house')}
        </Heading>
        <Text color='smHeadText'>{t('Stake MBT to earn other tokens. You can withdraw them at any time.')}</Text>
      </PageHeader>
      <PageContainer>
        <ControlContainer>
          <ViewControls>
            {/* <ToggleView viewMode={viewMode} onToggle={(mode: ViewMode) => setViewMode(mode)} /> */}
            {/* <FarmTabButtons hasStakeInFinishedFarms={stakedInactiveFarms.length > 0} /> */}
          </ViewControls>
          <FilterContainer>
            <FilterWrapper>
              <Toggle checked={historyOnly} onChange={() => setHistoryOnly(!historyOnly)} scale="sm" />
              <Text ml="4px">{t('Finished')}</Text>
            </FilterWrapper>
            <FilterWrapper>
              <Toggle checked={stakedOnly} onChange={() => setStakedOnly(!stakedOnly)} scale="sm" />
              <Text ml="4px">{t('Staked only')}</Text>
            </FilterWrapper>
            <FilterWrapper>
              <Select
                options={poolsSortOption}
                onChange={handleSortOptionChange}
              />
            </FilterWrapper>
            <FilterWrapper>
              <SearchInput onChange={handleChangeQuery} placeholder="Search Magic" />
            </FilterWrapper>
          </FilterContainer>
        </ControlContainer>
        <Divider />
        {renderContent()}
        {account && !userDataLoaded && stakedOnly && (
          <Flex justifyContent="center">
            <Loading />
          </Flex>
        )}
        {isLoadingData && (
          <Flex justifyContent="center">
            <Spinner />
          </Flex>
        )}
        {showNoData && <Empty />}
      </PageContainer>
    </PageSection>
  )
}

export default Pools
