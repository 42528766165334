import React from 'react'
import styled from 'styled-components'
import { Box } from 'pancake-uikit'
import Container from '../Layout/Container'

const Outer = styled(Box)<{ background?: string, noPaddingRight?: boolean }>`
  /* background: ${({ theme, background }) => background || theme.colors.gradients.bubblegum}; */
  padding-right: ${({ noPaddingRight }) => noPaddingRight ? '0px !important' : '0'};
  ${({ theme }) => theme.mediaQueries.xl} {
    padding-right: 8px;
  }

  ${({ theme }) => theme.mediaQueries.xs} {
    padding-right: 16px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-right: 32px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-right: 64px;
  }
`

const Inner = styled(Container)`
  padding-top: 32px;
  padding-bottom: 32px;
`

const PageHeader: React.FC<{ background?: string, noPaddingRight?: boolean }> = ({ background, children, ...props }) => (
  <Outer background={background} {...props}>
    <Inner>{children}</Inner>
  </Outer>
)

export default PageHeader
