import { Farm } from 'state/types'
import { stableSymbols, WETH } from 'dsgswap-sdk'

export const isBNBBUSDLp = (farm: Farm) => {
  const symbol = farm.token.symbol.toUpperCase()
  const quoteSymbol = farm.quoteToken.symbol.toUpperCase()
  const hasBNB = symbol === 'WBNB' || symbol === 'BNB' || quoteSymbol === 'WBNB' || quoteSymbol === 'BNB'
  const hasBUSD = symbol === 'USDT' || quoteSymbol === 'USDT'
  return hasBNB && hasBUSD && symbol !== quoteSymbol
}

export const isBaseTokenLp = (token: string, farm: Farm) => {
  const symbol = farm.token.symbol.toUpperCase()
  const quoteSymbol = farm.quoteToken.symbol.toUpperCase()
  const hasBNB = symbol === token || quoteSymbol === token
  const hasBUSD = stableSymbols.includes(symbol) || stableSymbols.includes(quoteSymbol)
  return hasBNB && hasBUSD && symbol !== quoteSymbol
}

export const isBNBDSGLp = (farm: Farm) => {
  const symbol = farm.token.symbol.toUpperCase()
  const quoteSymbol = farm.quoteToken.symbol.toUpperCase()
  const hasBNB = symbol === 'USDC' || quoteSymbol === 'USDC'
  const hasDSG = symbol === 'MBT' || quoteSymbol === 'MBT'
  return hasBNB && hasDSG && symbol !== quoteSymbol
}
export const hasDSGLp = (farm: Farm) => {
  const symbol = farm.token.symbol.toUpperCase()
  const quoteSymbol = farm.quoteToken.symbol.toUpperCase()
  return symbol === 'MBT' || quoteSymbol === 'MBT'
}
export const hasSymbolFarm = (symbol: string, farm: Farm) => {
  const querySymbol = symbol.toUpperCase()
  if (querySymbol === 'BNB' || querySymbol === 'WBNB') {
    return isBNBBUSDLp(farm)
  }
  const TokenSymbol = farm.token.symbol.toUpperCase()
  const quoteSymbol = farm.quoteToken.symbol.toUpperCase()
  return TokenSymbol === querySymbol || quoteSymbol === querySymbol
}
