import { sectionBackground } from './types'

export const backgroundVariants = {
  [sectionBackground.BGIMAGE1]: {
    dark: '/images/page-bg/dark-1.jpg',
    light: '/images/page-bg/light-1.jpg',
  },
  [sectionBackground.BGIMAGE2]: {
    dark: '/images/page-bg/dark-2.jpg',
    light: '/images/page-bg/light-2.jpg',
  },
  [sectionBackground.BGIMAGE3]: {
    dark: '/images/page-bg/dark-3.jpg',
    light: '/images/page-bg/light-3.jpg',
  },
  [sectionBackground.BGIMAGE4]: {
    dark: '/images/page-bg/dark-4.jpg',
    light: '/images/page-bg/light-4.jpg',
  },
  [sectionBackground.BGIMAGE5]: {
    dark: '/images/page-bg/dark-5.jpg',
    light: '/images/page-bg/light-5.jpg',
  },
  [sectionBackground.BGIMAGE8]: {
    dark: '/images/page-bg/dark-8.jpg',
    light: '/images/page-bg/light-8.jpg',
  },
}
