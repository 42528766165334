import React from 'react'
import styled from 'styled-components'
import { Box, Text } from 'pancake-uikit'

interface StatusTagPorps {
  lable: string
  color?: string
}

const BoxWrapper = styled(Box)`
  width: 0;
  height: 0;
  position: relative;
  z-index: 1;
`
const BoxInner = styled(Box)`
  position: absolute;
  width: 160px;
  height: 160px;
  overflow: hidden;
`
const BoxText = styled(Box)`
  position: absolute;
  height: 36px;
  width: 200%;
  top: calc(50% - 44px);
  left: calc(-50% - 28px);
  transform: rotateZ(-45deg);
  background: ${({ theme }) => theme.colors.background};
`

const StatusTag: React.FC<StatusTagPorps> = ({ lable, color = 'failure' }) => {
  return (
    <BoxWrapper>
      <BoxInner>
        <BoxText>
          <Text fontSize="24px" color={color} bold textAlign="center">
            {lable}
          </Text>
        </BoxText>
      </BoxInner>
    </BoxWrapper>
  )
}
export default StatusTag
