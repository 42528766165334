import React, { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import {
  Button,
  Text,
  Flex,
  Box,
  Erc20Icon,
  CardLableIcon,
  InputPanel,
} from 'pancake-uikit'
import { useTranslation } from 'contexts/Localization'
import { CardShowType } from 'components/NftCard'

interface UserBalancesProps {
  onIconClick?: () => void
}

const InputPanelStyled = styled(InputPanel)`
  padding: 0;
  .mobile-icon {
    display: block;
    ${({ theme }) => theme.mediaQueries.nav} {
      display: none;
    }
  }
  .desktop-icon {
    display: none;
    ${({ theme }) => theme.mediaQueries.nav} {
      display: block;
    }
  }
`

const TextWarpper = styled(Flex)`
  height: 40px;
  align-items: center;
`

const LeftBoxStyled = styled(Box)`
  padding: 0 28px;
  background: ${({ theme }) => theme.colors.primary};
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
`

const UserBalances: React.FC<UserBalancesProps> = ({ onIconClick }) => {
  const { t } = useTranslation()

  const location = useLocation()

  const url = '/nft'

  const activeIndex = useMemo(() => {
    switch (location.pathname) {
      case `${url}/${CardShowType.ERC20}`:
        return CardShowType.ERC20
      case `${url}/${CardShowType.BAG}`:
        return CardShowType.BAG
      default:
        return null
    }
  }, [location.pathname])

  return (
    <InputPanelStyled>
      <Box className="show-media-nav">
        <Flex>
          <LeftBoxStyled>
            <TextWarpper>
              <Text bold color="white">
                {t('My bag')}
              </Text>
            </TextWarpper>
          </LeftBoxStyled>
          <TextWarpper>
            <Button as={Link} to={`${url}/${CardShowType.ERC20}`} variant="text" startIcon={<Erc20Icon color="primary" width="28px" />}>
              <Text color={activeIndex === CardShowType.ERC20 ? 'primary' : 'text'}>{t('My token')}</Text>
            </Button>
          </TextWarpper>
          <TextWarpper>
            <Button
              as={Link}
              to={`${url}/${CardShowType.BAG}`}
              color="red"
              variant="text"
              startIcon={<CardLableIcon color="primary" width="28px" />}
            >
              <Text color={activeIndex === CardShowType.BAG ? 'primary' : 'text'}>{t('My NFT')}</Text>
            </Button>
          </TextWarpper>
        </Flex>
      </Box>
      <Box className="hide-media-nav">
        <Flex>
          <TextWarpper>
            <Button
              padding="0 12px 0 24px"
              as={Link}
              to={`${url}/${CardShowType.ERC20}`}
              variant="text"
              startIcon={<Erc20Icon color="primary" width="28px" />}
            >
              {/* <Text  color={activeIndex === CardShowType.ERC20 ? 'primary' : 'text'}>{t(' Token')}</Text> */}
            </Button>
          </TextWarpper>
          <TextWarpper>
            <Button
              padding="0 24px 0 12px"
              as={Link}
              to={`${url}/${CardShowType.BAG}`}
              color="red"
              variant="text"
              startIcon={<CardLableIcon color="primary" width="28px" />}
            >
              {/* <Text color={activeIndex === CardShowType.BAG ? 'primary' : 'text'}>{t('My NFT')}</Text> */}
            </Button>
          </TextWarpper>
        </Flex>
      </Box>
    </InputPanelStyled>
  )
}

export default UserBalances
