import uniq from 'lodash/uniq'
import liquidityPoolABI from 'config/abi/liquidityPool.json'
import { dsgNftFactorys } from 'config/constants/nfts'
import dsgnftAbi from 'config/abi/dsgnft.json'
import Erc20EarnNftPoolAbi from 'config/abi/Erc20EarnNftPool.json'
import { AddressZero } from '@ethersproject/constants'
import isZero from 'utils/isZero'
import multicall from 'utils/multicall'
import formatWETH from 'utils/formatWETH'
import { getLiquidityPoolAddress, getErc20EarnNftPoolAddress, getAddress } from 'utils/addressHelpers'
import { Farm, FarmPoolType } from '../types'

const getLptokenInfo = (farmdata: any) => {
  return {
    lpSymbol: !farmdata.isLpToken
      ?
      (formatWETH(farmdata.token0) || farmdata.symbol1)
      :
      `${formatWETH(farmdata.token0) || farmdata.symbol0}/${formatWETH(farmdata.token1) || farmdata.symbol1}`,
    token: {
      symbol: farmdata.symbol0,
      address: {
        56: farmdata.token0,
        80001: farmdata.token0,
        222: farmdata.token0,
      },
      decimals: farmdata.decimals0,
      projectLink: '',
    },
    quoteToken: {
      symbol: farmdata.symbol1,
      address: {
        56: farmdata.token1,
        80001: farmdata.token1,
        222: farmdata.token1,
      },
      decimals: farmdata.decimals1,
      projectLink: '',
    },
  }
}

const filterAdditionalNft = (additionalNft: string) => {
  return additionalNft?.toLowerCase() === getAddress(dsgNftFactorys.foodNft.address).toLowerCase() ? additionalNft : AddressZero
}

const fetchFarmList = async (farmdata: Farm[]) => {
  try {
    const liquidityPoolAddress = getLiquidityPoolAddress()
    const calls = [
      {
        address: liquidityPoolAddress,
        name: 'getAllPoolViews', // Function name on the contract (example: balanceOf)
      },
      {
        address: liquidityPoolAddress,
        name: 'nftSlotFee', // Function name on the contract (example: balanceOf)
      },
      // {
      //   address: liquidityPoolAddress,
      //   name: 'ratePerNftPower' // Function name on the contract (example: balanceOf)
      // },
      // {
      //   address: liquidityPoolAddress,
      //   name: 'maxNftAdditionalRate' // Function name on the contract (example: balanceOf)
      // },
      // {
      //   address: liquidityPoolAddress,
      //   name: 'additionalRate' // Function name on the contract (example: balanceOf)
      // },
      {
        address: liquidityPoolAddress,
        name: 'rewardToken', // Function name on the contract (example: balanceOf)
      },
    ]

    const [allPoolViews, nftSlotFee, rewardToken] = await multicall(liquidityPoolABI, calls)
    // const [allPoolViews, nftSlotFee, ratePerNftPower, maxNftAdditionalRate, rewardToken] = await multicall(liquidityPoolABI, calls);
    const res = allPoolViews[0].map((item) => {
      const oldFarm = farmdata.find((subItem) => subItem.pid === item.pid.toNumber())
      const token = oldFarm?.token || {}
      const quoteToken = oldFarm?.quoteToken || {}
      return {
        liquidityPoolAddress,
        rewardToken: rewardToken[0],
        farmPoolType: FarmPoolType.DSG_FARM,
        accRewardAmount: item.accRewardAmount.toJSON().hex,
        accRewardPerShare: item.accRewardPerShare.toJSON().hex,
        allocPoint: item.allocPoint.toJSON().hex,
        allocRewardAmount: item.allocRewardAmount.toJSON().hex,
        lastRewardBlock: item.lastRewardBlock.toJSON().hex,
        rewardsPerBlock: item.rewardsPerBlock.toJSON().hex,
        totalAmount: item.totalAmount.toJSON().hex,
        pid: item.pid.toNumber(),
        nftSlotFee: nftSlotFee[0].toJSON().hex,
        ratePerNftPower: '0',
        maxNftAdditionalRate: '0',
        // ratePerNftPower: ratePerNftPower[0].toJSON().hex,
        // maxNftAdditionalRate: maxNftAdditionalRate[0].toJSON().hex,
        lpSymbol: `${formatWETH(item.token0) || item.symbol0}/${formatWETH(item.token1) || item.symbol1} LP`,
        additionalNft: filterAdditionalNft(item.additionalNft),
        lpAddresses: {
          222: item.lpToken,
          56: item.lpToken,
          80001: item.lpToken,
        },
        token: {
          ...token,
          symbol: item.symbol0,
          address: {
            56: item.token0,
            80001: item.token0,
            222: item.token0,
          },
          token: item.token0,
          decimals: item.decimals0,
          projectLink: '',
        },
        quoteToken: {
          ...quoteToken,
          symbol: item.symbol1,
          token: item.token1,
          address: {
            56: item.token1,
            80001: item.token1,
            222: item.token1,
          },
          decimals: item.decimals1,
          projectLink: '',
        },
      }
    })
    return res
  } catch (error) {
    console.error(error)
    return []
  }
}

export const fetchAdditionalRate = async (nftInfos: Farm[]) => {
  const liquidityPoolAddress = getLiquidityPoolAddress()
  const tokens = nftInfos.map((farm) => farm.additionalNft)
  const tokensUniq = uniq(tokens).filter((address) => !isZero(address))
  try {
    const calls1 = tokensUniq.map((token) => {
      return {
        address: liquidityPoolAddress,
        name: 'getAdditionalRates',
      }
    })
    const res = await multicall(liquidityPoolABI, calls1)
    const rates = res.map((item, index) => {
      const fees = {}
      item[0].forEach((subItem, subIndex) => {
        fees[subIndex] = subItem.toJSON().hex
      })
      return {
        additionalNft: tokensUniq[index],
        levelRate: { ...fees },
      }
    })
    return rates
  } catch (error) {
    console.error(error)
    return []
  }
}

export const fetchAdditionalRates = async () => {
  const liquidityPoolAddress = getLiquidityPoolAddress()
  try {
    const calls = [{
      address: liquidityPoolAddress,
      name: 'getAdditionalRates',
    }]
    const [res] = await multicall(liquidityPoolABI, calls)
    return res[0].map((item) => Number(item.toJSON().hex) / 100)
  } catch (error) {
    return []
  }
}

// export const fetchAdditionalRate = async (nftInfos: Farm[]) => {
//     const liquidityPoolAddress = getLiquidityPoolAddress()
//     const tokens = nftInfos.map(farm => farm.additionalNft)
//   const tokensUniq = uniq(tokens).filter(address => !isZero(address))
//   try {
//     const calls1 = tokensUniq.map((token) => {
//       return {
//         address: token,
//         name: 'maxLevel',
//       }
//     })
//     const maxLevels = await multicall(dsgnftAbi, calls1)
//     let callIndex = 0
//     const calls2 = tokensUniq.map((token, index) => {
//       let arr = []
//       for (let i = 1; i <= maxLevels[index][0].toNumber(); i ++) {
//         arr = [...arr, {
//           address: liquidityPoolAddress,
//           name: 'additionalRate',
//           params: [i - 1],
//           customId: callIndex,
//         }]
//         callIndex += 1
//       }
//       return arr
//     })
//     const levelRate = await multicall(liquidityPoolABI, calls2.flat())
//     const res = calls2.map((item, index) => {
//       const fees = {}
//       item.forEach(subItem => {
//         fees[subItem.params[0]] = levelRate[subItem.customId][0].toJSON().hex
//       })
//       return {
//         additionalNft: tokensUniq[index],
//         levelRate: {...fees}
//       }
//     })
//     return res
//   } catch (error) {
//     console.error(error)
//     return []
//   }
// }

export const fetchErc20EarnNftList = async () => {
  try {
    const calls = [
      {
        address: getErc20EarnNftPoolAddress(),
        name: 'getAllPoolViews', // Function name on the contract (example: balanceOf)
      },
    ]
    const [data] = await multicall(Erc20EarnNftPoolAbi, calls)
    return data[0].map((item, index) => {
      const nftTokenIds = item.nftTokenIds.map((tokenId) => tokenId.toJSON().hex)
      return {
        ...getLptokenInfo(item),
        nftTokenIds,
        pid: index,
        isLpToken: item.isLpToken,
        nftAddress: item.nftAddress,
        tokenAddress: item.tokenAddress,
        stakeTime: item.stakeTime.toJSON().hex,
        stakeAmount: item.stakeAmount.toJSON().hex,
        nftLeft: item.nftLeft.toJSON().hex,
        outputs: 1,
      }
    })
  } catch (error) {
    console.error(error)
    return []
  }
}

export const fetchNftApprovalFarm = async (account, farmsToFetch: Farm[]) => {
  const marketAddress = getLiquidityPoolAddress()
  const tokens = farmsToFetch.map((farm) => farm.additionalNft)
  const tokensUniq = uniq(tokens).filter((address) => !isZero(address))

  try {
    const calls = tokensUniq.map((token) => {
      return {
        address: token,
        name: 'isApprovedForAll',
        params: [account, marketAddress],
      }
    })
    const userInfo = await multicall(dsgnftAbi, calls)
    return userInfo.map((item, index) => ({
      additionalNft: tokensUniq[index],
      isApprovedPool: item[0],
    }))
  } catch (error) {
    console.error(error)
    return []
  }
}

export const fetchNftApprovalFarmLP = async (account, farmsToFetch: Farm[], lpAddress) => {
  const tokens = farmsToFetch.map((farm) => farm.additionalNft)
  const tokensUniq = uniq(tokens).filter((address) => !isZero(address))

  try {
    const calls = tokensUniq.map((token) => {
      return {
        address: token,
        name: 'isApprovedForAll',
        params: [account, lpAddress],
      }
    })
    const userInfo = await multicall(dsgnftAbi, calls)
    return userInfo.map((item, index) => ({
      additionalNft: tokensUniq[index],
      isApprovedPool: item[0],
    }))
  } catch (error) {
    console.error(error)
    return []
  }
}

export default fetchFarmList
