import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Flex, Box, Heading, Button, LinkExternal, Image, Text } from 'pancake-uikit'
import { getBscScanLink } from 'utils'
import useToast from 'hooks/useToast'
import { Token, FarmTypes } from 'config/constants/types'
import { TokenPairImage } from 'components/TokenImage'
import { useTranslation } from 'contexts/Localization'
import { NftFarm, NftInfo } from 'state/types'
import { CurrencyLogo } from 'components/Logo'

export interface ExpandableSectionProps {
  lpAddress: string
  depositSymbol: string
  rewardsSymbol: string
  depositToken: string
  rewardsToken: string
}

const Wrapper = styled(Flex)`
  svg {
    /* margin-right: 4px; */
  }
`

const CardHeading: React.FC<ExpandableSectionProps> = ({
  lpAddress,
  depositSymbol,
  rewardsSymbol,
  depositToken,
  rewardsToken,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Wrapper justifyContent="" alignItems="center" mb="12px">
        <TokenPairImage
          variant="binary"
          primarySymbol={depositSymbol}
          secondarySymbol={rewardsSymbol}
          primaryAddress={depositToken}
          secondaryAddress={rewardsToken}
          width={56} height={56}
        />
        <Box ml="12px">
          {/* <Heading scale="ld" mb="4px">
            {lable} {singleToken ? 'TOKEN' : 'LP'}
          </Heading> */}
          <Flex alignItems="baseline">
            <Heading scale="ld">
              {depositSymbol}
            </Heading>
            <Text mr="4px" fontSize="12px" m="4px">{t('earn')}</Text>
            <Heading scale="ld">
              {rewardsSymbol}
            </Heading>
          </Flex>
          <LinkExternal fontSize="16px" href={getBscScanLink(lpAddress, 'token')}>
            {t('View Contract')}
          </LinkExternal>
        </Box>
      </Wrapper>
    </>
  )
}

export default CardHeading
