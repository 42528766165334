import { DEFAULT_TOKEN_DECIMAL } from 'config'
import BigNumber from 'bignumber.js'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import fetchMemberInfo, { fetchMemberSuperiorRatio, fetchMemberAlpha } from './fetchMemberInfo'
import { fetchFarmUserAllowances, fetchUserBanlanceOf, fetchUsersBanlanceOf } from './fetchUserMemberInfo'
import {
  MemberState,
  MemberUserDataState,
  MemberDataState,
  MemberUserRanking,
  MemberBalanceRanking,
  MemberMintList,
  MemberRedeemList,
} from '../types'
import {
  getAccountUserData,
  getVDSGInfo,
  getVDSGHistory,
  getUserRanking,
  getUserInvite,
  getUserBanlance,
  getMindAndRedeemHistory,
  getRedeemHistory,
  getMindHistory,
} from './getPublicMemberData'
import { caleExitFeeRate, getUserNetDsgEarnings } from './util'

const initialState: MemberState = {
  dataLoaded: false,
  data: {
    dsgRatio: 0,
    dsgFeeBurnRatio: '0',
    dsgTeam: '',
  },
  userData: {
    allowance: '0',
    vDsgAmount: '0',
    dsgAmount: '0',
    superior: '',
    mintAmount: '0',
    redeemRecieveAmount: '0',
    redeemFeeAmount: '0',
    credit: '0',
    stakingPower: '0',
  },
  userDataLoaded: false,
  userRanking: [],
  balanceRanking: {
    data: [],
    dataLoaded: false,
  },
  redeemList: {
    data: [],
    dataLoaded: false,
  },
  mintList: {
    data: [],
    dataLoaded: false,
  },
  userInvite: [],
}

// Async thunks
export const fetchMemberInfoAsync = createAsyncThunk<MemberDataState>('farms/fetchMemberInfoAsync', async () => {
  const [
    dsgTeam,
    dsgRatios,
    dsgFeeBurnRatio,
    alpha,
    getLatestAlpha,
    _superiorRatio,
    dsgWithdrawFeeRatio,
    _superiorMinSP,
    decimals,
  ] = await fetchMemberInfo()
  const vdsgInfo = await getVDSGInfo()
  const vDSGHistory = await getVDSGHistory()
  return {
    dsgRatio: dsgRatios?.[0].toJSON().hex || '0',
    dsgFeeBurnRatio: dsgFeeBurnRatio?.[0].toJSON().hex || '0',
    dsgTeam: dsgTeam?.[0],
    alpha: alpha?.[0].toJSON().hex || '0',
    newAlpha: getLatestAlpha?.[0].toJSON().hex || '0',
    curDistribution: getLatestAlpha?.[1].toJSON().hex || '0',
    feeRatio: dsgWithdrawFeeRatio?.[0].toJSON().hex || '0',
    _superiorRatio: _superiorRatio?.[0].toJSON().hex || '0',
    _superiorMinSP: _superiorMinSP?.[0].toJSON().hex || '0',
    decimals: decimals[0],
    ...vdsgInfo,
    ...vDSGHistory,
  }
})
// Async thunks
export const fetchMemberRankingAsync = createAsyncThunk<MemberUserRanking[]>(
  'farms/fetchMemberRankingAsync',
  async () => {
    const userRanking = await getUserRanking()
    // const superiorRatio = await fetchMemberSuperiorRatio()
    // return userRanking.map(item => {
    // const creditFromInvited = new BigNumber(item.creditFromInvited)
    //   return {
    //     ...item,
    //     invitedMintDsg: creditFromInvited.div(superiorRatio).times(1e18).toString()
    //   }
    // })
    return userRanking
  },
)
// Async thunks
export const fetchUserMemberInfoAsync = createAsyncThunk<MemberUserDataState, string>(
  'farms/fetchUserMemberInfoAsync',
  async (account) => {
    const { allowance } = await fetchFarmUserAllowances(account)
    const { vDsgAmount, dsgAmount, superior } = await fetchUserBanlanceOf(account)
    // mintAmount
    const data = await getAccountUserData(account)
    return {
      allowance,
      vDsgAmount,
      dsgAmount,
      superior,
      ...data,
    }
  },
)
// Async thunks
export const fetchUserMemberInviteAsync = createAsyncThunk<MemberUserRanking[], string>(
  'farms/fetchUserMemberInviteAsync',
  async (account) => {
    const inviteData = await getUserInvite(account)
    return inviteData
  },
)
// Async thunks
export const fetchMindAndRedeemHistoryAsync = createAsyncThunk<{
  redeemHistories: MemberRedeemList[]
  mintHistories: MemberMintList[]
}>(
  'farms/fetchMindAndRedeemHistoryAsync',
  async () => {
    const inviteData = await getMindAndRedeemHistory()
    return inviteData
  },
)
// Async thunks
export const fetchMindHistoryAsync = createAsyncThunk<{
  // redeemHistories: MemberRedeemList[]
  mintHistories: MemberMintList[]
}>(
  'farms/fetchMindHistoryAsync',
  async () => {
    const inviteData = await getMindHistory()
    return inviteData
  },
)
export const fetchRedeemHistoryAsync = createAsyncThunk<{
  redeemHistories: MemberRedeemList[]
  // mintHistories: MemberMintList[]
}>(
  'farms/fetchRedeemHistoryAsync',
  async () => {
    const { redeemHistories } = await getRedeemHistory()
    // const [alpha, dsgRatio] = await fetchMemberAlpha()
    const dsgAmounts = await fetchUsersBanlanceOf(redeemHistories.map(item => item.user.id))
    const data = redeemHistories.map((item, index) => {
      const { exitFeeRate,  total, exitFee } = caleExitFeeRate(item)
      const dsgEarning = getUserNetDsgEarnings(
          new BigNumber(dsgAmounts[index].mbtAmount.toJSON().hex),
          item.user.redeemRecieveAmount,
          item.user.mintAmount,
        )
      // const dsgBalance = new BigNumber(item.user.stakingPower)
      //   .times(alpha?.[0].toJSON().hex || 0)
      //   .div(DEFAULT_TOKEN_DECIMAL)
      //   .div(DEFAULT_TOKEN_DECIMAL)
      // const totalN = new BigNumber(total)
      // const profit = new BigNumber(item.recieve).div(dsgBalance.plus(total)).times(vDsgEarning).toFixed(2)
      // const profit = new BigNumber(item.recieve).div(item.user.redeemRecieveAmount).times(dsgEarning).toFixed(2)
      const profit = dsgEarning.toFixed(3)
      return {
        ...item,
        exitFeeRate,
        exitFee,
        total,
        profit
      }
    })
    return { redeemHistories: data }
  },
)
// Async thunks
export const fetchUserVDsgBalanceAsync = createAsyncThunk<MemberBalanceRanking[]>(
  'farms/fetchUserVDsgBalanceAsync',
  async () => {
    const inviteData = await getUserBanlance()
    const [alpha, dsgRatio] = await fetchMemberAlpha()
    const dsgAmounts = await fetchUsersBanlanceOf(inviteData.map(item => item.id))
    const data = inviteData.map((item, index) => ({
      ...item,
      mindAmountVDsg: new BigNumber(item.mintAmount).div(dsgRatio?.[0].toJSON().hex || 100).toString(),
      vdsgBalance: new BigNumber(item.stakingPower)
        .times(alpha?.[0].toJSON().hex || 0)
        .div(dsgRatio?.[0].toJSON().hex || 100)
        .div(DEFAULT_TOKEN_DECIMAL)
        .div(DEFAULT_TOKEN_DECIMAL).toString(),
      vDsgEarning: getUserNetDsgEarnings(
        new BigNumber(dsgAmounts[index].mbtAmount.toJSON().hex),
        item.redeemRecieveAmount,
        item.mintAmount,
      ).div(dsgRatio?.[0].toJSON().hex || 0).toString(),
    }))
    return data
  },
)

export const memberSlice = createSlice({
  name: 'Member',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Update farms with live data
    builder.addCase(fetchMemberInfoAsync.fulfilled, (state, action) => {
      state.data = action.payload
      state.dataLoaded = true
    })
    builder.addCase(fetchUserMemberInfoAsync.fulfilled, (state, action) => {
      state.userData = action.payload
      state.userDataLoaded = true
    })
    builder.addCase(fetchMemberRankingAsync.fulfilled, (state, action) => {
      state.userRanking = action.payload
    })
    builder.addCase(fetchUserMemberInviteAsync.fulfilled, (state, action) => {
      state.userInvite = action.payload
    })
    builder.addCase(fetchUserVDsgBalanceAsync.fulfilled, (state, action) => {
      state.balanceRanking.data = action.payload
      state.balanceRanking.dataLoaded = true
    })
    builder.addCase(fetchMindAndRedeemHistoryAsync.fulfilled, (state, action) => {
      const { redeemHistories, mintHistories } = action.payload
      state.redeemList.data = redeemHistories.map(item => {
        return {
          ...item,
          ...caleExitFeeRate(item),
        }
      })
      state.mintList.data = mintHistories
      state.redeemList.dataLoaded = true
      state.mintList.dataLoaded = true

    })
    builder.addCase(fetchMindHistoryAsync.fulfilled, (state, action) => {
      const { mintHistories } = action.payload
      state.mintList.data = mintHistories
      state.mintList.dataLoaded = true
    })
    builder.addCase(fetchRedeemHistoryAsync.fulfilled, (state, action) => {
      const { redeemHistories } = action.payload
      state.redeemList.data = redeemHistories
      state.redeemList.dataLoaded = true
    })
  },
})

export default memberSlice.reducer
