import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 1024 1024" {...props}>
      <path d="M844.288 586.752c-99.328 0-179.712 80.384-179.712 179.712 0 97.792 77.824 177.664 175.616 179.712h4.096c99.328 0 179.712-80.896 179.712-179.712s-80.896-179.712-179.712-179.712z m0 294.912c-63.488 0-114.688-51.712-114.688-114.688s51.712-114.688 114.688-114.688c63.488 0 114.688 51.712 114.688 114.688s-51.712 114.688-114.688 114.688z" fill="#067d73" p-id="2299"></path>
      <path d="M833.536 867.84c-57.344 0-103.936-46.592-103.936-103.936 0-57.344 46.592-103.936 103.936-103.936 57.344 0 103.936 46.592 103.936 103.936 0 57.344-46.592 103.936-103.936 103.936z" fill="#4dcdb3" p-id="2300"></path>
      <path d="M179.712 586.752c-99.328 0-179.712 80.384-179.712 179.712 0 97.792 77.824 177.664 175.616 179.712h4.096c99.328 0 179.712-80.896 179.712-179.712s-80.384-179.712-179.712-179.712z m0 294.912c-63.488 0-114.688-51.712-114.688-114.688s51.712-114.688 114.688-114.688c63.488 0 114.688 51.712 114.688 114.688s-51.2 114.688-114.688 114.688z" fill="#067d73" p-id="2301"></path>
      <path d="M169.984 874.496c-57.856 0-104.96-47.104-104.96-104.96s47.104-104.96 104.96-104.96 104.96 47.104 104.96 104.96-47.104 104.96-104.96 104.96z" fill="#4dcdb3" p-id="2302"></path>
      <path d="M527.872 31.744c-99.328 0-179.712 80.384-179.712 179.712 0 97.792 77.824 177.664 175.616 179.712h4.096c99.328 0 179.712-80.384 179.712-179.712 0-99.328-80.384-179.712-179.712-179.712z m0 294.4c-63.488 0-114.688-51.2-114.688-114.688 0-63.488 51.2-114.688 114.688-114.688 63.488 0 114.688 51.2 114.688 114.688 0 63.488-51.2 114.688-114.688 114.688z" fill="#067d73" p-id="2303"></path>
      <path d="M519.68 104.448c58.88 0 106.496 47.616 106.496 106.496S578.56 317.44 519.68 317.44s-106.496-47.616-106.496-106.496 47.616-106.496 106.496-106.496z" fill="#4dcdb3" p-id="2304"></path>
      <path d="M186.88 543.744C201.728 453.632 252.928 373.76 328.192 322.048c-8.704-16.384-15.36-34.304-19.968-52.736-97.792 62.464-163.328 165.376-177.664 280.576 16.896-4.096 34.304-6.144 51.712-6.144h4.608zM849.92 543.744c19.456 0.512 38.4 3.584 56.832 8.704-13.312-111.616-74.24-211.968-166.912-275.968-5.12 17.92-12.288 35.328-22.016 51.712 70.144 52.224 117.76 129.536 132.096 215.552zM666.112 901.12c-46.08 22.528-96.768 34.304-147.968 34.304-56.32 0-109.056-13.824-155.136-38.4-11.264 15.36-24.064 29.184-38.912 41.472 58.88 33.792 125.952 51.712 194.048 51.712 68.096 0 132.096-17.408 187.904-48.128-14.848-11.776-28.672-25.6-39.936-40.96z" fill="#067d73" p-id="2305"></path>
    </Svg>
  );
};

export default Icon;
