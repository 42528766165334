import React, { useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import styled from 'styled-components'
import {
  Flex,
  LogoutIconNewIcon,
  CopyIcon,
  UserMenu as UIKitUserMenu,
  UserMenuItem,
  Button,
  Text,
  LinkExternal,
} from 'pancake-uikit'
import useAuth from 'hooks/useAuth'
// import ConnectWalletButton from 'components/ConnectWalletButton'
import GradientValue from 'components/GradientValue/GradientValue'
import { useTranslation } from 'contexts/Localization'
import { getBscScanLink } from 'utils'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

const UserMenuItemBox = styled(UserMenuItem)`
  position: relative;
  max-width: 100%;
`
const LabelText = styled.div`
  /* color: ${({ theme }) => theme.colors.text}; */
  /* display: none;
  font-weight: 600; */

  ${({ theme }) => theme.mediaQueries.sm} {
    display: block;
    margin-left: 8px;
    margin-right: 4px;
  }
`

const Tooltip = styled.div<{ isTooltipDisplayed: boolean }>`
  display: ${({ isTooltipDisplayed }) => (isTooltipDisplayed ? 'inline-block' : 'none')};
  position: absolute;
  padding: 8px;
  top: -38px;
  right: 0;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.contrast};
  color: ${({ theme }) => theme.colors.invertedContrast};
  border-radius: 16px;
  opacity: 0.7;
  width: 100px;
`

const UserMenu = () => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { logout } = useAuth()
  const { chainId } = useActiveWeb3React()

  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false)

  const copyAddress = () => {
    if (navigator.clipboard && navigator.permissions) {
      navigator.clipboard.writeText(account).then(() => displayTooltip())
    } else if (document.queryCommandSupported('copy')) {
      const ele = document.createElement('textarea')
      ele.value = account
      document.body.appendChild(ele)
      ele.select()
      document.execCommand('copy')
      document.body.removeChild(ele)
      displayTooltip()
    }
  }

  function displayTooltip() {
    setIsTooltipDisplayed(true)
    setTimeout(() => {
      setIsTooltipDisplayed(false)
    }, 1000)
  }

  const accountEllipsis = account ? `${account.substring(0, 2)}...${account.substring(account.length - 4)}` : null

  return (
    <UIKitUserMenu
      lable={
        <Button>
          <LabelText title={account}>{accountEllipsis}</LabelText>
        </Button>
      }
      account={account}
    >
      <UserMenuItem>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Text bold fontSize="18px">
            {t('My Wallet')}
          </Text>
          <Button scale="xs" onClick={logout} variant="text">
            <LogoutIconNewIcon color="failure"/>
          </Button>
        </Flex>
      </UserMenuItem>
      <UserMenuItemBox>
        <Tooltip isTooltipDisplayed={isTooltipDisplayed}>{t('Copied')}</Tooltip>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <GradientValue value={account || ''} />
          <Button scale="xs" onClick={copyAddress} variant="text">
            <CopyIcon color="primary"/>
          </Button>
        </Flex>
      </UserMenuItemBox>
      <UserMenuItem>
        <Flex alignItems="center" width="100%">
          <LinkExternal href={getBscScanLink(account, 'address', chainId)}>{t('View on PolygonScan')}</LinkExternal>
        </Flex>
      </UserMenuItem>
    </UIKitUserMenu>
  )
}

export default UserMenu
