import { ethers } from 'ethers'
import { simpleRpcProvider } from 'utils/providers'

// Addresses
import {
  getAddress,
  getDsgAddress,
  getMasterChefAddress,
  getCakeVaultAddress,
  getMulticallAddress,
  getTradingPoolAddress,
  getAggregatorAddress,
  getLiquidityPoolAddress,
  getVDSGTokenAddress,
  getNftEarnErc20PoolAddress,
  getErc20EarnNftPoolAddress,
  getNftMarketAddress,
  getMysteryBoxAddress,
  getOracleAddress,
  getAriDropAddress
} from 'utils/addressHelpers'

// ABI
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import dsgnftAbi from 'config/abi/dsgnft.json'
import dsgTokenAbi from 'config/abi/dsgToken.json'
import ifoV1Abi from 'config/abi/ifoV1.json'
import ifoV2Abi from 'config/abi/ifoV2.json'
import masterChef from 'config/abi/masterchef.json'
import sousChef from 'config/abi/sousChef.json'
import sousChefV2 from 'config/abi/sousChefV2.json'
import sousChefBnb from 'config/abi/sousChefBnb.json'
import cakeVaultAbi from 'config/abi/cakeVault.json'
import MultiCallAbi from 'config/abi/Multicall.json'
import aggregatorAbi from 'config/abi/aggregator.json'
import liquidityPoolAbi from 'config/abi/liquidityPool.json'
import tradingPoolAbi from 'config/abi/tradingPool.json'
import vDSGTokenAbi from 'config/abi/vDSGToken.json'
import NftEarnErc20PoolAbi from 'config/abi/NftEarnErc20Pool.json'
import Erc20EarnNftPoolAbi from 'config/abi/Erc20EarnNftPool.json'
import nftMarketAbi from 'config/abi/nftMarket.json'
import mysteryBoxAbi from 'config/abi/mysteryBox.json'
import singlePoolABI from 'config/abi/singlePool.json'
import AirdropABI from 'config/abi/vDSGAirdrop.json'

const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getBep20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, address, signer)
}
export const getErc721Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(erc721Abi, address, signer)
}
export const getLpContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lpTokenAbi, address, signer)
}
export const getIfoV1Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ifoV1Abi, address, signer)
}
export const getIfoV2Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ifoV2Abi, address, signer)
}
export const getDsgNftContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(dsgnftAbi, address, signer)
}
export const getDsgContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(dsgTokenAbi, getDsgAddress(), signer)
}
export const getMasterchefContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(masterChef, getMasterChefAddress(), signer)
}
export const getCakeVaultContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeVaultAbi, getCakeVaultAddress(), signer)
}

export const getMulticallContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, getMulticallAddress(), signer)
}
export const getAggregatorContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(aggregatorAbi, getAggregatorAddress(), signer)
}
export const getLiquidityPoolContract = (signer?: ethers.Signer | ethers.providers.Provider, address?: string) => {
  return getContract(liquidityPoolAbi, address || getLiquidityPoolAddress(), signer)
}
export const getSinglePoolContract = (signer?: ethers.Signer | ethers.providers.Provider, address?: string) => {
  return getContract(singlePoolABI, address, signer)
}
export const getTradingPoolContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(tradingPoolAbi, getTradingPoolAddress(), signer)
}
export const getVDSGTokenContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(vDSGTokenAbi, getVDSGTokenAddress(), signer)
}
export const getNftEarnErc20PoolContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(NftEarnErc20PoolAbi, address, signer)
}
export const getErc20EarnNftPoolContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(Erc20EarnNftPoolAbi, getErc20EarnNftPoolAddress(), signer)
}
export const getNftMarketContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(nftMarketAbi, getNftMarketAddress(), signer)
}
export const getMysteryBoxContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(mysteryBoxAbi, getMysteryBoxAddress(), signer)
}
export const getOracleContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(mysteryBoxAbi, getOracleAddress(), signer)
}
export const getAirDropContract = (signer?: ethers.Signer | ethers.providers.Provider, address?: string) => {
  return getContract(AirdropABI, address || getAriDropAddress(), signer)
}
