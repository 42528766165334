import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import fetchTreasuryInfo from './fetchTreasuryInfo'
import fetchUserMiningInfos from './fetchUserMiningInfos'
import { swapDayDataState, swapDayData, DsgTotalData } from '../types'
import { getSwapDayData, getDsgTotalData, get24HTotalData } from './getSwapDayData'

const initialState: swapDayDataState = {
  data: [],
  dsgTotalData: {
    reserveUSD: '0',
    totalVolumeUSD: '0',
    loaded: false,
  }
}

// Async thunks
export const fetchSwapDatDataAsync = createAsyncThunk<swapDayData[]>('swapts/fetchSwapDatDataAsync', async () => {
  const data = await getSwapDayData()
  return data
})

export const fetchDsgTotalDataAsync = createAsyncThunk<DsgTotalData>('swapts/fetchDsgTotalDataAsync', async () => {
  const data = await get24HTotalData()
  // const aaa = await get24HTotalData()
  return data
})

export const swapts = createSlice({
  name: 'Swapts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Update farms with live data
    builder.addCase(fetchSwapDatDataAsync.fulfilled, (state, action) => {
      state.data = action.payload
    })
    builder.addCase(fetchDsgTotalDataAsync.fulfilled, (state, action) => {
      state.dsgTotalData = {
        ...action.payload,
        loaded: true,
      }
    })
  },
})

export default swapts.reducer
