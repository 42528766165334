import { ethers } from 'ethers'
import { createSlice } from '@reduxjs/toolkit'
import { ETHER } from 'dsgswap-sdk'
import { fetchMarketSalesData, fetchNftUpgradeFee, fetchNftSumRoyalties } from './fetchPublicData'
import {
  fetchNftUserData,
  fetchNftApprovalMarket,
  fetchaMarketllowance,
  fetchNftAllowance,
} from './fetchUserNfts'

import { NftState, NftMarketInfo, NftInfo, AppThunk } from '../types'
import { getSalesHistory } from './fetchSalesHistory'

const initialState: NftState = {
  market: {
    data: [],
    total: 0,
    limit: 20,
    loaded: false,
    loadMoreLoading: false,
    loadEnd: false,
  },
  marketHistory: {
    data: [],
    total: 0,
    limit: 20,
    loadEnd: false,
  },
  user: {
    data: [],
    loaded: false,
    allowanceLoaded: false,
  },
}

const isEqualNft = (nft1: NftInfo, nft2: NftInfo) => {
  if (!nft1 || !nft1?.properties) return false
  if (nft1.properties.token === nft2.properties.token && nft1.properties.token_id === nft2.properties.token_id)
    return true
  return false
}

export const fetchMarketSalesDataAsync =
  (account?: string): AppThunk =>
  async (dispatch) => {
    const { data, total } = await fetchMarketSalesData({ limit: 1000 })
    getSalesHistory()
    dispatch(
      setMarketSalesData({
        data,
        total,
      }),
    )
    // dispatch(fetchNftSumRoyaltiesAsync(data))
    if (account) {
      dispatch(fetchMarketAllowanceAsync(account))
    }
  }
  
  export const fetchMarketSalesDataLoadMoreAsync =
  (account?: string): AppThunk =>
  async (dispatch, getState) => {
    // const { nft } = getState()
    const state = getState()
    const { data: marketData, total: marketTotal, limit, loadEnd } = state.nfts.market || {}
    if (loadEnd) return
    const offset = marketData?.length || 0
    dispatch(setloadMoreLoading(true))
    const { data, total } = await fetchMarketSalesData({ limit, offset })
    dispatch(
      setMarketSalesDataLoadMore({
        data,
        total,
      }),
    )
    // dispatch(fetchNftSumRoyaltiesAsync(data))
    if (account) {
      dispatch(fetchMarketAllowanceAsync(account))
    }
  }

// export const fetchNftSumRoyaltiesAsync =
//   (data?: NftMarketInfo[]): AppThunk =>
//   async (dispatch) => {
//     const datas = await fetchNftSumRoyalties(data)
//     dispatch(setNftsumRoyalties(datas))
//   }

export const fetchMarketAllowanceAsync =
  (account: string): AppThunk =>
  async (dispatch) => {
    const allowance = await fetchaMarketllowance(account)
    dispatch(setMarketllowance(allowance))
  }

export const fetchNftAllowanceAsync =
  (account: string, data?: NftInfo[]): AppThunk =>
  async (dispatch, getState) => {
    const queryData = data || getState().nfts.user.data
    const allowance = await fetchNftAllowance(account, queryData)
    dispatch(setNftAllowance(allowance))
  }

export const fetchNftUserDataAsync =
  (account: string): AppThunk =>
  async (dispatch) => {
    const { data } = await fetchNftUserData(account)
    dispatch(setNftUserData(data))
    dispatch(fetchNftsApproveMarketUserAsync(account, data))
    dispatch(fetchNftAllowanceAsync(account, data))
    dispatch(fetchNftUpgradeFeeAsync(data))
  }

export const fetchNftUpgradeFeeAsync =
  (data?: NftInfo[]): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const queryData = data || state.nfts.user.data
    if (!queryData.length) return
    const levelFees = await fetchNftUpgradeFee(queryData)
    dispatch(setNftInfoLevelFees(levelFees))
  }

export const fetchNftsApproveMarketUserAsync =
  (account: string, data?: NftInfo[]): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const queryData = data || state.nfts.user.data
    if (!queryData.length) return
    const userApprovals = await fetchNftApprovalMarket(account, queryData)
    dispatch(setNftInfoApproveMarket(userApprovals))
  }

export const nftsSlice = createSlice({
  name: 'Aggregator',
  initialState,
  reducers: {
    setloadMoreLoading: (state, action) => {
      const { payload } = action
      state.market.loadMoreLoading = payload
    },
    setMarketSalesData: (state, action) => {
      const { payload } = action
      state.market.data = payload?.data?.map((item) => {
        const oldFarmData = state?.market?.data?.find((farmData) => isEqualNft(farmData.metadata, item.metadata))
        return {
          ...oldFarmData,
          ...item,
        }
      })
      state.market.total = payload?.total
      state.market.loaded = true
      if (state.market.data.length >= payload?.total)  {
        state.market.loadEnd = true
      } else {
        state.market.loadEnd = false
      }
    },
    setMarketSalesDataLoadMore: (state, action) => {
      const { payload } = action
      state.market.data = payload?.refresh ? [...payload?.data] : state.market.data.concat(payload?.data)
      state.market.total = payload?.total
      state.market.loaded = true
      if (state.market.data.length >= payload?.total)  {
        state.market.loadEnd = true
      } else {
        state.market.loadEnd = false
      }
    },
    setMarketSalesHistoryLoadMore: (state, action) => {
      const { payload } = action
      state.marketHistory.data = payload?.refresh ? [...payload?.data] : state.marketHistory.data.concat(payload?.data)
      state.marketHistory.total = payload?.total
      if (state.marketHistory.data.length >= payload?.total)  {
        state.marketHistory.loadEnd = true
      } else {
        state.marketHistory.loadEnd = false
      }
    },
    setNftUserData: (state, action) => {
      const { payload } = action
      state.user.data = payload.map((item) => {
        const oldFarmData = state?.user?.data?.find((farmData) => isEqualNft(item, farmData))
        return {
          ...oldFarmData,
          ...item,
        }
      })
      state.user.loaded = true
    },
    setNftInfoApproveMarket: (state, action) => {
      const { payload } = action
      state.user.data = state?.user?.data?.map((item) => {
        const approve = payload.find((farmData) => item.properties.token === farmData.token)
        return {
          ...item,
          ...approve,
        }
      })
    },
    setNftInfoLevelFees: (state, action) => {
      const { payload } = action
      state.user.data = state?.user?.data?.map((item) => {
        const levelFees = payload.find((farmData) => item.properties.token === farmData.token)
        return {
          ...item,
          ...levelFees,
        }
      })
    },
    setNftsumRoyalties: (state, action) => {
      const { payload } = action
      state.market.data = payload.map((item) => {
        const oldData = state.market.data.find((subItem) => isEqualNft(item.metadata, subItem.metadata))
        return {
          ...oldData,
          ...item,
        }
      })
      state.user.allowanceLoaded = true
    },
    setMarketllowance: (state, action) => {
      const { payload } = action
      state.market.data = state?.market?.data?.map((item) => {
        const allowance = payload.find(
          (farmData) => item.currency.toLowerCase() === farmData.currency.toLowerCase(),
        ) || { allowance: ethers.constants.MaxUint256.toHexString(), currencySymbol: ETHER.symbol }
        return {
          ...item,
          ...allowance,
        }
      })
      state.user.allowanceLoaded = true
    },
    setNftAllowance: (state, action) => {
      const { payload } = action
      state.user.data = state.user.data.map((item) => {
        const allowance = payload.find(
          (farmData) => item.properties.token.toLowerCase() === farmData.token.toLowerCase(),
        )
        return {
          ...item,
          ...allowance,
        }
      })
    },
  },
})

// Actions
export const {
  setloadMoreLoading,
  setMarketSalesDataLoadMore,
  setMarketSalesData,
  setNftUserData,
  setNftsumRoyalties,
  setNftInfoApproveMarket,
  setMarketllowance,
  setNftAllowance,
  setNftInfoLevelFees,
  setMarketSalesHistoryLoadMore,
} = nftsSlice.actions

export default nftsSlice.reducer
