import React, { AnchorHTMLAttributes } from "react";
import { NavLink } from "react-router-dom";
import { DropdownMenuItemType } from "../types";
// DropdownMenuItemType

export interface MenuLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  menuType?: DropdownMenuItemType
}

const MenuLink: React.FC<MenuLinkProps> = ({ href, menuType, ...otherProps }) => {
  const isHttpLink = href?.startsWith("http");

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Tag: any = isHttpLink ? "a" : NavLink;
  const target = menuType === DropdownMenuItemType.EXTERNAL_LINK ? '_blank' : '_self'
  const props = isHttpLink ? { href, target } : { to: href };
  return <Tag role="button" {...props} {...otherProps} />;
};

export default MenuLink;
