import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 1024 1024" {...props}>
      <path d="M512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m232.777143 382.72l-310.857143 310.857143a18.285714 18.285714 0 0 1-25.965714 0l-129.28-129.28a18.285714 18.285714 0 0 1 0-25.965714L305.188571 512a18.285714 18.285714 0 0 1 25.782858 0l85.394285 85.394286a7.314286 7.314286 0 0 0 10.24 0L693.028571 330.971429a18.285714 18.285714 0 0 1 25.782858 0l25.965714 25.782857a18.285714 18.285714 0 0 1 0 25.965714z" fill="#4dcdb3" p-id="2014"></path>
    </Svg>
  );
};

export default Icon;
