import singlePoolFactoryABI from 'config/abi/singlePoolFactory.json'
import { ERC20_ABI } from 'config/abi/erc20'
import { SINGLE_POOL_STAKE_ONLY_NEST } from 'config/constants'
import multicall from 'utils/multicall'
import formatWETH from 'utils/formatWETH'
import { getSinglePoolFactoryAddress } from 'utils/addressHelpers'
import { Farm } from '../types'

const chainId = process.env.REACT_APP_CHAIN_ID

// this pool has small bug in bsc-main, the minAmount value is failed
const SMALL_BUG_POOL_IN_BSC = '0x1bceCBa533780f5ac3a76867255EA4A7e824B069'

const fetchFragmentList = async (farmdata: Farm[], poolFactoryAddress) => {
  try {
    const calls = [
      {
        address: poolFactoryAddress,
        name: 'getAllPoolViews', // Function name on the contract (example: balanceOf)
      },
    ]
    const calls1 = [
      {
        address: '0x970279254e576e0a6d1a50c0e2a831a5887c1be0',
        name: 'balanceOf',
        params: [SMALL_BUG_POOL_IN_BSC],
      }
    ]
    let hackPoolBalance = '0'
    if (chainId === '56') {
      const [balanceOf] = await multicall(ERC20_ABI, calls1)
      hackPoolBalance = balanceOf.balance?.toJSON().hex
    }
    const [allPoolViews] = await multicall(singlePoolFactoryABI, calls)
    const res = allPoolViews[0].map((item) => {
      // 
      const isStakeOnce = SINGLE_POOL_STAKE_ONLY_NEST.some(pool => pool.toLowerCase() === item.pool.toLowerCase())
      const totalAmount = SMALL_BUG_POOL_IN_BSC.toLowerCase() === item.pool.toLowerCase()
        ?
        hackPoolBalance
        :
        item.totalAmount.toJSON().hex;
      
      return {
        poolFactoryAddress,
        isStakeOnce,
        totalAmount,
        poolAddress: item.pool,
        lpSymbol: `${formatWETH(item.depositToken) || item.depositSymbol}/${formatWETH(item.rewardToken) || item.rewardsSymbol}`,
        accRewardPerShare: item.accRewardPerShare.toJSON().hex,
        allocPoint: item.allocPoint.toJSON().hex,
        lastRewardBlock: item.lastRewardBlock.toJSON().hex,
        rewardsPerBlock: item.rewardsPerBlock.toJSON().hex,
        startBlock: item?.startBlock?.toJSON().hex,
        bonusEndBlock: item?.bonusEndBlock?.toJSON().hex,
        depositToken: item.depositToken,
        depositSymbol: `${formatWETH(item.depositToken) || item.depositSymbol}`,
        depositName: item.depositName,
        depositDecimals: item.depositDecimals,
        rewardsToken: item.rewardsToken,
        rewardsSymbol: `${formatWETH(item.rewardToken) || item.rewardsSymbol}`,
        rewardsName: item.rewardsName,
        rewardsDecimals: item.rewardsDecimals,
      }
    })
    return res
  } catch (error) {
    console.error(error)
    return []
  }
}

export const fetchFarmDataList = async (farmdata: Farm[]) => {
  const liquidityPoolAddress = getSinglePoolFactoryAddress()
  return fetchFragmentList(farmdata, liquidityPoolAddress)
}