import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 1024 1024" {...props}>
      <path d="M512 511.488m-509.44 0a509.44 509.44 0 1 0 1018.88 0 509.44 509.44 0 1 0-1018.88 0Z" fill="#4eceb4" p-id="2044"></path>
      <path d="M807.936 357.888l-179.712 293.376-129.024-209.408 101.888-166.4c5.12-7.68 14.848-11.264 23.04-8.704l183.808 91.136m2.048 392.192c0 16.896-14.848 23.552-33.28 14.336l-140.288-70.144 173.568-283.136v338.944m-387.072 4.608c0 16.896-12.8 24.576-27.648 16.896l-164.864-81.92c-8.704-4.608-16.384-16.896-16.384-26.624v-378.88c0-13.312 9.728-18.944 22.016-13.824l177.664 88.576 8.704 13.824v381.952m187.392-73.728l-157.696-78.848V423.424l157.696 257.536z" fill="#067d73" p-id="2045"></path>
    </Svg>
  );
};

export default Icon;
