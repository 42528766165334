import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Image, Text, Box, Flex, Tag, IndexIcon, FireIcon, PowerIcon, BoxProps } from 'pancake-uikit'
import { NftOwnerState } from 'state/types'
import { useLevelRates } from 'state/farms/hooks'
import { getNftFactoryByAddress, DsgNftType } from 'config/constants/nfts'
import StatusTag from './StatusTag'

export const scales = {
  LD: 'ld',
  MD: 'md',
  LM: 'lm',
  SM: 'sm',
  XS: 'xs',
  SS: 'ss',
} as const

export type Scale = typeof scales[keyof typeof scales]

export const scaleVariants = {
  [scales.LD]: {
    width: 372,
    height: 521,
    scale: 1,
    radii: 'card',
  },
  [scales.MD]: {
    width: 250,
    height: 350,
    scale: 0.672,
    radii: 'card',
  },
  [scales.LM]: {
    width: 158,
    height: 221,
    scale: 0.424,
    radii: 'card',
  },
  [scales.SM]: {
    width: 118,
    scale: 0.317,
    height: 165,
    radii: 'nftImage',
  },
  [scales.XS]: {
    width: 40,
    scale: 0.107,
    height: 56,
    radii: 'small',
  },
  [scales.SS]: {
    width: 29.76,
    scale: 0.08,
    height: 41.68,
    radii: 'small',
  },
}
export interface NftCardImgPorps extends BoxProps {
  power?: number
  level?: number
  bg?: string
  src?: string
  name?: string
  scale?: Scale
  id?: string
  nft?: string
  showTag?: boolean
  grayscale?: boolean
  blur?: boolean
  tagLable?: string
  toDetail?: boolean
}

const BoxStyled = styled(Box)<{ scale: Scale; grayscale: boolean, blur: boolean }>`
  position: relative;
  border-radius: ${({ theme, scale }) => theme.radii[scaleVariants[scale].radii]};
  width: ${({ scale }) => scaleVariants[scale].width}px;
  height: ${({ scale }) => scaleVariants[scale].height}px;
  filter: ${({ theme, grayscale, blur }) => 
    `${theme.filter.brightness} ${grayscale ? theme.filter.grayscale : ''} ${blur ? theme.filter.blur : ''}`};
  overflow: hidden;
  cursor: ${({ onClick }) => onClick ? 'pointer' : 'default'};
  user-select: none;
  -webkit-user-drag: none;

`
const BoxInnerStyled = styled(Box)<{ scale: Scale }>`
  position: relative;
  width: ${scaleVariants[scales.LD].width}px;
  height: ${scaleVariants[scales.LD].height}px;
  transform: scale(${({ scale }) => scaleVariants[scale].scale});
  transform-origin: 0 0;
`

const NftImageStyled = styled(Image)`
  border-radius: ${({ theme }) => theme.radii.nftImage};
`

const NftTitleStyled = styled(Text)`
  position: absolute;
  top: 28px;
  left: 38px;
  font-size: 20px;
  font-weight: bold;
`
const InfoBox = styled(Box)`
  position: absolute;
  bottom: 12px;
  right: 0;
  left: 0;
  padding: 0 14px;
  /* height: 80px; */
`
const NftLevelStyled = styled(Box)`
  position: absolute;
  top: 304px;
  left: 18px;
  padding: 6px 0 0 28px;
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  width: 80px;
  height: 38px;
  background: url('/images/nfts/level.png');
  color: white;
  background-size: 100%;
  &::before {
    content: '';
  }
`

const NftIdStyled = styled(Text)`
  position: absolute;
  top: 0;
  right: 14px;
`
const NameStyled = styled(Text)`
  position: absolute;
  top: 360px;
  width: 100%;
  padding: 0 18px;
`
const FoodNameStyled = styled(Text)`
  position: absolute;
  top: 21px;
  width: 100%;
  padding: 0 18px;
`
const ValueStyled = styled(Flex)`
  position: absolute;
  bottom: 22px;
  width: 100%;
  padding: 0 18px;
`
const TagStyled = styled(Tag)`
  width: 100%;
  height: 41px;
  border-radius: 40px;
`

const LinkStyled = styled(Link)`
  display: block;
  position: relative;
  z-index: 10;
`

const NftCardImg: React.FC<NftCardImgPorps> = ({
  level,
  power,
  src,
  bg = '/images/nfts/nft-1.png',
  name,
  scale,
  id,
  showTag,
  tagLable,
  grayscale,
  nft,
  children,
  blur,
  toDetail,
  ...props
}) => {
  const { width, height } = scaleVariants[scale]
  const nftFactory = getNftFactoryByAddress(nft)
  // useLevelRates
  const levelRates = useLevelRates()

  return (
    <BoxStyled blur={blur} grayscale={grayscale} scale={scale} {...props}>

      <BoxInnerStyled
        to={ toDetail ? `/nftdetail/info/${nft}/${id}` : undefined}
        as={ toDetail ? LinkStyled : undefined }
        scale={scale}>

        {showTag && <StatusTag lable={tagLable} />}
        <NftImageStyled
          src={src || bg}
          width={scaleVariants[scales.LD].width}
          height={scaleVariants[scales.LD].height}
        />
        {nftFactory?.dsgNftType === DsgNftType.FOOD_NFT && name && (
          <>
            <FoodNameStyled maxLine={1} lineHeight={1} fontSize="60px" bold textAlign="center" color="white">
              {name}
            </FoodNameStyled>
          </>
        )}
        {nftFactory?.dsgNftType === DsgNftType.MD_NFT && level && <NftLevelStyled>{level}</NftLevelStyled>}
        {nftFactory?.dsgNftType === DsgNftType.MD_NFT && name && (
          <NameStyled maxLine={2} lineHeight={1} fontSize="42px" textAlign="left" bold color="white">
            {name}
          </NameStyled>
        )}
        {(nftFactory?.dsgNftType === DsgNftType.MD_NFT || nftFactory?.dsgNftType === DsgNftType.FOOD_NFT) && (
          <ValueStyled>
            <Flex flex="1" mt="4px" mr="16px" alignItems="center">
              <TagStyled scale="md" outline variant="white" startIcon={<IndexIcon />}>
                <Text style={{flex: 1}} textAlign="center" fontSize="24px" bold color="white">
                  #{id}
                </Text>
              </TagStyled>
            </Flex>
            <Flex flex="1" mt="4px" alignItems="center">
              <TagStyled
                outline
                variant="white"
                startIcon={nftFactory?.dsgNftType === DsgNftType.FOOD_NFT ? <PowerIcon /> : <FireIcon />}
              >
                <Text style={{flex: 1}} textAlign="center" fontSize="24px" bold color="white">
                  {nftFactory?.dsgNftType === DsgNftType.FOOD_NFT ? (levelRates[level] ? `${levelRates[level]}%` :  '--') : power}
                </Text>
              </TagStyled>
            </Flex>
          </ValueStyled>
        )}
      </BoxInnerStyled>
    </BoxStyled>
  )
}

NftCardImg.defaultProps = {
  scale: scales.MD,
}

export default NftCardImg
