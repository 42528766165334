import React from 'react'
import styled, { DefaultTheme } from 'styled-components'
import { BoxProps, Box, Flex, FlexProps } from 'pancake-uikit'
import Container from 'components/Layout/Container'
import CurvedDivider from './CurvedDivider'
import { ClipFill, DividerFill, SectionBackground, sectionBackground } from './types'
import { backgroundVariants } from './theme'
// backgroundVariants

interface PageSectionProps extends BackgroundColorProps {
  svgFill?: string
  dividerComponent?: React.ReactNode
  hasCurvedDivider?: boolean
  dividerPosition?: 'top' | 'bottom'
  concaveDivider?: boolean
  containerProps?: BoxProps
  innerProps?: BoxProps
  clipFill?: ClipFill
  dividerFill?: DividerFill
}

interface BackgroundColorProps extends FlexProps {
  index: number
  background?: string | SectionBackground
  backgroundPositionX?: string
  getPadding?: () => string
}

interface ThemedProps extends BackgroundColorProps {
  theme: DefaultTheme
  // background: string|SectionBackground
}
const getBackground = ({ theme, background }: ThemedProps) => {
  if (backgroundVariants[background]) {
    const themeKey = theme.isDark ? 'dark' : 'light'
    const backgroundImage = backgroundVariants[background][themeKey]

    return `
      background: url(${backgroundImage}) no-repeat right;
      background-size: cover;
    `
  }
  return `
    background: ${background || theme.colors.background};
  `
}

const BackgroundColor = styled(Flex)<BackgroundColorProps>`
  position: relative;
  flex-direction: column;
  align-items: center;
  z-index: ${({ index }) => index - 1};
  /* background: ${({ background, theme }) => background || theme.colors.background}; */
  padding: ${({ getPadding }) => getPadding()};
  /* height: calc(100vh - 72px); */
  height: 100%;
  /* margin: 0; */
  overflow: auto;
  ${getBackground};
  background-position-x: ${({ background, backgroundPositionX }) => backgroundPositionX || (background === sectionBackground.BGIMAGE3 ? '80%' : 'right') };
  ${({ theme }) => theme.mediaQueries.sm} {
    background-position-x: ${({ backgroundPositionX }) => backgroundPositionX || 'right'};
  }
`

const ChildrenWrapper = styled(Container)`
  min-height: auto;
  width: 100%;
  /* padding-top: 16px;
  padding-bottom: 16px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-top: 48px;
    padding-bottom: 48px;
  } */
`

const PageSection: React.FC<PageSectionProps> = ({
  children,
  background,
  svgFill,
  index = 1,
  dividerComponent,
  dividerPosition = 'bottom',
  hasCurvedDivider = false,
  concaveDivider = false,
  clipFill,
  dividerFill,
  containerProps,
  innerProps,
  ...props
}) => {
  const getPadding = () => {
    return '0'
    // No curved divider
    // if (!hasCurvedDivider) {
    //   return '48px 0'
    // }
    // // Bottom curved divider
    // // Less bottom padding, as the divider is present there
    // if (dividerPosition === 'bottom') {
    //   return '48px 0 14px'
    // }
    // // Top curved divider
    // // Less top padding, as the divider is present there
    // if (dividerPosition === 'top') {
    //   return '14px 0 48px'
    // }
    // return '48px 0'
  }

  return (
    <Box height="100%" {...containerProps}>
      {hasCurvedDivider && dividerPosition === 'top' && (
        <CurvedDivider
          svgFill={svgFill}
          index={index}
          concave={concaveDivider}
          dividerPosition={dividerPosition}
          dividerComponent={dividerComponent}
          clipFill={clipFill}
          dividerFill={dividerFill}
        />
      )}
      <BackgroundColor background={background} index={index} getPadding={getPadding} {...props}>
        <ChildrenWrapper {...innerProps}>{children}</ChildrenWrapper>
      </BackgroundColor>
      {hasCurvedDivider && dividerPosition === 'bottom' && (
        <CurvedDivider
          svgFill={svgFill}
          index={index}
          concave={concaveDivider}
          dividerPosition={dividerPosition}
          dividerComponent={dividerComponent}
          clipFill={clipFill}
          dividerFill={dividerFill}
        />
      )}
    </Box>
  )
}

export default PageSection
