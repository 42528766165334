import { useMemo } from 'react'
import { useTranslation } from 'contexts/Localization'

import nft, { methods as nftMethods } from 'config/constants/optonsConfig/nft'
import pools, { methods as poolsMethods } from 'config/constants/optonsConfig/pools'

export { optionType } from 'config/constants/optonsConfig'

export const useNftOptions = (type: nftMethods) => {
  const { t } = useTranslation()

  return  nft[type](t)
}

// const bagFilterOption = useNftOptions(optionType.NFT_GET_FILTER_BAG_OPTION)
// const nftHistoryOption = useNftOptions(optionType.NFT_GET_HISTORY_OPTION)
// const nftSortOption = useNftOptions(optionType.NFT_GET_SORT_OPTION)

export const useNftBagFilterOption = () => {
  const { t } = useTranslation()

  return  nft[nftMethods.NFT_GET_FILTER_BAG_OPTION](t)
}
export const useNftHistoryOption = () => {
  const { t } = useTranslation()

  return  nft[nftMethods.NFT_GET_HISTORY_OPTION](t)
}
export const useNftSortOption = () => {
  const { t } = useTranslation()

  return  nft[nftMethods.NFT_GET_SORT_OPTION](t)
}


export const usePoolsOptions = (type: poolsMethods) => {
  const { t } = useTranslation()
  
  return pools[type](t)
}
