import sample from 'lodash/sample'
import { ChainId, ETHEREUM_CHAIN } from 'dsgswap-sdk'

const getNodeUrl = () => {
  const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10) as ChainId
  // return process.env.REACT_APP_NODE_3
  return sample(ETHEREUM_CHAIN[chainId].rpcUrls)
  // return 'https://polygon-mumbai.infura.io/v3/330472ed44dd4692a16dfcb4cc41f122'
}

export default getNodeUrl
