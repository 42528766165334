import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";
// import close from './assets/close.png'
import close from './assets/cancle.png'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 60 60" {...props}>
      <image xlinkHref={close} />

      {/* <path d="M704 108.8c6.4 0 12.8 6.4 19.2 6.4 19.2 12.8 44.8 6.4 57.6-12.8s6.4-44.8-12.8-57.6c-6.4-6.4-12.8-6.4-19.2-12.8-19.2-12.8-44.8 0-51.2 19.2-19.2 19.2-12.8 44.8 6.4 57.6zM934.4 204.8c-12.8-19.2-38.4-25.6-57.6-12.8-6.4 6.4-12.8 12.8-19.2 25.6 0 12.8 0 19.2 6.4 32 44.8 70.4 70.4 153.6 70.4 236.8a428.8 428.8 0 0 1-857.6 0c0-160 89.6-307.2 230.4-384 19.2-12.8 25.6-32 19.2-51.2-12.8-19.2-32-25.6-51.2-19.2-172.8 89.6-275.2 262.4-275.2 454.4 0 281.6 230.4 512 512 512s512-230.4 512-512c-6.4-96-32-198.4-89.6-281.6z" fill="#067d73" p-id="3934"></path>
      <path d="M307.2 332.8l147.2 147.2c6.4 6.4 6.4 6.4 0 12.8l-147.2 147.2c-12.8 12.8-12.8 32-6.4 44.8 12.8 25.6 38.4 25.6 57.6 6.4 51.2-51.2 102.4-96 147.2-147.2 6.4-6.4 6.4-6.4 12.8 0 51.2 51.2 102.4 102.4 153.6 147.2 19.2 19.2 38.4 19.2 57.6 0 12.8-12.8 12.8-38.4 0-51.2l-70.4-70.4-76.8-76.8c-6.4-6.4-6.4-6.4 0-12.8 0 0 0-6.4 6.4-6.4l134.4-134.4c19.2-19.2 19.2-38.4 0-51.2-12.8-12.8-38.4-12.8-51.2 0l-147.2 147.2c-6.4 6.4-6.4 6.4-12.8 0l-57.6-57.6-96-96c-12.8-12.8-32-12.8-44.8-6.4-19.2 12.8-25.6 38.4-6.4 57.6z" fill="#067d73" p-id="3935"></path> */}
    </Svg>
  );
};
// Icon.defaultProps = {
//   width: '32px',
//   height: '32px',
// }
export default Icon;
