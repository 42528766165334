import dsgnftAbi from 'config/abi/dsgnft.json'
import uniq from 'lodash/uniq'
import multicall from 'utils/multicall'
import { getMarketSales } from 'config/axios'
import { NftInfo, NftMarketInfo } from 'state/types'
import { canUpgradeNfts } from 'config/constants/nfts'

export const fetchMarketSalesData = async (params) => {
  try {
    const sales = await getMarketSales(params)
    const data = (sales.list || []).map((item: NftMarketInfo) => ({
      ...item,
      key: item.metadata
        ? `${item.metadata.properties.token}_${item.metadata.properties.token_id}_${item.metadata.properties.owner_status}`
        : item.id,
    }))
    return {
      data,
      total: sales.total,
    }
  } catch (error) {
    console.error(error)
    return {
      data: [],
      total: 0,
    }
  }
}

export const fetchNftSumRoyalties = async (farmsToFetch: NftMarketInfo[]) => {
  try {
    const calls = farmsToFetch.map((nftInfo) => {
      return {
        address: nftInfo.metadata.properties.token,
        name: 'sumRoyalties',
        params: [nftInfo.metadata.properties.token_id],
      }
    })
    const sumRoyalties = await multicall(dsgnftAbi, calls)
    return sumRoyalties.map((item, index) => ({
      // tokenId: calls[index].params[0],
      // token: calls[index].address,
      ...farmsToFetch[index],
      sumRoyalties: item[0].toHexString(),
    }))
  } catch (error) {
    console.error(error)
    return farmsToFetch
  }
}
export const fetchNftSumRoyaltie = async (token: string, tokenId: string) => {
  try {
    const calls = [{
      address: token,
      name: 'sumRoyalties',
      params: [tokenId],
    }]
    const sumRoyalties = await multicall(dsgnftAbi, calls)
    return sumRoyalties[0]
  } catch (error) {
    console.error(error)
    return null
  }
}

export const fetchNftUpgradeFee = async (nftInfos: NftInfo[]) => {
  const tokens = nftInfos.map((farm) => farm.properties.token)
  // const dsgFoodNftAddress = getDsgFoodNftAddress()
  // const mysteryBoxAddress = getMysteryBoxAddress()
  const tokensUniq = uniq(tokens).filter((token) => {
    return canUpgradeNfts.some((subToken) => subToken.toLowerCase() === token.toLowerCase())
  })
  try {
    const calls1 = tokensUniq.map((token) => {
      return {
        address: token,
        name: 'maxLevel',
      }
    })
    const maxLevels = await multicall(dsgnftAbi, calls1)
    let callIndex = 0
    const calls2 = tokensUniq.map((token, index) => {
      let arr = []
      for (let i = 1; i <= maxLevels[index][0].toNumber(); i++) {
        arr = [
          ...arr,
          {
            address: token,
            name: 'getUpgradeFee',
            params: [i],
            customId: callIndex,
          },
        ]
        callIndex += 1
      }
      return arr
    })
    const levelfees = await multicall(dsgnftAbi, calls2.flat())
    const res = calls2.map((item, index) => {
      const fees = {}
      item.forEach((subItem) => {
        fees[subItem.params[0]] = levelfees[subItem.customId][0].toJSON().hex
      })
      return {
        token: tokensUniq[index],
        levelFees: { ...fees },
      }
    })
    return res
  } catch (error) {
    console.error(error)
    return []
  }
}
