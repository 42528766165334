import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 100 100" {...props}>
      <path d="M62.2857 87.9644L25.4728 66.7006C24.1796 65.953 23.3839 64.5736 23.3839 63.0797V20.5003C23.3839 19.8561 22.6869 19.4534 22.1289 19.7761L14.5964 24.1311C11.7514 25.7765 10 28.8124 10 32.0972V67.9051C10 71.1911 11.7514 74.227 14.5964 75.8712L45.5468 93.7641C48.3956 95.412 51.9081 95.412 54.7581 93.7641L62.2857 89.4116C62.8436 89.0914 62.8436 88.2871 62.2857 87.9644Z" fill="#495BFF"/>
      <path d="M32.1596 62.8185L48.0511 72.0064C49.3468 72.7552 50.943 72.7552 52.2374 72.0064L68.1326 62.8185C69.4258 62.0709 70.2214 60.6915 70.2214 59.1976V40.8108C70.2214 39.3169 69.4246 37.9375 68.1326 37.1899L52.2386 28.0008C50.943 27.252 49.3468 27.252 48.0524 28.0008L44.1087 30.2805C43.8292 30.4419 43.4806 30.2399 43.4806 29.9184L43.5065 8.90093C43.5077 8.25679 42.8094 7.85282 42.2515 8.17551L32.1879 13.9937C30.896 14.74 30.1004 16.1194 30.0991 17.6121L30.072 59.1951C30.0696 60.6891 30.8664 62.0709 32.1596 62.8185ZM41.3622 46.6314C41.3622 45.5759 41.9251 44.6005 42.8389 44.0721L48.6669 40.7037C49.582 40.1741 50.7102 40.1741 51.6253 40.7037L57.4533 44.0721C58.3671 44.6005 58.93 45.5759 58.93 46.6314V53.3757C58.93 54.4312 58.3671 55.4067 57.4533 55.935L51.6253 59.3035C50.7102 59.8331 49.582 59.8331 48.6669 59.3035L42.8389 55.935C41.9251 55.4067 41.3622 54.4312 41.3622 53.3757V46.6314Z" fill="#495BFF"/>
      <path d="M85.7047 24.12L54.7542 6.22591C53.4574 5.47586 52.0238 5.0682 50.5778 5.00046C50.3401 4.98937 50.1418 5.1815 50.1418 5.41921V18.5433C50.1418 18.8426 50.3007 19.1184 50.5594 19.2675L74.8283 33.2906C76.1215 34.0382 76.9171 35.4176 76.9171 36.9116V63.0674C76.9171 64.5613 76.1203 65.9407 74.8283 66.6883L58.0968 76.3614C57.5401 76.6841 57.5401 77.4883 58.0968 77.8098L69.3858 84.3275C69.9043 84.6268 70.5423 84.6268 71.0596 84.3275L85.7047 75.8614C88.5485 74.2172 90.3011 71.1812 90.3011 67.8953V32.0861C90.3011 28.8002 88.5497 25.7642 85.7047 24.12Z" fill="#495BFF"/>
    </Svg>
  );
};

export default Icon;
