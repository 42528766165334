import liquidityPoolABI from 'config/abi/liquidityPool.json'
import multicall from 'utils/multicall'
import formatWETH from 'utils/formatWETH'
import { getFFragmentPoolAddress, getIncentivePoolAddress, getTFragmentPoolAddress, getV1FarmPoolAddress } from 'utils/addressHelpers'
import { Farm, FarmPoolType } from '../types'

const fetchFragmentList = async (farmdata: Farm[], liquidityPoolAddress, farmPoolType) => {
  try {
    const calls = [
      {
        address: liquidityPoolAddress,
        name: 'getAllPoolViews', // Function name on the contract (example: balanceOf)
      },
      {
        address: liquidityPoolAddress,
        name: 'nftSlotFee', // Function name on the contract (example: balanceOf)
      },
      // {
      //   address: liquidityPoolAddress,
      //   name: 'ratePerNftPower' // Function name on the contract (example: balanceOf)
      // },
      // {
      //   address: liquidityPoolAddress,
      //   name: 'maxNftAdditionalRate' // Function name on the contract (example: balanceOf)
      // },
      {
        address: liquidityPoolAddress,
        name: 'rewardToken', // Function name on the contract (example: balanceOf)
      },
    ]

    const [allPoolViews, nftSlotFee, rewardToken] = await multicall(liquidityPoolABI, calls)
    const res = allPoolViews[0].map((item) => {
      const oldFarm = farmdata.find((subItem) => subItem.pid === item.pid.toNumber())
      const token = oldFarm?.token || {}
      const quoteToken = oldFarm?.quoteToken || {}
      return {
        liquidityPoolAddress,
        farmPoolType,
        rewardToken: rewardToken[0],
        accRewardAmount: item.accRewardAmount.toJSON().hex,
        accRewardPerShare: item.accRewardPerShare.toJSON().hex,
        allocPoint: item.allocPoint.toJSON().hex,
        allocRewardAmount: item.allocRewardAmount.toJSON().hex,
        lastRewardBlock: item.lastRewardBlock.toJSON().hex,
        rewardsPerBlock: item.rewardsPerBlock.toJSON().hex,
        totalAmount: item.totalAmount.toJSON().hex,
        pid: item.pid.toNumber(),
        nftSlotFee: nftSlotFee[0].toJSON().hex,
        ratePerNftPower: '0',
        maxNftAdditionalRate: '0',
        // ratePerNftPower: ratePerNftPower[0].toJSON().hex,
        // maxNftAdditionalRate: maxNftAdditionalRate[0].toJSON().hex,
        lpSymbol: `${formatWETH(item.token0) || item.symbol0?.toUpperCase()}/${formatWETH(item.token1) || item.symbol1?.toUpperCase()} LP`,
        additionalNft: item.additionalNft,
        lpAddresses: {
          222: item.lpToken,
          56: item.lpToken,
          80001: item.lpToken,
        },
        token: {
          ...token,
          symbol: item.symbol0?.toUpperCase(),
          token: item.token0,
          address: {
            56: item.token0,
            80001: item.token0,
            222: item.token0,
          },
          decimals: item.decimals0,
          projectLink: '',
        },
        quoteToken: {
          ...quoteToken,
          symbol: item.symbol1?.toUpperCase(),
          token: item.token1,
          address: {
            56: item.token1,
            80001: item.token1,
            222: item.token1,
          },
          decimals: item.decimals1,
          projectLink: '',
        },
      }
    })
    return res
  } catch (error) {
    return []
  }
}

export const fetchFFragmentList = async (farmdata: Farm[]) => {
  const liquidityPoolAddress = getFFragmentPoolAddress()
  return fetchFragmentList(farmdata, liquidityPoolAddress, FarmPoolType.FF_FARM)
}

export const fetchTFragmentList = async (farmdata: Farm[]) => {
  const liquidityPoolAddress = getTFragmentPoolAddress()
  return fetchFragmentList(farmdata, liquidityPoolAddress, FarmPoolType.TF_FARM)
}
export const fetchIncentiveFarmragmentList = async (farmdata: Farm[]) => {
  const liquidityPoolAddress = getIncentivePoolAddress()
  return fetchFragmentList(farmdata, liquidityPoolAddress, FarmPoolType.INCENTIVE_FARM)
}
export const fetchV1FarmragmentList = async (farmdata: Farm[]) => {
  const liquidityPoolAddress = getV1FarmPoolAddress()
  return fetchFragmentList(farmdata, liquidityPoolAddress, FarmPoolType.INCENTIVE_FARM)
}
