import React from 'react'
import { useWeb3React } from '@web3-react/core'
import { Flex, Box, Language, LangSelector } from 'pancake-uikit'
// import useAuth from 'hooks/useAuth'
import ConnectWalletButton from 'components/ConnectWalletButton'
// import { useHistory } from 'react-router-dom'
// import { CardShowType } from 'components/NftCard'
// import { FetchStatus, useGetBnbBalance } from 'hooks/useTokenBalance'
// import { useTranslation } from 'contexts/Localization'
// import WalletModal, { WalletView, LOW_BNB_BALANCE } from './WalletModal'
// import WalletUserMenuItem from './WalletUserMenuItem'
import UIUserWallet from './UIUserWallet'
import UserBag from './UserBag'

interface UserMenuProps {
  currentLang: string
  isPushed?: boolean
  isMobile?: boolean
  langs: Language[]
  setLang: (lang: Language) => void
}

const UserMenu: React.FC<UserMenuProps> = ({ langs, isPushed, isMobile, setLang, currentLang }) => {
  // const { t } = useTranslation()
  const { account } = useWeb3React()
  // const { logout } = useAuth()
  // const { balance, fetchStatus } = useGetBnbBalance()
  // const { isInitialized, isLoading, profile } = useProfile()
  // const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  // const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  // const hasProfile = isInitialized && !!profile
  // const avatarSrc = profile && profile.nft ? `/images/nfts/${profile.nft.images.sm}` : undefined
  // const hasLowBnbBalance = fetchStatus === FetchStatus.SUCCESS && balance.lte(LOW_BNB_BALANCE)

  // const { push } = useHistory()
  // const pushNftBag = useCallback(() => {
  //   push(`/nft/${CardShowType.BAG}`)
  // }, [push])
  // const pushNftErc20 = useCallback(() => {
  //   push(`/nft/${CardShowType.ERC20}`)
  // }, [push])

  if (!account) {
    return (
      <Flex alignItems="center">
        <ConnectWalletButton scale="sm" />
        <Box ml="15px">
          <LangSelector currentLang={currentLang} langs={langs} setLang={setLang} />
        </Box>
      </Flex>
    )
  }
  if (isMobile) {
    if (isPushed) {
      return (
        <Flex>
          <Box ml="24px">
            <UIUserWallet />
          </Box>
          <Box ml="24px">
            <LangSelector currentLang={currentLang} langs={langs} setLang={setLang} />
          </Box>
        </Flex>
      )
    }
    return (
      <UserBag />
    )
  }
  return (
    <Flex alignItems="center">
      {/* <Box mr="24px">
        <UserBalances onIconClick={pushNftErc20}/>
      </Box>
      <Box mr="24px">
        <IconButton onClick={pushNftBag} variant="text">
          <BagIcon mt="13px" width="52px" />
        </IconButton>
      </Box> */}
      <Box mr="0">
        <UserBag />
      </Box>
      {/* <Box mr="24px">
        <UserFarms />
      </Box> */}
      {/* <Box mr="24px">
        <UserBalance />
      </Box> */}
      <Box className="show-media-nav">
        <Flex>
          <Box ml="24px">
            <UIUserWallet />
          </Box>
          <Box ml="24px">
            <LangSelector currentLang={currentLang} langs={langs} setLang={setLang} />
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
}

export default UserMenu
