import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 1024 1024" {...props}>
      <path d="M369.60079053 20.07545719h543.70607408a90.61767941 90.61767941 0 0 1 90.6176782 90.6176782v543.70607408a90.61767941 90.61767941 0 0 1-90.6176782 90.61767942H369.60079053a90.61767941 90.61767941 0 0 1-90.61767942-90.61767942V110.69313539a90.61767941 90.61767941 0 0 1 90.61767942-90.6176782z m0 77.6722963a12.94538311 12.94538311 0 0 0-12.94538312 12.9453819v543.70607408a12.94538311 12.94538311 0 0 0 12.94538312 12.94538312h543.70607408a12.94538311 12.94538311 0 0 0 12.9453819-12.94538312V110.69313539a12.94538311 12.94538311 0 0 0-12.9453819-12.9453819H369.60079053z m297.74380206 724.94143169a38.83614815 38.83614815 0 0 1 77.6722963 0v90.61767943a90.61767941 90.61767941 0 0 1-90.61767942 90.6176782H110.69313539a90.61767941 90.61767941 0 0 1-90.6176782-90.6176782V369.60079053a90.61767941 90.61767941 0 0 1 90.6176782-90.61767942h90.61767943a38.83614815 38.83614815 0 0 1 0 77.6722963h-90.61767943a12.94538311 12.94538311 0 0 0-12.9453819 12.94538312v543.70607408a12.94538311 12.94538311 0 0 0 12.9453819 12.9453819h543.70607408a12.94538311 12.94538311 0 0 0 12.94538312-12.9453819v-90.61767943z" p-id="2561"></path>
    </Svg>
  );
};


export default Icon;
