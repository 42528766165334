import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import { getBalanceAmount } from 'utils/formatBalance'
import { isBNBBUSDLp, hasDSGLp, hasSymbolFarm } from 'utils/getLiquidityPool'
import useRefresh from 'hooks/useRefresh'
import {
  fetchFarmsPublicDataList,
} from '.'
import { State,SinglePoolState } from '../types'

export const useFetchSinglePollData = () => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const { account } = useWeb3React()

  useEffect(() => {

    dispatch(fetchFarmsPublicDataList(account))

  }, [dispatch, slowRefresh, account])
}


export const useSinglePollData = (): SinglePoolState => {
  const pools = useSelector((state: State) => state.pools)
  return pools
}