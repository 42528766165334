import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 1024 1024" {...props}>
      <path d="M514.42725925 1014.56402963c-277.07164445 0-502.44266667-225.37102222-502.44266666-502.44266666s225.37102222-502.44266667 502.44266666-502.44266667 502.44266667 225.37102222 502.44266667 502.44266667-225.37102222 502.44266667-502.44266667 502.44266666z m0-936.07253333c-239.08503703 0-433.62986667 194.54482963-433.62986666 433.62986667S275.34222222 945.75122963 514.42725925 945.75122963s433.62986667-194.54482963 433.62986667-433.62986666S753.5122963 78.4914963 514.42725925 78.4914963z" p-id="2429"></path><path d="M784.45985185 470.3725037h-540.06518518V401.5597037h435.69303703l-36.16616295-26.82121481 40.8993185-55.34151111 120.02797038 88.83768889c11.89357037 8.8594963 16.86945185 24.27259259 12.25765926 38.47205925-4.61179259 14.19946667-17.84035555 23.66577778-32.64663704 23.66577778zM344.03365925 704.84574815l-120.02797036-88.8376889c-11.89357037-8.8594963-16.86945185-24.27259259-12.25765926-38.47205925 4.61179259-14.0781037 17.84035555-23.66577778 32.64663704-23.66577778h540.06518518v68.8128h-435.69303703l36.16616296 26.82121481-40.89931853 55.34151112z" p-id="2430"></path>
    </Svg>
  );
};

export default Icon;
