import React, { useCallback, useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import { Flex, Box, Text, Skeleton, useModal, InputPanel, Button, BoxProps } from 'pancake-uikit'
import { Farm, NftInfo, SinglePoolView } from 'state/types'
import BlockTime from 'components/BlockTime'
import { useTranslation } from 'contexts/Localization'
import { BASE_TRADE_SWAP_URL_OUT } from 'config'
import CardHeading from './CardHeading'
import CardActionsContainer from './CardActionsContainer'
import ApyButton from './ApyButton'

export interface FarmWithStakedValue extends SinglePoolView, BoxProps {
  apr?: number
  lpRewardsApr?: number
  liquidity?: BigNumber
  depositTokenPriceBusd?: BigNumber
  rewardsTokenPriceBusd?: BigNumber
}

export const FCard = styled(Box)<{ background?: string }>`
  /* align-self: baseline; */
  background: ${({ theme, background }) => background || theme.card.background};
  border-radius: ${({ theme }) => theme.radii.card};
  box-shadow: 0px 1px 4px rgba(25, 19, 38, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 24px;
  position: relative;
  max-width: 384px;
  min-width: 300px;
  width: auto;
  flex: 1;
`

interface FarmCardProps {
  farm: FarmWithStakedValue
  displayApr: string
  removed: boolean
  userDataLoaded?: boolean
  cakePrice: BigNumber
  account: string
}

const FarmCard: React.FC<FarmCardProps> = ({ farm, displayApr, userDataLoaded, removed, cakePrice, account, ...props }) => {
  const { t } = useTranslation()

  const {
    allowance: allowanceAsString = 0,
  } = farm.userData || {}


  const allowance = new BigNumber(allowanceAsString)
  const isApproved = account && allowance && allowance.isGreaterThan(0)

  const totalValueFormatted =
    farm.liquidity && farm.liquidity.gt(0)
      ? `$${farm.liquidity.toNumber().toLocaleString(undefined, { maximumFractionDigits: 0 })}`
      : ''

  const addLiquidityUrl = `${BASE_TRADE_SWAP_URL_OUT}${farm.depositToken}`


  return (
    <FCard {...props}>
      <CardHeading
        lpAddress={farm.poolAddress}
        depositSymbol={farm.depositSymbol}
        rewardsSymbol={farm.rewardsSymbol}
        depositToken={farm.depositToken}
        rewardsToken={farm.rewardsToken}
      />
      <Flex justifyContent="space-between">
        {removed ? <Box /> : (
          <Box>
            <Text>{t('APR')}</Text>
            <Text bold color="textPrimary" style={{ display: 'flex', alignItems: 'center' }}>
              {farm.apr ? (
                <>
                  <ApyButton
                    // variant="text-and-button"
                    lpSymbol={farm.depositSymbol}
                    lpLabel={`${farm.depositSymbol} earn ${farm.rewardsSymbol}`}
                    addLiquidityUrl={addLiquidityUrl}
                    cakePrice={cakePrice}
                    stakingTokenBalance={farm?.userData?.stakedBalance}
                    stakingTokenPrice={farm.depositTokenPriceBusd}
                    rewardsTokenPrice={farm.rewardsTokenPriceBusd}
                    stakingSymbol={farm.depositSymbol}
                    rewardsSymbol={farm.rewardsSymbol}
                    apr={farm.apr}
                    displayApr={displayApr}
                  />
                  {/* {displayApr}% */}
                </>
              ) : (
                <Skeleton height={24} width={80} />
              )}
            </Text>
          </Box>
        )}
        <Flex alignItems="flex-end" flexDirection="column">
          <Text>{t('Total staked')}</Text>
          {totalValueFormatted ? <Text>{totalValueFormatted}</Text> : <Skeleton width={75} height={25} />}
        </Flex>
      </Flex>
      <CardActionsContainer
        removed={removed}
        userDataLoaded={userDataLoaded}
        farm={farm}
        account={account}
        addLiquidityUrl={addLiquidityUrl}
      />
      <BlockTime downloadType="time" mt="8px" endBlock={Number(farm.bonusEndBlock)} startBlock={Number(farm.startBlock)} />
    </FCard>
  )
}

export default FarmCard
