import React from 'react'
import { Menu as UikitMenu } from 'pancake-uikit'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import { usePriceDsgBusd } from 'state/farms/hooks'
import config from './config'
import UserMenu from './UserMenu'
import PanleSlot from './UserMenu/PanleSlot'

const Menu = (props) => {
  const { isDark, toggleTheme } = useTheme()
  const cakePriceUsd = usePriceDsgBusd()
  const { currentLanguage, setLanguage, t } = useTranslation()
  return (
    <UikitMenu
      userMenu={<UserMenu currentLang={currentLanguage.code} langs={languageList} setLang={setLanguage} />}
      panelSlot={<PanleSlot currentLang={currentLanguage.code} langs={languageList} setLang={setLanguage} />}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={currentLanguage.code}
      langs={languageList}
      setLang={setLanguage}
      cakePriceUsd={cakePriceUsd.toNumber()}
      links={config(t)}
      {...props}
    />
  )
}

export default Menu
