import React, { useCallback, useState } from 'react'
import BigNumber from 'bignumber.js'
import { Button, Flex, Heading, Box, Text, SpeedUpIcon, ArrowDropRightIcon, useLoading } from 'pancake-uikit'
import { useTranslation } from 'contexts/Localization'
import { useAppDispatch } from 'state'
import { CurrencyLogo } from 'components/Logo'
import { fetchFarmsPublicDataList } from 'state/pools'
import { useToken } from 'hooks/Tokens'
import useToast from 'hooks/useToast'
import { getBalanceAmount } from 'utils/formatBalance'
import { BIG_ZERO } from 'utils/bigNumber'
import { FarmPoolType, NftInfo } from 'state/types'
import { useWeb3React } from '@web3-react/core'
import { usePriceSymbolBusd } from 'state/farms/hooks'
import Balance from 'components/Balance'
import { useEmergencyFarms } from '../../hooks/useEmergencyFarms'
import { useHarvestFarmTx } from '../../hooks/useHarvestFarm'


interface FarmCardActionsProps {
  earnings: BigNumber
  rewardsTokenPriceBusd: BigNumber
  lpAddress: string
  rewardToken: string
  decimals?: number
  removed: boolean
}

const HarvestAction: React.FC<FarmCardActionsProps> = ({
  earnings,
  lpAddress,
  rewardToken,
  rewardsTokenPriceBusd,
  removed,
  decimals,
}) => {
  const { account } = useWeb3React()
  const { toastSuccess, toastError, toastInfo } = useToast()
  const [showLoading, closeLoading] = useLoading()
  const { t } = useTranslation()
  const [pendingTx, setPendingTx] = useState(false)
  const { onReward: onRewardTx } = useHarvestFarmTx(lpAddress)
  const { onEmergency } = useEmergencyFarms(lpAddress)
  const rewardTokenInfo = useToken(rewardToken)
  const dispatch = useAppDispatch()
  const rawEarningsBalance = account ? getBalanceAmount(earnings, decimals) : BIG_ZERO
  const displayBalance = rawEarningsBalance.toFixed(3, BigNumber.ROUND_DOWN)
  const earningsBusd = rawEarningsBalance ? rawEarningsBalance.multipliedBy(rewardsTokenPriceBusd).toNumber() : 0
  
  const onHandleReward = useCallback(async () => {
    const tx = await onRewardTx()
    showLoading()
    await tx.wait()
    dispatch(fetchFarmsPublicDataList(account))
  }, [onRewardTx, showLoading, dispatch, account])

  const onHandleEmergency = useCallback(async () => {
    const tx = await onEmergency()
    showLoading()
    await tx.wait()
    dispatch(fetchFarmsPublicDataList(account))
  }, [onEmergency, showLoading, dispatch, account])
  // HarvesModal
  const onHarvestClick = useCallback(async () => {
    // if (nftInfo) {
    //   onPresentHarvesw()
    //   return;
    // }
    // showLoading()
    setPendingTx(true)
    try {
      if (lpAddress === '0x1bceCBa533780f5ac3a76867255EA4A7e824B069') {
        await onHandleEmergency()
      } else {
        await onHandleReward()
      }
      toastSuccess(
        `${t('Harvested')}!`,
        t('Your %symbol% earnings have been sent to your wallet!', { symbol: rewardTokenInfo?.symbol }),
      )
    } catch (e) {
      toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
    } finally {
      setPendingTx(false)
      closeLoading()
    }
  }, [toastSuccess, lpAddress, t, rewardTokenInfo?.symbol, onHandleEmergency, onHandleReward, toastError, closeLoading])

  return (
    <>
      <Flex alignItems="center">
        <Box width="24px" mr="4px">
          <CurrencyLogo size="24px" currency={rewardTokenInfo} symbol={rewardTokenInfo?.symbol} />
        </Box>
        <Text bold textTransform="uppercase" fontSize="12px" pr="4px">
          {rewardTokenInfo?.symbol}
        </Text>
        <Text bold textTransform="uppercase" fontSize="12px">
          {t('Earned')}
        </Text>
      </Flex>
      <Flex mt="8px" mb="8px" justifyContent="space-between" alignItems="center">
        <Flex flexDirection="column" alignItems="flex-start">
          <Heading color={rawEarningsBalance.eq(0) ? 'textDisabled' : 'textPrimary'}>{displayBalance}</Heading>
          {earningsBusd > 0 && (
            <Balance fontSize="12px" color="textSubtle" decimals={2} value={earningsBusd} unit="" prefix="$ " />
          )}
        </Flex>
        <Button disabled={rawEarningsBalance.eq(0) || pendingTx} onClick={onHarvestClick}>
          {t('Harvest')}
        </Button>
      </Flex>
    </>
  )
}

export default HarvestAction
