// import tokens from './tokens'
import { ChainId, ETHER_TOKEN, SWAP_TOKEN, USDC, USDT, tokens  } from 'dsgswap-sdk'
import { getToken } from 'utils/addressHelpers'

export const getDurationTime = (t) => [
  {
    label: `10 ${t('m')}`,
    value: 10 * 60,
  },
  {
    label: `30 ${t('m')}`,
    value: 30 * 60,
  },
  {
    label: `1 ${t('h')}`,
    value: 60 * 60,
  },
  {
    label: `6 ${t('h')}`,
    value: 6 * 60 * 60,
  },
  {
    label: `12 ${t('h')}`,
    value: 12 * 60 * 60,
  },
  {
    label: `1 ${t('d')}`,
    value: 24 * 60 * 60,
  },
]

const usdc = getToken(USDC)
const swapToken = getToken(SWAP_TOKEN)
export const nftSupportSymbols = [
  {
    label: usdc.symbol,
    value: usdc,
    id: usdc.symbol,
  },
  {
    label: swapToken.symbol,
    value: swapToken,
    id: swapToken.symbol,
  },
]
