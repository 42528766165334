import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { useWeb3React } from '@web3-react/core'
import { DEFAULT_TOKEN_DECIMAL } from 'config'
import BigNumber from 'bignumber.js'
import { useSinglePollData } from 'state/pools/hooks'
import { BIG_ZERO } from 'utils/bigNumber'
import { getAddress, getToken } from 'utils/addressHelpers'
import { SWAP_TOKEN, WETHER } from 'dsgswap-sdk'
import { getBalanceAmount } from 'utils/formatBalance'
import { isBNBBUSDLp, hasDSGLp, hasSymbolFarm } from 'utils/getLiquidityPool'
import useRefresh from 'hooks/useRefresh'
import {
  fetchFarmsPublicDataList,
  fetchFarmsPublicDataAsync,
  fetchEarningVaultUserAsync,
  fetchErc20FarmsPublicDataList,
  fetchNftsFarmsPublicDataList,
  fetchErc20VaultUserAsync,
  fetchFfFarmsPublicDataListAsync,
  fetchTfFarmsPublicDataListAsync,
  fetchAdditionalRatesAsync,
  fetchIncentiveFarmragmentListAsync,
  fetchPublicDataAsyncWidthType
} from '.'
import { State, Farm, FarmsState, FarmPoolType } from '../types'


export const usePollFarmsData = (farmPoolType: FarmPoolType) => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const { account } = useWeb3React()

  useEffect(() => {
    // switch (farmPoolType) {
    //   case FarmPoolType.DSG_FARM:
    //     dispatch(fetchFarmsPublicDataList(account))
    //     break
    //   case FarmPoolType.LP_FARM:
    //     dispatch(fetchErc20FarmsPublicDataList(account))
    //     break
    //   case FarmPoolType.NFT_FARM:
    //     dispatch(fetchNftsFarmsPublicDataList(account))
    //     break
    //   case FarmPoolType.FTS_FARM:
    //     dispatch(fetchFfFarmsPublicDataListAsync(account))
    //     dispatch(fetchTfFarmsPublicDataListAsync(account))
    //     break
    //   default:
    //     console.error(`usePollFarmsData: farmPoolType:${farmPoolType} is not a FarmPoolType type`)
    //     break
    // }
    // getLpAprs
    dispatch(fetchPublicDataAsyncWidthType(account, farmPoolType))
    // dispatch(fetchErc20FarmsPublicDataList(account))
    // dispatch(fetchNftsFarmsPublicDataList(account))
    // dispatch(fetchFarmsPublicDataAsync(pids))

    // if (account) {
    //   dispatch(fetchVaultUserAsync(account))
    // }
  }, [farmPoolType, dispatch, slowRefresh, account])
}

export const usePollPublicFarmsData = () => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    dispatch(fetchPublicDataAsyncWidthType(undefined, FarmPoolType.DSG_AND_INCENTTIVE_FARM))
    dispatch(fetchPublicDataAsyncWidthType(undefined, FarmPoolType.V1_FARM))
    dispatch(fetchPublicDataAsyncWidthType(undefined, FarmPoolType.FTS_FARM))
  }, [dispatch, slowRefresh])
}

export const useFetchNftFarmsData = (includeArchive = false) => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const { account } = useWeb3React()

  useEffect(() => {
    dispatch(fetchNftsFarmsPublicDataList(account))
  }, [includeArchive, dispatch, slowRefresh, account])
}

export const usePollEarningFarmsData = () => {
  const dispatch = useAppDispatch()
  const { fastRefresh } = useRefresh()
  const { account } = useWeb3React()
  useEffect(() => {
    if (account) {
      dispatch(fetchEarningVaultUserAsync(account))
    }
  }, [dispatch, fastRefresh, account])
}

export const useErc20EarningNftsData = () => {
  const dispatch = useAppDispatch()
  const { fastRefresh } = useRefresh()
  const { account } = useWeb3React()
  useEffect(() => {
    if (account) {
      dispatch(fetchErc20VaultUserAsync(account))
    }
  }, [dispatch, fastRefresh, account])
}

/**
 * Fetches the "core" farm data used globally
 * 251 = CAKE-BNB LP
 * 252 = BUSD-BNB LP
 */
export const usePollCoreFarmData = () => {
  const dispatch = useAppDispatch()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync())
    dispatch(fetchIncentiveFarmragmentListAsync())
  }, [dispatch, fastRefresh])
}
export const usePollFragmentFarmData = (farmPoolType: FarmPoolType) => {
  const dispatch = useAppDispatch()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    if (farmPoolType === FarmPoolType.FTS_FARM) {
      dispatch(fetchIncentiveFarmragmentListAsync())
    }
  }, [dispatch, farmPoolType, fastRefresh])
}

export const useFetchAdditionalRates = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchAdditionalRatesAsync())
  }, [dispatch])
}

export const useFarms = (): FarmsState => {
  const farms = useSelector((state: State) => state.farms)
  
  return useMemo(() => {
    return farms
  }, [farms])
}
export const useFarmsPricePools = (): Farm[] => {
  const farms = useFarms()
  return farms.data.concat(farms.v1Farm.data)
}
export const useFarmsPrices = (): {
  [address: string]: string
} => {
  const farms = useFarms()
  return farms.tokenPrices
}

export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return farm
}

export const useFarmFromLpSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return farm
}

export const useFarmUser = (pid) => {
  const farm = useFarmFromPid(pid)

  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : BIG_ZERO,
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : BIG_ZERO,
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : BIG_ZERO,
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : BIG_ZERO,
  }
}

// Return the base token price for a farm, from a given pid
export const useBusdPriceFromPid = (pid: number): BigNumber => {
  const farm = useFarmFromPid(pid)
  return farm && new BigNumber(farm.token.busdPrice)
}

export const useLpTokenPrice = (symbol: string) => {
  const farm = useFarmFromLpSymbol(symbol)
  const farmTokenPriceInUsd = BIG_ZERO
  let lpTokenPrice = BIG_ZERO

  if (farm?.lpTotalSupply && farm?.lpTotalInQuoteToken) {
    // Total value of base token in LP
    const valueOfBaseTokenInFarm = farmTokenPriceInUsd.times(farm.tokenAmountTotal)
    // Double it to get overall value in LP
    const overallValueOfAllTokensInFarm = valueOfBaseTokenInFarm.times(2)
    // Divide total value of all tokens, by the number of LP tokens
    const totalLpTokens = getBalanceAmount(new BigNumber(farm.lpTotalSupply))
    lpTokenPrice = overallValueOfAllTokensInFarm.div(totalLpTokens)
  }

  return lpTokenPrice
}

// /!\ Deprecated , use the BUSD hook in /hooks

export const usePriceBnbBusd = (): BigNumber => {
  const prices = useFarmsPrices()
  return useMemo(() => {
    const wbnbAddress = getToken(WETHER).address
    return new BigNumber(prices[wbnbAddress] || 0)
  }, [prices])
//   const bnbBusdFarm = farms.data.find((farm) => isBNBBUSDLp(farm))
//   if (!bnbBusdFarm) return new BigNumber(0)
//   const bnbs = ['BNB', 'WBNB']
//   if (bnbs.includes(bnbBusdFarm.token.symbol.toUpperCase())) {
//     return new BigNumber(bnbBusdFarm.token.busdPrice)
//   }
//   return new BigNumber(bnbBusdFarm.quoteToken.busdPrice)
// }
// export const usePriceBnbBusd = (): BigNumber => {
//   const farms = useFarms()
//   const bnbBusdFarm = farms.data.find((farm) => isBNBBUSDLp(farm))
//   if (!bnbBusdFarm) return new BigNumber(0)
//   const bnbs = ['BNB', 'WBNB']
//   if (bnbs.includes(bnbBusdFarm.token.symbol.toUpperCase())) {
//     return new BigNumber(bnbBusdFarm.token.busdPrice)
//   }
//   return new BigNumber(bnbBusdFarm.quoteToken.busdPrice)
}

export const usePriceDsgBusd = (): BigNumber => {
  const prices = useFarmsPrices()
  return useMemo(() => {
    const dsgAddress = getToken(SWAP_TOKEN).address
    return new BigNumber(prices[dsgAddress] || 0)
  }, [prices])
  // const farms = useFarms()
  // const data = useFarmsPricePools()
  // const DSGFarm = data.find((farm) => hasDSGLp(farm))
  // if (!DSGFarm) return new BigNumber(0)
  // if (DSGFarm.token.symbol === 'MBT') return new BigNumber(DSGFarm.token.busdPrice)
  // if (DSGFarm.quoteToken.symbol === 'MBT') return new BigNumber(DSGFarm.quoteToken.busdPrice)
  // return new BigNumber(0)
}
export const usePriceByAddress = (address: string): BigNumber => {
  const prices = useFarmsPrices()
  return useMemo(() => {
    if (!address) return new BigNumber(0)
    return new BigNumber(prices[address] || 0)
  }, [address, prices])

  // const farms = useFarms()
  // const data = useFarmsPricePools()
  // const DSGFarm = data.find((farm) => hasDSGLp(farm))
  // if (!DSGFarm) return new BigNumber(0)
  // if (DSGFarm.token.symbol === 'MBT') return new BigNumber(DSGFarm.token.busdPrice)
  // if (DSGFarm.quoteToken.symbol === 'MBT') return new BigNumber(DSGFarm.quoteToken.busdPrice)
  // return new BigNumber(0)
}

export const usePriceSymbolBusd = (symbol = ''): BigNumber => {
  const querySymbol = symbol.toUpperCase()
  const farms = useFarms()
  const data = useFarmsPricePools()

  const bnbs = ['BNB', 'WBNB']
  if (bnbs.includes(querySymbol)) {
    const bnbBusdFarm = data.find((farm) => isBNBBUSDLp(farm))
    if (!bnbBusdFarm) return new BigNumber(0)
    if (bnbs.includes(bnbBusdFarm.token.symbol.toUpperCase())) {
      return new BigNumber(bnbBusdFarm.token.busdPrice)
    }
  }
  // eslint-disable-next-line no-debugger
  // debugger
  const symbolFarm = data.concat(farms.incentiveFarm.data).find((farm) => {
    return hasSymbolFarm(symbol, farm)
  })
  if (!symbolFarm) return new BigNumber(0)
  if (symbolFarm.token.symbol.toUpperCase() === querySymbol) return new BigNumber(symbolFarm.token.busdPrice)
  if (symbolFarm.quoteToken.symbol.toUpperCase() === querySymbol) return new BigNumber(symbolFarm.quoteToken.busdPrice)
  return new BigNumber(0)
}


export const useLevelRates = () => {
  const { levelRates } = useFarms() 
  return levelRates
}

export const useTotalLiquiditAmount = () => {
  let totalLiquidityAmount = new BigNumber(0)
  const { data: singleData } = useSinglePollData()
  const { data, tfFarm: { data: tfData }, ffFarm: { data: ffData }, incentiveFarm: { data: incentiveData }, v1Farm: { data: v1Data} } = useFarms()
  data.concat(tfData).concat(v1Data).concat(ffData).concat(incentiveData).forEach((farm) => {
    const totalLiquidity = new BigNumber(farm.lpTotalInQuoteToken).times(farm.quoteToken.busdPrice)
    totalLiquidityAmount = totalLiquidityAmount.plus(totalLiquidity.isFinite() ? totalLiquidity : 0)
  })
  singleData.forEach((farm) => {
    const depositTokenPriceBusd = getBalanceAmount(new BigNumber(farm.depositTokenPrice), Number(farm.depositDecimals))
    const totalLiquidity = depositTokenPriceBusd.times(farm.totalAmount).div(DEFAULT_TOKEN_DECIMAL)
    totalLiquidityAmount = totalLiquidityAmount.plus(totalLiquidity.isFinite() ? totalLiquidity : 0)
  })
  return totalLiquidityAmount
}
