import { getLiquidityPoolAddress, getSinglePoolFactoryAddress,
  getTradingPoolAddress,
  getFFragmentPoolAddress,
  getIncentivePoolAddress,
  getTFragmentPoolAddress,
  getNftEarnErc20PoolAddress,
  getVDSGTokenAddress,
} from "utils/addressHelpers";
import liquidityPoolABI from 'config/abi/liquidityPool.json'
import NftEarnErc20PoolAbi from 'config/abi/NftEarnErc20Pool.json'
import tradingPoolABI from 'config/abi/tradingPool.json'
import singlePoolFactoryABI from 'config/abi/singlePoolFactory.json'
import vDSGTokenABI from 'config/abi/vDSGToken.json'
import multicall from 'utils/multicall'
import BigNumber from "bignumber.js";
import { DEFAULT_TOKEN_DECIMAL } from "config";

const getTotalAmount = (data) => {
  let res = new BigNumber(0)
  data.forEach(element => {
    res = res.plus(element.rewardsPerBlock.toJSON().hex)
  });
  return res.div(DEFAULT_TOKEN_DECIMAL).toString()
}

const main = async () => {
  console.debug('start')
  const liquidityPoolAddress = getLiquidityPoolAddress()
  const calls1 = [
    {
      address: liquidityPoolAddress,
      name: 'getAllPoolViews',
    },
    {
      address: getFFragmentPoolAddress(),
      name: 'getAllPoolViews',
    },
    {
      address: getIncentivePoolAddress(),
      name: 'getAllPoolViews',
    },
    {
      address: getTFragmentPoolAddress(),
      name: 'getAllPoolViews',
    },
  ]


  const calls2 = [
    {
      address: getTradingPoolAddress(),
      name: 'getAllPoolViews',
    },
  ]


  const calls3 = [
    {
      address: getNftEarnErc20PoolAddress(),
      name: 'getPoolView',
    },
  ]

  const calls4 = [
    {
      address: getSinglePoolFactoryAddress(),
      name: 'getAllPoolViews',
    },
  ]

  const calls5 = [
    {
      address: getVDSGTokenAddress(),
      name: '_mbtPerBlock',
    },
  ]
  const [SinglePoolFactory] = await multicall(singlePoolFactoryABI, calls4)
  const [liquidityPool, FFragmentPool, IncentivePool, TFragmentPool] = await multicall(liquidityPoolABI, calls1)
  const [NftEarnErc20Pool] = await multicall(NftEarnErc20PoolAbi, calls3)
  const [TradingPool] = await multicall(tradingPoolABI, calls2)
  const [VDSGToken] = await multicall(vDSGTokenABI, calls5)

  console.debug('ok')
  // console.debug(VDSGToken)

  console.debug('liquidityPool: ', getTotalAmount(liquidityPool[0]))
  console.debug('FFragmentPool: ', getTotalAmount(FFragmentPool[0]))
  console.debug('IncentivePool: ', getTotalAmount(IncentivePool[0]))
  console.debug('TFragmentPool: ', getTotalAmount(TFragmentPool[0]))
  console.debug('TradingPool: ', getTotalAmount(TradingPool[0]))
  console.debug('NftEarnErc20Pool: ', getTotalAmount(NftEarnErc20Pool))
  console.debug('SinglePoolFactory: ', getTotalAmount(SinglePoolFactory[0]))
  console.debug('VDSGToken: ', new BigNumber(VDSGToken[0].toJSON().hex).div(DEFAULT_TOKEN_DECIMAL).toString())

}

export default main
