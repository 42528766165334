import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 1024 1024" {...props}>
      <path d="M938.24007585 473.08982045h-389.55326577v-389.55326578c0-26.23988622-21.266432-47.50510459-47.5051046-47.50510459s-47.50510459 21.266432-47.50510459 47.50510459v389.55326578h-370.55025304c-26.23988622 0-47.50510459 21.266432-47.5051046 47.50267733s21.266432 47.50267733 47.5051046 47.50267733h370.55025304v370.55025304c0 26.23988622 21.26764563 47.50267733 47.50510459 47.50267733 26.23988622 0 47.50510459-21.266432 47.5051046-47.50510459v-370.54903941h389.55326577c26.23988622 0 47.50510459-21.26279111 47.5051046-47.50267733s-21.266432-47.50510459-47.5051046-47.5051046z" fill="#067d73" p-id="2150"></path>
    </Svg>
  );
};

export default Icon;
