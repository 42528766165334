import BigNumber from 'bignumber.js'
import uniq from 'lodash/uniq'
import oracleABI from 'config/abi/oracle.json'
import Erc20EarnNftPoolAbi from 'config/abi/Erc20EarnNftPool.json'
import NftEarnErc20PoolAbi from 'config/abi/NftEarnErc20Pool.json'
import dsgnftAbi from 'config/abi/dsgnft.json'
import masterchefABI from 'config/abi/masterchef.json'
import liquidityPoolABI from 'config/abi/liquidityPool.json'
import multicall from 'utils/multicall'
import {
  getAddress,
  getLiquidityPoolAddress,
  getErc20EarnNftPoolAddress,
  getNftEarnErc20PoolAddress,
  getDsgAddress,
  getOracleAddress,
} from 'utils/addressHelpers'
import { SinglePoolView, NftFarm } from '../types'

export const fetchFarmsPrices = async (farmsToFetch: SinglePoolView[]) => {
  const depositTokens = farmsToFetch.map((farm) => farm.depositToken)
  const rewardsTokens = farmsToFetch.map((farm) => farm.rewardsToken)
  const tokensUniq = uniq(depositTokens.concat(rewardsTokens))
  try {
    const oracleAddress = getOracleAddress()
    const calls0 = tokensUniq.map((address) => {
      return { address: oracleAddress, name: 'getCurrentPrice', params: [address] }
    })
    // const calls1 = farmsToFetch.map((farm) => {
    //   return { address: oracleAddress, name: 'getCurrentPrice', params: [farm.rewardsSymbol] }
    // })

    const currentPrice0 = await multicall(oracleABI, calls0)
    // const currentPrice1 = await multicall(erc20ABI, calls1)
    // const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    //   return lpBalance[0].toJSON()
    // })
    // return parsedLpAllowances
    // depositTokenPrice: string,
    // rewardsTokenPrice: string,
    return farmsToFetch.map(pool => {
      const depositTokenPrice = currentPrice0[tokensUniq.indexOf(pool.depositToken)]?.price.toJSON().hex
      const rewardsTokenPrice = currentPrice0[tokensUniq.indexOf(pool.rewardsToken)]?.price.toJSON().hex
      return {
        ...pool,
        depositTokenPrice,
        rewardsTokenPrice,
      }
    })
  } catch (error) {
    console.error(error)
    return farmsToFetch
  }
}


