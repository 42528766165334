import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import { getLiquidityPoolAddress } from 'utils/addressHelpers'
import { fetchFarmDataList } from './fetchFarmDataList'

import { fetchFarmsPrices } from './fetchFarmsPrices'
import {
  fetchPoolsAllowance,
  fetchFarmUserViews,
} from './fetchFarmUser'

import {
  Farm,
  AppThunk,
  SinglePoolView,
  SinglePoolState
} from '../types'

const initialState: SinglePoolState = {
  data: [],
  userDataLoaded: false,
  farmsDataLoaded: false,
}

export const fetchFarmsPublicDataList =
  (account?: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const farmData = state.farms.data
    const data = await fetchFarmDataList(farmData)
    dispatch(setFarmsPublicDataList({ data }))
    dispatch(fetchFarmsPublicDatasAsync(data, account))
  }

  
export const fetchFarmsPublicDatasAsync =
(data?: SinglePoolView[], account?: string): AppThunk =>
async (dispatch, getState) => {
  const state = getState()
  const queryData = data || state.pools.data
  const farmsWithPrices = await fetchFarmsPrices(queryData)
  dispatch(setFarmsPublicDataList({ data: farmsWithPrices }))
  if (account) {
    dispatch(fetchVaultUserAsync(account, farmsWithPrices))
  }
}

export const fetchVaultUserAsync =
  (account: string, data?: SinglePoolView[]): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const queryData = data || state.pools.data
    const userPoolsAllowance = await fetchPoolsAllowance(account, queryData)
    const poolUserViews = await fetchFarmUserViews(account)
    const users = poolUserViews.map((farmAllowance, index) => {
      return {
        ...farmAllowance,
        allowance: userPoolsAllowance[index]?.hex,
      }
    })
    dispatch(setPoolUserData({ data: users }))
  }


/*  createSlice */
export const farmsSlice = createSlice({
  name: 'Pools',
  initialState,
  reducers: {
    setPoolUserData: (state, action) => {
      const { data } = action.payload
      if (state.data.length !== data.length) return
      state.data = data.map((user, index) => {
        // const oldFarmData = state.data.find((farmData) => farmData.poolAddress === user.poolAddress)
        return {
          ...state.data[index],
          userData: { ...user },
        }
      })
      state.userDataLoaded = true
    },
    setFarmsPublicDataList: (state, action) => {
      const { data } = action.payload
      state.data = data.map((item) => {
        const oldFarmData = state.data.find((farmData) => farmData.poolAddress === item.poolAddress)
        return {
          ...oldFarmData,
          ...item,
        }
      })
      state.farmsDataLoaded = true
    },

  },

})

// Actions
export const {
  setPoolUserData,
  setFarmsPublicDataList,
} = farmsSlice.actions

export default farmsSlice.reducer
