import { useCallback } from 'react'
import {
  depositPool
} from 'utils/calls'
import {
  useSinglePoolForAddress,
} from 'hooks/useContract'

export const useStakeFarms = (lpAddress: string) => {
  const masterChefContract = useSinglePoolForAddress(lpAddress)

  const handleStake = useCallback(
    async (amount: string, decimals: number) => {
      await depositPool(masterChefContract, amount, decimals)
    },
    [masterChefContract],
  )

  return { onStake: handleStake }
}
