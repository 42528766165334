export type DividerFill = {
  light: string
  dark?: string
}

export type ClipFill = {
  light: string
  dark?: string
}

export const sectionBackground = {
  BGIMAGE1: 'bgimage1',
  BGIMAGE2: 'bgimage2',
  BGIMAGE3: 'bgimage3',
  BGIMAGE4: 'bgimage4',
  BGIMAGE5: 'bgimage5',
  BGIMAGE8: 'bgimage8',
} as const

export type SectionBackground = typeof sectionBackground[keyof typeof sectionBackground]
