export enum ChainId {
  MAINNET = 56,
  TESTNET = 222,
  MATIC_MAINNET = 137,
  MATIC_TESTNET = 80001,
  // OKT = 66,
  // OKT = 65,
}
export interface AddressForChainId {
  [ChainId.MAINNET]?: string
  [ChainId.TESTNET]?: string
  [ChainId.MATIC_MAINNET]?: string
  [ChainId.MATIC_TESTNET]?: string
}