import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 1024 1024" {...props}>
      <path d="M964.57705245 518.11669333L778.62957511 350.05326222a38.62254933 38.62254933 0 0 0-52.07927466 0 30.408704 30.408704 0 0 0 0 47.06941156L849.29194667 508.03871289H492.19356445c-22.25311289 0-37.16619378 13.45672533-37.16619378 33.61268622 0 20.21421511 14.91308089 33.61268622 37.224448 33.61268622H849.29194667L726.55030045 686.23837867a30.408704 30.408704 0 0 0 0 47.06941155 35.47682133 35.47682133 0 0 0 26.03963733 10.07798045 35.53507555 35.53507555 0 0 0 26.03963733-10.07798045l185.94747734-171.442176s0-3.37874489 3.72827022-3.37874489a25.92312889 25.92312889 0 0 0-3.72827022-40.370176z" p-id="2197"></path><path d="M45.96622222 870.612992C45.96622222 931.48865422 95.19104 978.03377778 159.56195555 978.03377778h644.00042667c64.37091555 0 113.59573333-46.54512355 113.59573333-107.42078578v-71.65269333c0-21.43755378-15.14609778-35.76809245-37.86524444-35.76809245s-37.86524445 14.33053867-37.86524444 35.82634667v71.65269333c0 21.43755378-15.14609778 35.76809245-37.86524445 35.76809245H159.56195555c-22.71914667 0-37.86524445-14.33053867-37.86524444-35.82634667V154.55209245c0-21.495808 15.14609778-35.82634667 37.86524444-35.82634667h643.94217245c22.71914667 0 37.86524445 14.33053867 37.86524445 35.82634667v71.5944391c0 21.495808 15.14609778 35.82634667 37.86524444 35.82634667s37.86524445-14.33053867 37.86524444-35.82634667v-71.65269333C917.15811555 93.73468445 867.93329778 47.13130667 803.56238222 47.13130667H159.56195555C95.19104 47.13130667 45.96622222 93.67643022 45.96622222 154.55209245" p-id="2198"></path>
    </Svg>
  );
};

export default Icon;