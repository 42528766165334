import { useCallback } from 'react'
import { withdrawPool } from 'utils/calls'
import {
  useSinglePoolForAddress,
} from 'hooks/useContract'

export const useUnstakeFarms = (lpAddress) => {
  const masterChefContract = useSinglePoolForAddress(lpAddress)

  const handleUnstake = useCallback(
    async (amount: string, decimals: number) => {
      await withdrawPool(masterChefContract, amount, decimals)
    },
    [masterChefContract],
  )

  return { onUnstake: handleUnstake }
}