import { ChainId, Token, TokenForChainId, WETHER, SWAP_TOKEN } from 'dsgswap-sdk'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getToken = (token: TokenForChainId): Token => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  return token[chainId] ? token[chainId] : token[ChainId.MAINNET]
}

export const getDsgAddress = () => {
  return getToken(SWAP_TOKEN).address
}
// export const getCakeAddress = () => {
//   return getAddress(tokens.cake.address)
// }
export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getWbnbAddress = () => {
  return getToken(WETHER).address
}
export const getCakeVaultAddress = () => {
  return getAddress(addresses.cakeVault)
}
export const getTradingPoolAddress = () => {
  return getAddress(addresses.tradingPool)
}
export const getAggregatorAddress = () => {
  return getAddress(addresses.aggregator)
}
export const getLiquidityPoolAddress = () => {
  return getAddress(addresses.liquidityPool)
}
export const getVDSGTokenAddress = () => {
  return getAddress(addresses.vDSGToken)
}
export const getNftEarnErc20PoolAddress = () => {
  return getAddress(addresses.NftEarnErc20Pool)
}
export const getErc20EarnNftPoolAddress = () => {
  return getAddress(addresses.Erc20EarnNftPool)
}
export const getNftMarketAddress = () => {
  return getAddress(addresses.nftMarket)
}
export const getFFragmentPoolAddress = () => {
  return getAddress(addresses.FFragmentPool)
}
export const getTFragmentPoolAddress = () => {
  return getAddress(addresses.TFragmentPool)
}
export const getIncentivePoolAddress = () => {
  return getAddress(addresses.LiquidityPoolOther)
}
export const getV1FarmPoolAddress = () => {
  return getAddress(addresses.liquidityV1Pool)
}
export const getMysteryBoxAddress = () => {
  return getAddress(addresses.MysteryBox)
}
export const getDsgFoodNftAddress = () => {
  return getAddress(addresses.DsgFoodNft)
}
export const getOracleAddress = () => {
  return getAddress(addresses.oracle)
}
export const getSinglePoolFactoryAddress = () => {
  return getAddress(addresses.SinglePoolFactory)
}
export const getAriDropAddress = () => {
  return getAddress(addresses.vDsgAirdrop)
}
