/* eslint-disable camelcase */
import { ThunkAction } from 'redux-thunk'
import { AnyAction } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import {
  Address,
  CampaignType,
  Token,
  FarmConfig,
  LotteryStatus,
  LotteryTicket,
  Nft,
  PoolConfig,
  Team,
} from 'config/constants/types'
import { Price, CurrencyAmount, TokenAmount } from 'dsgswap-sdk'

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, State, unknown, AnyAction>

export interface BigNumberToJson {
  type: 'BigNumber'
  hex: string
}

export type TranslatableText =
  | string
  | {
    key: string
    data?: {
      [key: string]: string | number
    }
  }

export type SerializedBigNumber = string

export type FarmUserData = {
  allowance: string
  tokenBalance: string
  stakedBalance: string
  earnings: string
}

export type DsgFarmUserData = {
  allowance: string
  allowanceDsg: string
  tokenBalance: string
  stakedBalance: string
  earnings: string
  additionalRate: string
  additionalNftId: string
}

export interface Farm extends FarmConfig {
  farmPoolType?: FarmPoolType
  tokenAmountMc?: SerializedBigNumber
  quoteTokenAmountMc?: SerializedBigNumber
  tokenAmountTotal?: SerializedBigNumber
  quoteTokenAmountTotal?: SerializedBigNumber
  lpTotalInQuoteToken?: SerializedBigNumber
  lpTotalSupply?: SerializedBigNumber
  lpTokenPrice?: string
  tokenPriceVsQuote?: SerializedBigNumber
  poolWeight?: SerializedBigNumber
  rewardsPerBlock?: SerializedBigNumber
  additionalNft?: string
  isApprovedPool?: boolean
  nftSlotFee?: string
  ratePerNftPower?: string
  maxNftAdditionalRate?: string
  rewardToken?: address
  nftsInfo?: NftInfo[]
  levelRate?: LevelFees
  lpRewardsApr?: number
  fourRealAmount?: string
  multiplierVal?: string
  totalLiquidity?: string
  userData?: DsgFarmUserData
}

export interface Pool extends PoolConfig {
  totalStaked?: BigNumber
  stakingLimit?: BigNumber
  startBlock?: number
  endBlock?: number
  apr?: number
  stakingTokenPrice?: number
  earningTokenPrice?: number
  isAutoVault?: boolean
  userData?: {
    allowance: BigNumber
    stakingTokenBalance: BigNumber
    stakedBalance: BigNumber
    pendingReward: BigNumber
  }
}

type uint256 = number
type uint8 = number
type address = string
export interface TradePool {
  pid: uint256
  pair: address
  allocPoint: uint256
  lastRewardBlock: uint256
  rewardsPerBlock: uint256
  accRewardPerShare: uint256
  allocRewardAmount: uint256
  accRewardAmount: uint256
  quantity: uint256
  accQuantity: uint256
  token0: address
  symbol0: string
  name0: string
  decimals0: uint8
  token1: address
  symbol1: string
  name1: string
  decimals1: uint8
  totalAmount: uint256
  userData?: {
    quantity: uint256
    accQuantity: uint256
    unclaimedRewards: uint256
    accRewardAmount: uint256
  }
}

export interface TradePoolUserData {
  isLoading: boolean
  pendingRewards: number
}
export interface Profile {
  userId: number
  points: number
  teamId: number
  nftAddress: string
  tokenId: number
  isActive: boolean
  username: string
  nft?: Nft
  team: Team
  hasRegistered: boolean
}

// Slices states

export interface NftSlotView {
  index: number // 插槽索引
  tokenIds: string[] // 插槽中质押的 nft id
}

export interface SlotsNftsInfo {
  index: number
  nftsInfo: NftInfo[]
}

export interface NftFarm {
  dsgToken: address // dsg token 地址
  rewardToken: address // 奖励token
  dsgDecimals: uint8 // dsg 小数位
  lastRewardBlock: uint256 // 最近一次奖励的区块
  rewardsPerBlock: uint256 // 每区块奖励数
  accRewardPerShare: uint256 // 每算力累积的奖励
  allocRewardAmount: uint256 // 总待领取的奖励数
  accRewardAmount: uint256 // 累计奖励数
  totalAmount: uint256 // 总质押的nft数量
  nft: address // nft地址
  nftSymbol: string // nft标识
  pid: number
  contractAddress: string // 当前合约地址
  slotAdditionRate: string // 插槽质押时的算力加成比率，要除以10000
  nftsInfo: NftInfo[] // 普通质押的nft id列表
  enableSlotFee: string // 开插槽的手续费
  slotsNftsInfo: SlotsNftsInfo[]
  poolInfo: {
    accShare_: string
    accDsgTokenPerShare_: string
    dsgTokenPerBlock_: string
  }
  userData: {
    share: string // 总算力
    nfts: string[] // 普通质押的nft id列表
    slotNum: string // 已开通的插槽数
    slots: NftSlotView[] // 对应的插槽信息
    accRewardAmount_: string // 总已领取的奖励
    isApproval: boolean // nft 是否授权
    rewardDebt: string // 已领取收益
    allowance: string
    tokenBalance: string
    stakedBalance: string
    earnings: string
  }
}

export interface Erc20FarmStakeView {
  amount: uint256
  isCompleted: boolean
  beginTime: uint256
  endTime: uint256
  sid: number
}
export interface Erc20FarmUserData extends FarmUserData {
  pid: number
  stakeView: Erc20FarmStakeView[]
}

interface FarmToken extends Token {
  token?: string
}
export interface Erc20Farm {
  pid: number
  tokenAddress: address // 要存入的erc20代币
  stakeAmount: string // 需要质押的数量
  stakeTime: string // 需要质押的时间
  nftAddress: address // 获得的nft
  nftTokenIds: string[] // 可挖的nft id
  nftLeft: string // 剩余未挖出的nft数量
  outputs: number // 预期产出
  isLpToken: boolean
  lpSymbol: string
  token: FarmToken
  quoteToken: FarmToken
  userData: Erc20FarmUserData
}

export enum FarmPoolType {
  DSG_FARM = 'dsg',
  DSG_AND_INCENTTIVE_FARM = 'dsgAndIncentive',
  FF_FARM = 'ff',
  TF_FARM = 'tf',
  FTS_FARM = 'fts',
  LP_FARM = 'lp',
  NFT_FARM = 'nft',
  INCENTIVE_FARM = 'incentive',
  V1_FARM = 'v1',
}

export interface FarmsState {
  tokenPrices: {
    [address: string]: string
  }
  data: Farm[]
  v1Farm: {
    data: Farm[]
    dataLoaded: boolean
  }
  ffFarm: {
    data: Farm[]
    dataLoaded: boolean
  }
  incentiveFarm: {
    data: Farm[]
    dataLoaded: boolean
  }
  tfFarm: {
    data: Farm[]
    dataLoaded: boolean
  }
  nftFarm: {
    data: NftFarm[]
    dataLoaded: boolean
    userLoaded: boolean
  }
  erc20Farm: {
    data: Erc20Farm[]
    dataLoaded: boolean
  }
  loadArchivedFarmsData: boolean
  userDataLoaded: boolean
  farmsDataLoaded: boolean
  userData: {
    earnings: SerializedBigNumber
    dataLoaded: boolean
  }
  levelRates: number[]
}

export interface UserMiningInfo {
  userAmount: SerializedBigNumber // 用户质押量、交易量
  userUnclaimedReward: SerializedBigNumber // 用户未领取收益
  userAccReward: SerializedBigNumber // 总收益
  poolType: SerializedBigNumber // 矿池类型 1.流动性挖矿 2.交易挖矿 3.单币挖矿
  pid: SerializedBigNumber // 矿池id
  pair: address // 交易对
  totalAmount: SerializedBigNumber // 矿池总质押量
  rewardsPerBlock: SerializedBigNumber // 每区块奖励数
  allocPoint: SerializedBigNumber // 矿池权重
  token0: address // 基础货币地址
  name0: string // 基础货币名
  symbol0: string // 基础货币标识
  decimals0: SerializedBigNumber // 基础货币小数位数
  token1: address // 报价货币地址
  name1: string // 报价货币名
  symbol1: string // 报价货币标识
  decimals1: SerializedBigNumber // 报价货币名
}

export interface TreasuryInfo {
  nftBonusRatio: SerializedBigNumber // nft奖励比例
  investorRatio: SerializedBigNumber // 投资人比例
  totalFee: SerializedBigNumber // 总手续费
  nftBonusAmount: SerializedBigNumber // nft奖励数量
  totalDistributedFee: SerializedBigNumber // 总分发的手续费
  totalBurnedDSG: SerializedBigNumber // 总燃烧的DSG
  totalRepurchasedUSDT: SerializedBigNumber // 总回购价值usdt
}
export interface AggregatorState {
  userMiningInfo: UserMiningInfo[]
  treasuryInfo: TreasuryInfo
  userDataLoaded: boolean
  circulationSupply: SerializedBigNumber
}
export interface MemberDataState {
  dsgRatio: number
  dsgFeeBurnRatio: SerializedBigNumber
  dsgTeam: address
  totalUsers?: string
  alpha?: string
  decimals?: string
  mintAmount?: string
  redeemAmount?: string
  feeAmount?: string
  burnAmount?: string
  mbtBalance?: string
  mbtPerBlock?: string
  totalDonate?: string
  totalStakingPower?: string
  donateAmount3?: string
  totalBlockReward?: string
  curDistribution?: string
  feeRatio?: string
  newAlpha?: string
  _superiorRatio?: string
  donateDt?: number
  feeAmount3?: string
  feeDt?: number
  _superiorMinSP?: string
}

export interface MemberUserRedeemAmount {
  mintAmount: string
  redeemRecieveAmount: string
  redeemFeeAmount: string
  credit: string
  stakingPower: string
}
export interface MemberUserDataState extends MemberUserRedeemAmount {
  allowance: SerializedBigNumber
  vDsgAmount: SerializedBigNumber
  dsgAmount: SerializedBigNumber
  superior: string
}
export interface MemberUserRanking {
  id: string
  spFromInvited: string
  creditFromInvited: string
  invitedMintDsg?: string
  credit?: string
  mintAmount?: string
  creditOfSuperior?: string
}
export interface MemberBalanceRanking {
  id: string
  mintAmount: string
  stakingPower: string
  redeemRecieveAmount: string
  vDsgEarning?: string
  vdsgBalance?: string
  mindAmountVDsg?: string
}
export interface MemberMintList {
  id: string
  user: {
    id: address
  }
  amount: string
  timestamp: number
}
export interface MemberRedeemList {
  id: string
  user: {
    id: address
    mintAmount: string
    stakingPower: string
    redeemRecieveAmount: string
  }
  burn: string
  recieve: string
  fee: string
  timestamp: number
  exitFeeRate?: string
  exitFee?: string
  profit?: string
  total?: string
}
export interface MemberState {
  data: MemberDataState
  userDataLoaded: boolean
  dataLoaded: boolean
  userData: MemberUserDataState
  userRanking: MemberUserRanking[]
  userInvite: MemberUserRanking[]
  // balanceRanking: MemberBalanceRanking[]
  // mintList: MemberMintList[]
  mintList: {
    dataLoaded: boolean
    data: MemberMintList[],
  }
  balanceRanking: {
    dataLoaded: boolean
    data: MemberBalanceRanking[],
  }
  redeemList: {
    dataLoaded: boolean
    data: MemberRedeemList[],
  }
}
export interface MemberDataInfo {
  stake: BigNumber;
  credit: BigNumber;
  creditPerMonth: BigNumber;
  baseApr: number;
  donateApr: number;
  feeApr: number;
  totalApr: number;
  feeRatioDisplay: string;
}

export enum NftOwnerState {
  NORMAL,
  SELLING,
  STAKING,
  WAIT_OPEN_BOX,
}

export interface NftProperties {
  id: string | number // 数据库中的记录id
  token: address // NFT合约地址
  nft: address // 盲盒开出的nft合约地址
  token_id: string // nft id
  owner: address // 所有者
  level: number // 等级
  power: number // 算力
  res: string // 所使用的资源
  owner_status: NftOwnerState
  author: address
  createdTime: number // 创建时间，秒
}

export interface LevelFees {
  [elme: number]: string
}

export interface NftInfo {
  properties: NftProperties
  name: string // 名称
  description: string // 描述
  image: string
  isApprovedMarket: boolean // 是否授权给市场
  key: string // `${item.properties.token}_${item.properties.token_id}_${item.properties.owner_status}`
  isAllowanceNft?: string
  upgradeFee?: string
  levelFees?: LevelFees
}

export enum NftMarketState {
  SELLING,
  FINISH,
  CANCELLED,
}

export interface NftMarketInfo {
  id: number // 商品id
  token_id: string // nft id
  seller: address // 出售者
  nft: address // nft 合约地址
  buyer: address // 购买者
  currency: address // 支持购买的货币 0代表的是bnb
  start_time: number // 开始销售时间
  duration_time: number // 销售时长
  max_price: string // 最高价
  min_price: string // 最低价
  final_price: string // 成交价
  final_price_usd?: number
  max_price_usd?: number
  final_hash?: string
  final_time?: number
  state: NftMarketState // 状态 0.销售中 1.已成交 2.已取消
  allowance: string
  currencySymbol: string
  currencyDecimals: number
  sumRoyalties?: string
  symbolAddress: string
  metadata: NftInfo
}

export interface NftState {
  market: {
    data: NftMarketInfo[]
    total: number
    limit: number
    loaded: boolean
    loadMoreLoading: boolean
    loadEnd: boolean
  }
  marketHistory: {
    data: NftMarketInfo[]
    total: number
    limit: number
    loadEnd: boolean
  }
  user: {
    data: NftInfo[]
    loaded: boolean
    allowanceLoaded: boolean
  }
}

export interface VaultFees {
  performanceFee: number
  callFee: number
  withdrawalFee: number
  withdrawalFeePeriod: number
}

export interface VaultUser {
  isLoading: boolean
  userShares: string
  cakeAtLastUserAction: string
  lastDepositedTime: string
  lastUserActionTime: string
}
export interface CakeVault {
  totalShares?: string
  pricePerFullShare?: string
  totalCakeInVault?: string
  estimatedCakeBountyReward?: string
  totalPendingCakeHarvest?: string
  fees?: VaultFees
  userData?: VaultUser
}

export interface PoolsState {
  data: Pool[]
  cakeVault: CakeVault
  userDataLoaded: boolean
}
export interface TradePoolsState {
  data: TradePool[]
  userDataLoaded: boolean
  poolDataLoaded: boolean
  totalAllocPoint: null | number
  totalQuantityAllocPoint: null | number
  userData: TradePoolUserData
}

export interface ProfileState {
  isInitialized: boolean
  isLoading: boolean
  hasRegistered: boolean
  data: Profile
}

export type TeamResponse = {
  0: string
  1: string
  2: string
  3: string
  4: boolean
}

export type TeamsById = {
  [key: string]: Team
}

export interface TeamsState {
  isInitialized: boolean
  isLoading: boolean
  data: TeamsById
}

export interface Achievement {
  id: string
  type: CampaignType
  address: string
  title: TranslatableText
  description?: TranslatableText
  badge: string
  points: number
}

export interface AchievementState {
  data: Achievement[]
}

// Block

export interface BlockState {
  currentBlock: number
  initialBlock: number
}

// Collectibles

export interface CollectiblesState {
  isInitialized: boolean
  isLoading: boolean
  data: {
    [key: string]: number[]
  }
}

// Predictions

export enum BetPosition {
  BULL = 'Bull',
  BEAR = 'Bear',
  HOUSE = 'House',
}

export enum PredictionStatus {
  INITIAL = 'initial',
  LIVE = 'live',
  PAUSED = 'paused',
  ERROR = 'error',
}

export interface Round {
  id: string
  epoch: number
  failed?: boolean
  startBlock: number
  startAt: number
  lockAt: number
  lockBlock: number
  lockPrice: number
  endBlock: number
  closePrice: number
  totalBets: number
  totalAmount: number
  bullBets: number
  bearBets: number
  bearAmount: number
  bullAmount: number
  position: BetPosition
  bets?: Bet[]
}

export interface Market {
  paused: boolean
  epoch: number
}

export interface Bet {
  id?: string
  hash?: string
  amount: number
  position: BetPosition
  claimed: boolean
  claimedHash: string
  user?: PredictionUser
  round: Round
}

export interface PredictionUser {
  id: string
  address: string
  block: number
  totalBets: number
  totalBNB: number
}

export interface HistoryData {
  [key: string]: Bet[]
}

export enum HistoryFilter {
  ALL = 'all',
  COLLECTED = 'collected',
  UNCOLLECTED = 'uncollected',
}

export interface LedgerData {
  [key: string]: {
    [key: string]: ReduxNodeLedger
  }
}

export interface RoundData {
  [key: string]: ReduxNodeRound
}

export interface ReduxNodeLedger {
  position: BetPosition
  amount: BigNumberToJson
  claimed: boolean
}

export interface NodeLedger {
  position: BetPosition
  amount: ethers.BigNumber
  claimed: boolean
}

export interface ReduxNodeRound {
  epoch: number
  startBlock: number
  lockBlock: number | null
  endBlock: number | null
  lockPrice: BigNumberToJson | null
  closePrice: BigNumberToJson | null
  totalAmount: BigNumberToJson
  bullAmount: BigNumberToJson
  bearAmount: BigNumberToJson
  rewardBaseCalAmount: BigNumberToJson
  rewardAmount: BigNumberToJson
  oracleCalled: boolean
}

export interface NodeRound {
  epoch: number
  startBlock: number
  lockBlock: number
  endBlock: number
  lockPrice: ethers.BigNumber
  closePrice: ethers.BigNumber
  totalAmount: ethers.BigNumber
  bullAmount: ethers.BigNumber
  bearAmount: ethers.BigNumber
  rewardBaseCalAmount: ethers.BigNumber
  rewardAmount: ethers.BigNumber
  oracleCalled: boolean
}

export interface PredictionsState {
  status: PredictionStatus
  isLoading: boolean
  isHistoryPaneOpen: boolean
  isChartPaneOpen: boolean
  isFetchingHistory: boolean
  historyFilter: HistoryFilter
  currentEpoch: number
  currentRoundStartBlockNumber: number
  intervalBlocks: number
  bufferBlocks: number
  minBetAmount: string
  rewardRate: number
  lastOraclePrice: string
  history: HistoryData
  rounds?: RoundData
  ledgers?: LedgerData
  claimableStatuses: {
    [key: string]: boolean
  }
}

// Voting

/* eslint-disable camelcase */
/**
 * @see https://hub.snapshot.page/graphql
 */
export interface VoteWhere {
  id?: string
  id_in?: string[]
  voter?: string
  voter_in?: string[]
  proposal?: string
  proposal_in?: string[]
}

export enum SnapshotCommand {
  PROPOSAL = 'proposal',
  VOTE = 'vote',
}

export enum ProposalType {
  ALL = 'all',
  CORE = 'core',
  COMMUNITY = 'community',
}

export enum ProposalState {
  ACTIVE = 'active',
  PENDING = 'pending',
  CLOSED = 'closed',
}

export interface Space {
  id: string
  name: string
}

export interface Proposal {
  author: string
  body: string
  choices: string[]
  end: number
  id: string
  snapshot: string
  space: Space
  start: number
  state: ProposalState
  title: string
}

export interface Vote {
  id: string
  voter: string
  created: number
  space: Space
  proposal: {
    choices: Proposal['choices']
  }
  choice: number
  metadata?: {
    votingPower: string
    verificationHash: string
  }
  _inValid?: boolean
}

export enum VotingStateLoadingStatus {
  INITIAL = 'initial',
  IDLE = 'idle',
  LOADING = 'loading',
  ERROR = 'error',
}

export interface VotingState {
  proposalLoadingStatus: VotingStateLoadingStatus
  proposals: {
    [key: string]: Proposal
  }
  voteLoadingStatus: VotingStateLoadingStatus
  votes: {
    [key: string]: Vote[]
  }
}

export interface LotteryRoundUserTickets {
  isLoading?: boolean
  tickets?: LotteryTicket[]
}

interface LotteryRoundGenerics {
  isLoading?: boolean
  lotteryId: string
  status: LotteryStatus
  startTime: string
  endTime: string
  treasuryFee: string
  firstTicketId: string
  lastTicketId: string
  finalNumber: number
}

export interface LotteryRound extends LotteryRoundGenerics {
  userTickets?: LotteryRoundUserTickets
  priceTicketInCake: BigNumber
  discountDivisor: BigNumber
  amountCollectedInCake: BigNumber
  cakePerBracket: string[]
  countWinnersPerBracket: string[]
  rewardsBreakdown: string[]
}

export interface LotteryResponse extends LotteryRoundGenerics {
  priceTicketInCake: SerializedBigNumber
  discountDivisor: SerializedBigNumber
  amountCollectedInCake: SerializedBigNumber
  cakePerBracket: SerializedBigNumber[]
  countWinnersPerBracket: SerializedBigNumber[]
  rewardsBreakdown: SerializedBigNumber[]
}

export interface LotteryState {
  currentLotteryId: string
  maxNumberTicketsPerBuyOrClaim: string
  isTransitioning: boolean
  currentRound: LotteryResponse & { userTickets?: LotteryRoundUserTickets }
  lotteriesData?: LotteryRoundGraphEntity[]
  userLotteryData?: LotteryUserGraphEntity
}

export interface LotteryRoundGraphEntity {
  id: string
  totalUsers: string
  totalTickets: string
  winningTickets: string
  status: LotteryStatus
  finalNumber: string
  startTime: string
  endTime: string
  ticketPrice: SerializedBigNumber
}

export interface LotteryUserGraphEntity {
  account: string
  totalCake: string
  totalTickets: string
  rounds: UserRound[]
}

export interface UserRound {
  claimed: boolean
  lotteryId: string
  status: LotteryStatus
  endTime: string
  totalTickets: string
  tickets?: LotteryTicket[]
}

export type UserTicketsResponse = [ethers.BigNumber[], number[], boolean[]]

// Global state

export interface swapDayData {
  id: string
  date: number
  totalLiquidityUSD: string
  totalVolumeUSD: string
}
export interface DsgTotalData {
  reserveUSD: string
  totalVolumeUSD: string
  loaded?: boolean
}

export interface swapDayDataState {
  data: swapDayData[]
  dsgTotalData?: DsgTotalData
}

export interface MysteryBoxFactory {
  id: number
  name: string // 名称
  nft: address // 销售的 nft
  limit: number // 总量
  minted: number // 已销售量
  author: address // 作者
  currency: address // 购买币种
  price: string // 价格
  created_time: number // 创建时间
  nftInfo?: NftInfo
  allowance?: string
}

export interface MysteryBoxView {
  id: uint256
  name: string
  nft: address
  limit: uint256
  author: address
  currency: address
  price: uint256
  createdTime: uint256
}

export interface MysteryBoxState {
  data: MysteryBoxFactory[]
  loaded: boolean
  userLoaded: boolean
}

export interface PolyData {
  fromToken: {
    symbol: string,
    name: string,
    address: string,
    decimals: number,
    logoURI: string,
  },
  toToken: {
    symbol: string,
    name: string,
    address: string,
    decimals: number,
    logoURI: string,
  },
  toTokenAmount: string,
  fromTokenAmount: string,
  protocols: [
    [
      [
        {
          name: string,
          part: number,
          fromTokenAddress: string,
          toTokenAddress: string,
        }
      ],
      [
        {
          name: string,
          part: number,
          fromTokenAddress: string,
          toTokenAddress: string,
        }
      ],
      [
        {
          name: string,
          part: number,
          fromTokenAddress: string,
          toTokenAddress: string,
        }
      ]
    ]
  ],
  price: Price,
  estimatedGas: number   // do not use gas limit from the quote method 
  isPolyMethed: boolean,
  currencyAmount: CurrencyAmount,
  toCurrencyAmount: CurrencyAmount,
  fromCurrencyTokenAmount?: TokenAmount
  fromCurrencyToken?: Token
}

export interface PolyDataIndex {
  lastQueryTimestamp?: number
  fromTokenAddress?: string
  toTokenAddress?: string
  amount?: string
  slippage?: number
  fromAddress?: string
  amountDecimal?: string
}
export interface PolyApproveData {
  data: string
  gasPrice: string
  to: string
  value: string
}

export interface PolyAllowance {
  [address: string]: string
}

export interface PolySwapState {
  polyData?: PolyData
  polyDataIndex?: PolyDataIndex
  polyApproveData?: PolyApproveData
  polyAllowance?: PolyAllowance
  polySpender?: string
}

export enum PolyDataIndexStatus {
  NOT_SWAP_DATA,
  NEED_QUERY,
  NEED_REFRESH,
  LOADED,
}

export interface SinglePoolView {
  poolFactoryAddress: address,
  poolAddress: address,
  lpSymbol: string,
  accRewardPerShare: string,
  allocPoint: string,
  allocRewardAmount: string,
  lastRewardBlock: string,
  rewardsPerBlock: string,
  totalAmount: string,
  startBlock: string,
  bonusEndBlock: string,
  depositToken: string,
  depositTokenPrice: string,
  rewardsTokenPrice: string,
  depositSymbol: string,
  depositName: string,
  depositDecimals: string,
  rewardsToken: string,
  rewardsSymbol: string,
  rewardsName: string,
  isRemoved?: boolean,
  isStakeOnce?: boolean,
  rewardsDecimals: string,
  userData: FarmUserData
}

export interface SinglePoolState {
  data: SinglePoolView[],
  userDataLoaded: boolean,
  farmsDataLoaded: boolean,
}

export interface AriDropView {
  myRewards: number,
  totalShare: number,
  accRewardPerShare: number,
  lastRewardBlock: number,
  currentEpochStartAt: number, // 当前周期开始时间
  minEpochPeriod: number, // 周期时长
}
export interface AriDropState {
  data: AriDropView,
}
export interface State {
  aggregator: AggregatorState
  achievements: AchievementState
  member: MemberState
  block: BlockState
  farms: FarmsState
  trading: FarmsState
  pools: SinglePoolState
  tradingPool: TradePoolsState
  predictions: PredictionsState
  profile: ProfileState
  teams: TeamsState
  collectibles: CollectiblesState
  voting: VotingState
  lottery: LotteryState
  nfts: NftState
  swapts: swapDayDataState
  mysteryBox: MysteryBoxState
  ariDrop: AriDropState
}
