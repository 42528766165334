import erc20ABI from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
import { getMysteryBoxAddress } from 'utils/addressHelpers'
import { MysteryBoxFactory } from '../types'

export const fetchMysteryBoxAllowances = async (account: string, factorys: MysteryBoxFactory[]) => {
  try {
    const mysteryBoxAddress = getMysteryBoxAddress()
    const calls = factorys.map((factory) => {
      return { address: factory.currency, name: 'allowance', params: [account, mysteryBoxAddress] }
    })

    const rawLpAllowances = await multicall(erc20ABI, calls)
    const allowances = rawLpAllowances.map((lpBalance) => {
      return lpBalance[0].toHexString()
    })
    return allowances
  } catch (err) {
    return []
  }
}
