import React, { useMemo } from "react";
import styled from "styled-components";
import { formatLocalisedCompactNumber } from 'utils/formatBalance';
import { useTranslation } from 'contexts/Localization'
import { Box, Flex } from "pancake-uikit/components/Box";
import { LogoIcon, LogovDsgIcon } from "../../../components/Svg";
import Text from "../../../components/Text/Text";
import Skeleton from "../../../components/Skeleton/Skeleton";
import BalanceText from "../../../components/Text/Balance";
import { scaleVariants, Scale } from "../types"

interface Props {
  cakePriceUsd?: number;
  cakeToken?: string;
  fontColor?: string;
  tokenLable?: string;
  isLoading?: boolean;
  dollar?: boolean;
  formatLocal?: number
  suffix?: string;
  logo?: string
  scale?: Scale
  isBalance?: boolean
}

const PriceLink = styled.div`
  display: flex;
  align-items: center;
  /* cursor: pointer; */
  svg {
    transition: transform 0.3s;
  }
  :hover {
    svg {
      transform: scale(1.2);
    }
  }
`;

const CakePrice: React.FC<Props> = ({
  cakePriceUsd,
  isLoading,
  cakeToken,
  tokenLable,
  fontColor,
  suffix,
  formatLocal,
  logo = 'MBT',
  dollar = true,
  scale = 'md',
  isBalance
}) => {
  const { t } = useTranslation()

  const dispalyPrice = useMemo(() => {
    if (formatLocal) return formatLocalisedCompactNumber(Number(cakePriceUsd?.toFixed(formatLocal)), formatLocal)
    return cakePriceUsd.toFixed(3)
  }, [formatLocal, cakePriceUsd])

  const show = typeof isLoading === 'boolean' ? !isLoading : cakePriceUsd;
  const IconElement = logo === 'MBT' ? LogoIcon : LogovDsgIcon
  return show ? (
    <PriceLink>
      <Box width={scaleVariants[scale].boxWidth}>
        <IconElement width={scaleVariants[scale].iconWidth} height={scaleVariants[scale].iconWidth} ml={scaleVariants[scale].marginL} mr="8px" />
      </Box>
      <Box ml={scaleVariants[scale].subMarginL}>
        <Text fontSize={scaleVariants[scale].subFontSize} color={fontColor || 'white_black'}>{cakeToken} {tokenLable ? tokenLable : t('Price')}</Text>
        <Flex>
          <Text fontSize={scaleVariants[scale].fontSize} color={fontColor || 'textPrimary'} bold>
            {dollar ? '$' : ''}
          </Text>
          <Text fontSize={scaleVariants[scale].fontSize} color={fontColor || 'textPrimary'} bold>
            {
              isBalance
              ?
              (<BalanceText
                  fontSize={scaleVariants[scale].fontSize}
                  color={fontColor || 'textPrimary'}
                  bold
                  value={Number(dispalyPrice)} />)
              :
              `${dispalyPrice}`
            }
            </Text>
          <Text ml="8px" fontSize={scaleVariants[scale].fontSize} color={fontColor || 'textPrimary'} bold>
            {suffix && ` ${suffix}`}
          </Text>
        </Flex>
      </Box>
    </PriceLink>
  ) : (
    <Skeleton width={scaleVariants[scale].skeletonW} height={scaleVariants[scale].skeletonH} />
  );
};

export default React.memo(CakePrice);
