import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 1024 1024" {...props}>
      <path d="M182.94953068 518.61826527a445.81726031 342.27306625 90 1 0 684.54613253 0 445.81726031 342.27306625 90 1 0-684.54613253 0Z" fill="#ffe87f" p-id="2983"></path>
      <path d="M521.40716422 27.48835774c-223.28818457 0-380.02268896 371.13326901-380.02268726 572.08093913C141.38447696 805.54650625 315.42995485 979.59198584 521.40716422 979.59198584s380.02268896-174.04547958 380.02268897-380.02268897c0-200.94767014-156.73450441-572.08093916-380.02268897-572.08093913z m0 896.54475418c-175.91693518 0-324.46381675-148.54687987-324.46381506-324.46381505 0-172.2909885 140.24229084-516.52206694 324.46381506-516.52206692s324.46381675 344.23107842 324.46381675 516.52206692c0 175.91693518-148.66384608 324.46381675-324.46381675 324.46381505z" fill="#f1d2b1" p-id="2984"></path>
      <path d="M356.48503699 304.5809251a50.06146827 50.06146827 0 1 0 100.12293817 0 50.06146827 50.06146827 0 1 0-100.12293817 0z" fill="#ffb80c" p-id="2985"></path>
      <path d="M274.02397418 586.00123458a82.46106281 82.46106281 0 1 0 164.92212727 0 82.46106281 82.46106281 0 1 0-164.92212727 0z" fill="#ffb80c" p-id="2986"></path>
      <path d="M529.47782258 698.52257325a123.28221421 123.28221421 0 1 0 246.56442842 0 123.28221421 123.28221421 0 1 0-246.56442842 0z" fill="#ffb80c" p-id="2987"></path>
    </Svg>
  );
};

export default Icon;
