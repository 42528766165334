import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import fetchTreasuryInfo from './fetchTreasuryInfo'
import fetchUserMiningInfos from './fetchUserMiningInfos'

import { AggregatorState, UserMiningInfo, TreasuryInfo } from '../types'

const initialState: AggregatorState = {
  userMiningInfo: [],
  treasuryInfo: {
    nftBonusRatio: '0', // nft奖励比例
    investorRatio: '0', // 投资人比例
    totalFee: '0', // 总手续费
    nftBonusAmount: '0', // nft奖励数量
    totalDistributedFee: '0', // 总分发的手续费
    totalBurnedDSG: '0', // 总燃烧的DSG
    totalRepurchasedUSDT: '0', // 总回购价值usdt
  },
  circulationSupply: '0',
  userDataLoaded: false,
}

// Async thunks
export const fetchTreasuryInfoAsync = createAsyncThunk<{ treasuryInfo: TreasuryInfo; circulationSupply: string }>(
  'farms/fetchTreasuryInfoAsync',
  async () => {
    const { treasuryInfo, supply } = await fetchTreasuryInfo()
    return {
      treasuryInfo,
      circulationSupply: supply,
    }
  },
)
// Async thunks
export const fetchUserMiningInfosAsync = createAsyncThunk<UserMiningInfo[], string>(
  'farms/fetchUserMiningInfosAsync',
  async (account) => {
    const userMiningInfos = await fetchUserMiningInfos(account)
    return userMiningInfos
  },
)

export const aggregatorSlice = createSlice({
  name: 'Aggregator',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Update farms with live data
    builder.addCase(fetchTreasuryInfoAsync.fulfilled, (state, action) => {
      const { circulationSupply, treasuryInfo } = action.payload

      state.circulationSupply = circulationSupply
      state.treasuryInfo = treasuryInfo
    })

    // Update farms with user data
    builder.addCase(fetchUserMiningInfosAsync.fulfilled, (state, action) => {
      state.userMiningInfo = action.payload
      state.userDataLoaded = true
    })
  },
})

export default aggregatorSlice.reducer
