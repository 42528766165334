// import React from "react";
// import Svg from "../Svg";
// import { SvgProps } from "../types";

// const Icon: React.FC<SvgProps> = (props) => {
//   return (
//     <Svg viewBox="0 0 24 24" {...props}>
//       <path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19Z" />
//       <path d="M11.25 7.72H6.25V9.22H11.25V7.72Z" />
//       <path d="M18 15.75H13V17.25H18V15.75Z" />
//       <path d="M18 13.25H13V14.75H18V13.25Z" />
//       <path d="M8 18H9.5V16H11.5V14.5H9.5V12.5H8V14.5H6V16H8V18Z" />
//       <path d="M14.09 10.95L15.5 9.54L16.91 10.95L17.97 9.89L16.56 8.47L17.97 7.06L16.91 6L15.5 7.41L14.09 6L13.03 7.06L14.44 8.47L13.03 9.89L14.09 10.95Z" />
//     </Svg>
//   );
// };

// export default Icon;

import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";
import Calculate from './assets/calculate.png'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 45 45" {...props}>
      <image width="100%" xlinkHref={Calculate}/>
      {/* <path d="M543.513369 316.093117a21.956036 21.956036 0 0 0-31.052108-31.052108l-190.224144 190.205694a28.487495 28.487495 0 0 0 0 40.295783l190.224144 190.205694a21.956036 21.956036 0 0 0 31.052108-31.052108l-154.983783-154.983784h326.203964a24.280793 24.280793 0 0 0 24.262342-24.723603c-0.239856-13.358126-11.716036-23.801081-25.074162-23.801081H388.474234z" fill="#067d73" p-id="4207"></path>
      <path d="M844.495568 317.347748a18.45045 18.45045 0 0 0-25.830631-6.715964 18.45045 18.45045 0 0 0-6.420757 24.723603 330.53982 330.53982 0 0 1 41.052252 160.279063c-0.129153 183.342126-150.684829 332.883027-333.953153 331.849802a332.108108 332.108108 0 0 1-330.170811-330.263063c-1.014775-181.44173 149.448649-333.381189 330.94573-333.953153A330.705874 330.705874 0 0 1 651.780613 190.03964a18.45045 18.45045 0 0 0 23.856432-8.745514 18.45045 18.45045 0 0 0-9.391279-25.221766c-65.11164-27.933982-139.835964-37.638919-217.89982-22.712504C319.820108 157.991207 190.03964 283.02991 161.607495 410.83618 107.824432 653.145946 292.45809 867.447928 526.188396 864.366703 728.792793 861.636036 892.300685 694.142847 890.215784 491.52a367.034811 367.034811 0 0 0-45.720216-174.172252z" fill="#067d73" p-id="4208"></path> */}
    </Svg>
  );
};

export default Icon;