import { getNftMarketContract } from 'utils/contractHelpers'

export const getSalesHistory = async (tokenId?: string) => {
  try {
    const contract = getNftMarketContract()
    const filterFrom = contract.filters.eveSales(null, tokenId)
    const eveSales = await contract.queryFilter(filterFrom)

    return eveSales
  } catch (error) {

    return []
  }
}
