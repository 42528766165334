import { getMysteryboxFactories } from 'config/axios'
import { MysteryBoxFactory } from 'state/types'

export const getMysteryBoxFactories = async (): Promise<MysteryBoxFactory[]> => {
  try {
    const data = (await getMysteryboxFactories()) || []
    return data
  } catch (error) {
    return []
  }
}
