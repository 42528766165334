import BigNumber from 'bignumber.js'
import { request, gql } from 'graphql-request'
import { GRAPH_API_MEMBER } from 'config/constants/endpoints'
import {
  MemberUserRanking,
  MemberUserRedeemAmount,
  MemberBalanceRanking,
  MemberRedeemList,
  MemberMintList
} from 'state/types'
import { EXCEPT_MEMBER_RANKING_ADDRESS } from 'config/constants'
import isZero from 'utils/isZero'

export const MAX_LOTTERIES_REQUEST_SIZE = 100

/* eslint-disable camelcase */
type LotteriesWhere = { id_in?: string[] }

interface VDSGInfo {
  decimals: string
  totalUsers: string
  mintAmount: string
  redeemAmount: string
  feeAmount: string
  burnAmount: string
  mbtBalance: string
  mbtPerBlock: string
  totalDonate: string
  totalStakingPower: string
  totalBlockReward: string
}

interface DonateAndRedeemData {
  donateAmount3: string
  feeAmount3: string
  donateDt: number
  feeDt: number
}

export const getAccountUserData = async (account, where: LotteriesWhere = {}): Promise<MemberUserRedeemAmount> => {
  try {
    const response = await request(
      GRAPH_API_MEMBER,
      gql`
        query getAccountUserData($id: ID!) {
          user(id: $id) {
            mintAmount
            redeemRecieveAmount
            creditFromInvited
            redeemFeeAmount
            credit
            stakingPower
          }
        }
      `,
      { id: `${account}`.toLowerCase() },
    )
    return response.user
  } catch (error) {
    console.error(error)
    return {
      mintAmount: '0',
      redeemRecieveAmount: '0',
      redeemFeeAmount: '0',
      credit: '0',
      stakingPower: '0',
    }
  }
}

export const getVDSGInfo = async (where: LotteriesWhere = {}): Promise<VDSGInfo> => {
  try {
    const response = await request(
      GRAPH_API_MEMBER,
      gql`
        query VDSGs($first: Int!) {
          vMBTs(first: $first, where: $where) {
            decimals
            totalUsers
            mintAmount
            redeemAmount
            feeAmount
            burnAmount
            mbtBalance
            mbtPerBlock
            totalDonate
            totalStakingPower
            totalBlockReward
          }
        }
      `,
      { first: 10, where },
    )
    return response.vMBTs[0]
  } catch (error) {
    console.error(error)
    return {
      decimals: '0',
      totalUsers: '0',
      mintAmount: '0',
      redeemAmount: '0',
      feeAmount: '0',
      burnAmount: '0',
      mbtBalance: '0',
      mbtPerBlock: '0',
      totalDonate: '0',
      totalStakingPower: '0',
      totalBlockReward: '0',
    }
  }
}

export const getVDSGHistory = async (first = 1000, where: LotteriesWhere = {}): Promise<DonateAndRedeemData> => {
  try {
    const HOURS_6 = 21_600
    const HOURS_1 = 3_600
    const now = Math.floor(new Date().getTime() / 1000) - HOURS_1
    const response = await request(
      GRAPH_API_MEMBER,
      gql`
        query getVDSGHistory($first: Int!,) {
          donateHistories(first: $first, where: { timestamp_gte: ${now} }, orderBy: timestamp,  orderDirection: desc) {
            mbtAmount
            timestamp
          }
          redeemHistories(first: $first, where: { timestamp_gte: ${now} }, orderBy: timestamp,  orderDirection: desc) {
            fee
            timestamp
          }
        }
      `,
      { first },
    )
    const { donateHistories, redeemHistories } = response
    let donateAmount3 = new BigNumber(0)
    let feeAmount3 = new BigNumber(0)
    donateHistories.forEach((item) => {
      donateAmount3 = donateAmount3.plus(item.mbtAmount)
    })
    redeemHistories.forEach((item) => {
      feeAmount3 = feeAmount3.plus(item.fee)
    })
    const donateDt = HOURS_1
      // (Number(donateHistories[0]?.timestamp) || 0) - Number(donateHistories[donateHistories.length - 1]?.timestamp || 0)
    const feeDt = HOURS_1
      // (Number(redeemHistories[0]?.timestamp) || 0) - Number(redeemHistories[redeemHistories.length - 1]?.timestamp || 0)

    return {
      donateAmount3: donateAmount3.toString(),
      feeAmount3: feeAmount3.toString(),
      donateDt,
      feeDt,
    }
  } catch (error) {
    console.error(error)
    return {
      donateAmount3: '0',
      feeAmount3: '0',
      donateDt: 0,
      feeDt: 0,
    }
  }
}

export const getUserRanking = async (first = 10, where: LotteriesWhere = {}): Promise<MemberUserRanking[]> => {
  try {
    const response = await request(
      GRAPH_API_MEMBER,
      gql`
        query getUserRanking($first: Int!) {
          users(first: $first, where: $where, orderBy: credit, orderDirection: desc) {
            id
            spFromInvited
            creditFromInvited
            credit
            stakingPower
          }
        }
      `,
      { first, where },
    )
    const users = response.users.filter(item => {
      if (!EXCEPT_MEMBER_RANKING_ADDRESS.length) return true
      return EXCEPT_MEMBER_RANKING_ADDRESS.every(subAddress => subAddress.toLowerCase() !== item?.id?.toLowerCase()) && !isZero(item?.id)
    }).slice(0, 6)
    return users
    return response.users
  } catch (error) {
    return []
  }
}

export const getUserInvite = async (
  account: string,
  first = 100,
  where: LotteriesWhere = {},
): Promise<MemberUserRanking[]> => {
  try {
    const response = await request(
      GRAPH_API_MEMBER,
      gql`
        query getUserInvite($first: Int!, $where: User_filter) {
          users(first: $first, where: $where, orderBy: creditOfSuperior, orderDirection: desc) {
            id
            spFromInvited
            creditFromInvited
            stakingPower
            superior
            mintAmount
            credit
            creditOfSuperior
          }
        }
      `,
      { first, where: { ...where, superior: `${account}`.toLowerCase() } },
    )
    return response.users
  } catch (error) {
    return []
  }
}

export const getUserBanlance = async (
  first = 20,
  where: LotteriesWhere = {},
): Promise<MemberBalanceRanking[]> => {
  try {
    const response = await request(
      GRAPH_API_MEMBER,
      gql`
        query getUserBalance($first: Int!, $where: User_filter) {
          users(first: $first, where: $where, orderBy: stakingPower, orderDirection: desc) {
            id
            mintAmount
            stakingPower
            redeemRecieveAmount
          }
        }
      `,
      { first, where },
    )
    const res = response.users.filter(item => {
      if (!EXCEPT_MEMBER_RANKING_ADDRESS.length) return true
      return EXCEPT_MEMBER_RANKING_ADDRESS.every(subAddress => subAddress.toLowerCase() !== item.id?.toLowerCase()) && !isZero(item.id)
    }).slice(0, 10)
    return res
  } catch (error) {
    console.error(error)
    return []
  }
}

export const getMindAndRedeemHistory = async (
  first = 50,
  where: LotteriesWhere = {},
): Promise<{
  redeemHistories: MemberRedeemList[]
  mintHistories: MemberMintList[]
}> => {
  try {
    const response = await request(
      GRAPH_API_MEMBER,
      gql`
        query getMindAndRedeemHistory($first: Int!, $where: User_filter) {
          redeemHistories(first: $first, where: $where, orderBy: timestamp, orderDirection: desc) {
            id
            user(first: 1) {
              id
            }
            burn
            timestamp
            recieve
            fee
          }
          mintHistories(first: $first, where: $where, orderBy: timestamp, orderDirection: desc) {
            id
            user(first: 1) {
              id
              superior
            }
            amount
            timestamp
            
          }
        }
      `,
      { first, where },
    )
    return response
  } catch (error) {
    console.error(error)
    return {
      redeemHistories: [],
      mintHistories: [],
    }
  }
}

export const getMindHistory = async (
  first = 20,
  where: LotteriesWhere = {},
): Promise<{
  // redeemHistories: MemberRedeemList[]
  mintHistories: MemberMintList[]
}> => {
  try {
    const response = await request(
      GRAPH_API_MEMBER,
      gql`
        query getMindHistory($first: Int!, $where: User_filter) {
          mintHistories(first: $first, where: $where, orderBy: timestamp, orderDirection: desc) {
            id
            user(first: 1) {
              id
              superior
            }
            amount
            timestamp
            
          }
        }
      `,
      { first, where },
    )
    const mintHistories = response.mintHistories.filter(item => {
      if (!EXCEPT_MEMBER_RANKING_ADDRESS.length) return true
      return EXCEPT_MEMBER_RANKING_ADDRESS.every(subAddress => subAddress.toLowerCase() !== item?.user?.id?.toLowerCase()) && !isZero(item?.user?.id)
    }).slice(0, 20)
    return {
      mintHistories,
    }
    return response
  } catch (error) {
    console.error(error)
    return {
      // redeemHistories: [],
      mintHistories: [],
    }
  }
}
export const getRedeemHistory = async (
  first = 20,
  where: LotteriesWhere = {},
): Promise<{
  redeemHistories: MemberRedeemList[]
  // mintHistories: MemberMintList[]
}> => {
  try {
    const response = await request(
      GRAPH_API_MEMBER,
      gql`
        query getRedeemHistory($first: Int!, $where: User_filter) {
          redeemHistories(first: $first, where: $where, orderBy: timestamp, orderDirection: desc) {
            id
            user(first: 1) {
              id
              mintAmount
              stakingPower
              redeemRecieveAmount
            }
            burn
            timestamp
            recieve
            fee
          }
        }
      `,
      { first, where },
    )
    const redeemHistories = response.redeemHistories.filter(item => {
      if (!EXCEPT_MEMBER_RANKING_ADDRESS.length) return true
      return EXCEPT_MEMBER_RANKING_ADDRESS.every(subAddress => subAddress.toLowerCase() !== item?.user?.id?.toLowerCase()) && !isZero(item?.user?.id)
    }).slice(0, 20)
    return {
      redeemHistories,
    }
    return response
  } catch (error) {
    console.error(error)
    return {
      redeemHistories: [],
      // mintHistories: [],
    }
  }
}
