import React, { useMemo } from 'react'
import { BASE_TRADE_SWAP_URL_OUT } from 'config'
import { Flex, Link, Text, BlockTimeIcon, FlexProps, Skeleton } from 'pancake-uikit'
import { useWeb3React } from '@web3-react/core'
import { useBlock } from 'state/block/hooks'
import { useTranslation } from 'contexts/Localization'
import Balance from 'components/Balance'
import { getBscScanLink } from 'utils'
import useBlockCountdown from 'hooks/useGetBlockCountdown'
import getTimePeriods from 'utils/getTimePeriods'
import Timer from 'components/Time/Timer'
// useBlockCountdown


interface BlockTimeProps extends FlexProps {
  startBlock: number
  endBlock: number
  startLable?: string
  endLable?: string
  downloadType?: 'block' | 'time'
}
enum BLOCK_STATUS {
  LOADING,
  PENDDING,
  CURRENT,
  END,
}


const BlockTime: React.FC<BlockTimeProps> = ({ startBlock, downloadType, endBlock, startLable, endLable, ...props }) => {
  const { t } = useTranslation()
  const { currentBlock } = useBlock()
  const { chainId } = useWeb3React()

  const [blockStatus, displayBlock, countdownBlock] = useMemo(() => {
    if (!currentBlock) return [BLOCK_STATUS.LOADING]
    if (currentBlock < startBlock) return [BLOCK_STATUS.PENDDING, startBlock - currentBlock, startBlock]
    if (currentBlock >= startBlock && currentBlock <= endBlock) return [BLOCK_STATUS.CURRENT, endBlock - currentBlock, endBlock]
    return [BLOCK_STATUS.END, endBlock]
  }, [currentBlock, startBlock, endBlock])

  const timeDown = useBlockCountdown(countdownBlock)
  const { days, hours, minutes, seconds } = getTimePeriods(timeDown)

  return (
    <>
      {
        blockStatus === BLOCK_STATUS.END
        ?
        <Flex {...props} alignItems="center">
          <Text mr="4px" fontSize="14px">{t('Finished')}:</Text>
            <Text bold color="primary">--</Text>
          {/* <Link external href={getBscScanLink(countdownBlock, 'block', chainId)}>
            <Balance bold color="primary" decimals={0} value={displayBlock} />
          </Link>
          <Link external href={getBscScanLink(countdownBlock, 'block', chainId)}>
          </Link> */}
            <BlockTimeIcon width={32} />
        </Flex>
        :
        <Flex {...props} alignItems="center">
          <Text mr="4px" fontSize="14px">{
            blockStatus === BLOCK_STATUS.CURRENT ? t('End in') : t('Start in')
          }:</Text>
          <Link external href={getBscScanLink(countdownBlock, 'block', chainId)}>
            {
              blockStatus === BLOCK_STATUS.LOADING
              ?
              <Skeleton width={80} height={22} />
              :
              (
                downloadType === 'time'
                ?
                // <Balance bold color="primary" decimals={0} value={displayBlock} />
                // <Text bold color="primary"></Text>
                <Timer itemMr="4px" textMr="2px" color="primary" scale="ld" days={days} hours={hours} minutes={minutes} seconds={seconds} />
                :
                <Balance bold color="primary" decimals={0} value={displayBlock} />
              )
            }
            { downloadType === 'block' && (
              <Text bold color="primary">{t('Blocks')}</Text>
            ) }
          </Link>
          <Link external href={getBscScanLink(countdownBlock, 'block', chainId)}>
            <BlockTimeIcon />
          </Link>
        </Flex>
      }
    </>
  )
}

export default BlockTime
