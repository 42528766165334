import { ChainId } from './chainId'
import { Token } from './entities/token'
import tokenList from './default.tokenlist.json'
import { formatTokenList } from './utils'

const tokensMap = formatTokenList(tokenList.tokens)

export const stableSymbols = ['USDC', 'DAI', 'USDT', 'BUSD']

export const WETHER = {
  // [ChainId.MAINNET]: new Token(
  //   ChainId.MAINNET,
  //   tokensMap[`WBNB_${ChainId.MAINNET}`].address,
  //   tokensMap[`WBNB_${ChainId.MAINNET}`].decimals,
  //   tokensMap[`WBNB_${ChainId.MAINNET}`].symbol,
  //   tokensMap[`WBNB_${ChainId.MAINNET}`].name,
  // ),
  [ChainId.MATIC_TESTNET]: new Token(
    ChainId.MATIC_TESTNET,
    tokensMap[`WMATIC_${ChainId.MATIC_TESTNET}`].address,
    tokensMap[`WMATIC_${ChainId.MATIC_TESTNET}`].decimals,
    tokensMap[`WMATIC_${ChainId.MATIC_TESTNET}`].symbol,
    tokensMap[`WMATIC_${ChainId.MATIC_TESTNET}`].name,
  ),
  [ChainId.MATIC_MAINNET]: new Token(
    ChainId.MATIC_MAINNET,
    tokensMap[`WMATIC_${ChainId.MATIC_MAINNET}`].address,
    tokensMap[`WMATIC_${ChainId.MATIC_MAINNET}`].decimals,
    tokensMap[`WMATIC_${ChainId.MATIC_MAINNET}`].symbol,
    tokensMap[`WMATIC_${ChainId.MATIC_MAINNET}`].name,
  ),
  // [ChainId.TESTNET]: new Token(
  //   ChainId.TESTNET,
  //   tokensMap[`WBNB_${ChainId.TESTNET}`].address,
  //   tokensMap[`WBNB_${ChainId.TESTNET}`].decimals,
  //   tokensMap[`WBNB_${ChainId.TESTNET}`].symbol,
  //   tokensMap[`WBNB_${ChainId.TESTNET}`].name,
  // ),
}

export const ETHER_TOKEN = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    18,
    'BNB',
    'BNB',
  ),
  [ChainId.MATIC_TESTNET]: new Token(
    ChainId.MATIC_TESTNET,
    '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    18,
    'MATIC',
    'MATIC',
  ),
  [ChainId.MATIC_MAINNET]: new Token(
    ChainId.MATIC_MAINNET,
    '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    18,
    'MATIC',
    'MATIC',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    18,
    'MATIC',
    'MATIC',
  ),
}

export const SWAP_TOKEN = {
    // [ChainId.MAINNET]: new Token(
    //   ChainId.MAINNET,
    //   tokensMap[`MBT_${ChainId.TESTNET}`].address,
    //   tokensMap[`MBT_${ChainId.TESTNET}`].decimals,
    //   tokensMap[`MBT_${ChainId.TESTNET}`].symbol,
    //   tokensMap[`MBT_${ChainId.TESTNET}`].name,
    // ),
    [ChainId.MATIC_TESTNET]: new Token(
      ChainId.MATIC_TESTNET,
      tokensMap[`MBT_${ChainId.MATIC_TESTNET}`].address,
      tokensMap[`MBT_${ChainId.MATIC_TESTNET}`].decimals,
      tokensMap[`MBT_${ChainId.MATIC_TESTNET}`].symbol,
      tokensMap[`MBT_${ChainId.MATIC_TESTNET}`].name,
    ),
    [ChainId.MATIC_MAINNET]: new Token(
      ChainId.MATIC_MAINNET,
      tokensMap[`MBT_${ChainId.MATIC_MAINNET}`].address,
      tokensMap[`MBT_${ChainId.MATIC_MAINNET}`].decimals,
      tokensMap[`MBT_${ChainId.MATIC_MAINNET}`].symbol,
      tokensMap[`MBT_${ChainId.MATIC_MAINNET}`].name,
    ),
    // [ChainId.TESTNET]: new Token(
    //   ChainId.TESTNET,
    //   '0x48F1935187D71990ded5c5c3fC65F2b9896380b5',
    //   18,
    //   'MBT',
    //   'Mbt',
    // ),
}

export const MBT: { [chainId: number]: Token } = {
  [ChainId.MATIC_TESTNET]: new Token(
    ChainId.MATIC_TESTNET,
    tokensMap[`MBT_${ChainId.MATIC_TESTNET}`].address,
    tokensMap[`MBT_${ChainId.MATIC_TESTNET}`].decimals,
    tokensMap[`MBT_${ChainId.MATIC_TESTNET}`].symbol,
    tokensMap[`MBT_${ChainId.MATIC_TESTNET}`].name,
  ),
  [ChainId.MATIC_MAINNET]: new Token(
    ChainId.MATIC_MAINNET,
    tokensMap[`MBT_${ChainId.MATIC_MAINNET}`].address,
    tokensMap[`MBT_${ChainId.MATIC_MAINNET}`].decimals,
    tokensMap[`MBT_${ChainId.MATIC_MAINNET}`].symbol,
    tokensMap[`MBT_${ChainId.MATIC_MAINNET}`].name,
  ),
}

export const DSG: { [chainId: number]: Token } = {
  [ChainId.MATIC_TESTNET]: new Token(
    ChainId.MATIC_TESTNET,
    tokensMap[`DSG_${ChainId.MATIC_TESTNET}`].address,
    tokensMap[`DSG_${ChainId.MATIC_TESTNET}`].decimals,
    tokensMap[`DSG_${ChainId.MATIC_TESTNET}`].symbol,
    tokensMap[`DSG_${ChainId.MATIC_TESTNET}`].name,
  ),
  [ChainId.MATIC_MAINNET]: new Token(
    ChainId.MATIC_MAINNET,
    tokensMap[`DSG_${ChainId.MATIC_MAINNET}`].address,
    tokensMap[`DSG_${ChainId.MATIC_MAINNET}`].decimals,
    tokensMap[`DSG_${ChainId.MATIC_MAINNET}`].symbol,
    tokensMap[`DSG_${ChainId.MATIC_MAINNET}`].name,
  ),
}

export const BUSD: { [chainId: number]: Token } = {
  // [ChainId.MATIC_TESTNET]: new Token(
  //   ChainId.MATIC_TESTNET,
  //   tokensMap[`BUSD_${ChainId.MATIC_TESTNET}`].address,
  //   tokensMap[`BUSD_${ChainId.MATIC_TESTNET}`].decimals,
  //   tokensMap[`BUSD_${ChainId.MATIC_TESTNET}`].symbol,
  //   tokensMap[`BUSD_${ChainId.MATIC_TESTNET}`].name,
  // ),
}

export const USDT = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    tokensMap[`USDT_${ChainId.TESTNET}`].address,
    tokensMap[`USDT_${ChainId.TESTNET}`].decimals,
    tokensMap[`USDT_${ChainId.TESTNET}`].symbol,
    tokensMap[`USDT_${ChainId.TESTNET}`].name,
  ),
  [ChainId.MATIC_TESTNET]: new Token(
    ChainId.MATIC_TESTNET,
    tokensMap[`USDT_${ChainId.MATIC_TESTNET}`].address,
    tokensMap[`USDT_${ChainId.MATIC_TESTNET}`].decimals,
    tokensMap[`USDT_${ChainId.MATIC_TESTNET}`].symbol,
    tokensMap[`USDT_${ChainId.MATIC_TESTNET}`].name,
  ),
  [ChainId.MATIC_MAINNET]: new Token(
    ChainId.MATIC_MAINNET,
    tokensMap[`USDT_${ChainId.MATIC_MAINNET}`].address,
    tokensMap[`USDT_${ChainId.MATIC_MAINNET}`].decimals,
    tokensMap[`USDT_${ChainId.MATIC_MAINNET}`].symbol,
    tokensMap[`USDT_${ChainId.MATIC_MAINNET}`].name,
  ),
}

export const VAI = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    tokensMap[`USDT_${ChainId.TESTNET}`].address,
    tokensMap[`USDT_${ChainId.TESTNET}`].decimals,
    tokensMap[`USDT_${ChainId.TESTNET}`].symbol,
    tokensMap[`USDT_${ChainId.TESTNET}`].name,
  ),
  [ChainId.MATIC_TESTNET]: new Token(
    ChainId.MATIC_TESTNET,
    tokensMap[`USDT_${ChainId.MATIC_TESTNET}`].address,
    tokensMap[`USDT_${ChainId.MATIC_TESTNET}`].decimals,
    tokensMap[`USDT_${ChainId.MATIC_TESTNET}`].symbol,
    tokensMap[`USDT_${ChainId.MATIC_TESTNET}`].name,
  ),
  [ChainId.MATIC_MAINNET]: new Token(
    ChainId.MATIC_MAINNET,
    tokensMap[`USDT_${ChainId.MATIC_MAINNET}`].address,
    tokensMap[`USDT_${ChainId.MATIC_MAINNET}`].decimals,
    tokensMap[`USDT_${ChainId.MATIC_MAINNET}`].symbol,
    tokensMap[`USDT_${ChainId.MATIC_MAINNET}`].name,
  ),
}
export const DAI = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    tokensMap[`DAI_${ChainId.TESTNET}`].address,
    tokensMap[`DAI_${ChainId.TESTNET}`].decimals,
    tokensMap[`DAI_${ChainId.TESTNET}`].symbol,
    tokensMap[`DAI_${ChainId.TESTNET}`].name,
  ),
  [ChainId.MATIC_TESTNET]: new Token(
    ChainId.MATIC_TESTNET,
    tokensMap[`DAI_${ChainId.MATIC_TESTNET}`].address,
    tokensMap[`DAI_${ChainId.MATIC_TESTNET}`].decimals,
    tokensMap[`DAI_${ChainId.MATIC_TESTNET}`].symbol,
    tokensMap[`DAI_${ChainId.MATIC_TESTNET}`].name,
  ),
  [ChainId.MATIC_MAINNET]: new Token(
    ChainId.MATIC_MAINNET,
    tokensMap[`DAI_${ChainId.MATIC_MAINNET}`].address,
    tokensMap[`DAI_${ChainId.MATIC_MAINNET}`].decimals,
    tokensMap[`DAI_${ChainId.MATIC_MAINNET}`].symbol,
    tokensMap[`DAI_${ChainId.MATIC_MAINNET}`].name,
  ),
}

export const WETH = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    tokensMap[`WETH_${ChainId.TESTNET}`].address,
    tokensMap[`WETH_${ChainId.TESTNET}`].decimals,
    tokensMap[`WETH_${ChainId.TESTNET}`].symbol,
    tokensMap[`WETH_${ChainId.TESTNET}`].name,
  ),
  [ChainId.MATIC_TESTNET]: new Token(
    ChainId.MATIC_TESTNET,
    tokensMap[`WETH_${ChainId.MATIC_TESTNET}`].address,
    tokensMap[`WETH_${ChainId.MATIC_TESTNET}`].decimals,
    tokensMap[`WETH_${ChainId.MATIC_TESTNET}`].symbol,
    tokensMap[`WETH_${ChainId.MATIC_TESTNET}`].name,
  ),
  [ChainId.MATIC_MAINNET]: new Token(
    ChainId.MATIC_MAINNET,
    tokensMap[`WETH_${ChainId.MATIC_MAINNET}`].address,
    tokensMap[`WETH_${ChainId.MATIC_MAINNET}`].decimals,
    tokensMap[`WETH_${ChainId.MATIC_MAINNET}`].symbol,
    tokensMap[`WETH_${ChainId.MATIC_MAINNET}`].name,
  ),
}

export const USDC = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    tokensMap[`USDC_${ChainId.TESTNET}`].address,
    tokensMap[`USDC_${ChainId.TESTNET}`].decimals,
    tokensMap[`USDC_${ChainId.TESTNET}`].symbol,
    tokensMap[`USDC_${ChainId.TESTNET}`].name,
  ),
  [ChainId.MATIC_TESTNET]: new Token(
    ChainId.MATIC_TESTNET,
    tokensMap[`USDC_${ChainId.MATIC_TESTNET}`].address,
    tokensMap[`USDC_${ChainId.MATIC_TESTNET}`].decimals,
    tokensMap[`USDC_${ChainId.MATIC_TESTNET}`].symbol,
    tokensMap[`USDC_${ChainId.MATIC_TESTNET}`].name,
  ),
  [ChainId.MATIC_MAINNET]: new Token(
    ChainId.MATIC_MAINNET,
    tokensMap[`USDC_${ChainId.MATIC_MAINNET}`].address,
    tokensMap[`USDC_${ChainId.MATIC_MAINNET}`].decimals,
    tokensMap[`USDC_${ChainId.MATIC_MAINNET}`].symbol,
    tokensMap[`USDC_${ChainId.MATIC_MAINNET}`].name,
  ),
}

export const WBTC = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    tokensMap[`WBTC_${ChainId.TESTNET}`].address,
    tokensMap[`WBTC_${ChainId.TESTNET}`].decimals,
    tokensMap[`WBTC_${ChainId.TESTNET}`].symbol,
    tokensMap[`WBTC_${ChainId.TESTNET}`].name,
  ),
  [ChainId.MATIC_TESTNET]: new Token(
    ChainId.MATIC_TESTNET,
    tokensMap[`WBTC_${ChainId.MATIC_TESTNET}`].address,
    tokensMap[`WBTC_${ChainId.MATIC_TESTNET}`].decimals,
    tokensMap[`WBTC_${ChainId.MATIC_TESTNET}`].symbol,
    tokensMap[`WBTC_${ChainId.MATIC_TESTNET}`].name,
  ),
  [ChainId.MATIC_MAINNET]: new Token(
    ChainId.MATIC_MAINNET,
    tokensMap[`WBTC_${ChainId.MATIC_MAINNET}`].address,
    tokensMap[`WBTC_${ChainId.MATIC_MAINNET}`].decimals,
    tokensMap[`WBTC_${ChainId.MATIC_MAINNET}`].symbol,
    tokensMap[`WBTC_${ChainId.MATIC_MAINNET}`].name,
  ),
}

export const MBTMDF = {
  // [ChainId.MAINNET]: new Token(
  //   ChainId.MAINNET,
  //   tokensMap[`MBTCF_${ChainId.TESTNET}`].address,
  //   tokensMap[`MBTCF_${ChainId.TESTNET}`].decimals,
  //   tokensMap[`MBTCF_${ChainId.TESTNET}`].symbol,
  //   tokensMap[`MBTCF_${ChainId.TESTNET}`].name,
  // ),
  [ChainId.MATIC_TESTNET]: new Token(
    ChainId.MATIC_TESTNET,
    tokensMap[`MBTCF_${ChainId.MATIC_TESTNET}`].address,
    tokensMap[`MBTCF_${ChainId.MATIC_TESTNET}`].decimals,
    tokensMap[`MBTCF_${ChainId.MATIC_TESTNET}`].symbol,
    tokensMap[`MBTCF_${ChainId.MATIC_TESTNET}`].name,
  ),
  [ChainId.MATIC_MAINNET]: new Token(
    ChainId.MATIC_MAINNET,
    tokensMap[`MBTCF_${ChainId.MATIC_MAINNET}`].address,
    tokensMap[`MBTCF_${ChainId.MATIC_MAINNET}`].decimals,
    tokensMap[`MBTCF_${ChainId.MATIC_MAINNET}`].symbol,
    tokensMap[`MBTCF_${ChainId.MATIC_MAINNET}`].name,
  ),
}
export const MBTMF = {
  // [ChainId.MAINNET]: new Token(
  //   ChainId.MAINNET,
  //   tokensMap[`MBTPF_${ChainId.TESTNET}`].address,
  //   tokensMap[`MBTPF_${ChainId.TESTNET}`].decimals,
  //   tokensMap[`MBTPF_${ChainId.TESTNET}`].symbol,
  //   tokensMap[`MBTPF_${ChainId.TESTNET}`].name,
  // ),
  [ChainId.MATIC_TESTNET]: new Token(
    ChainId.MATIC_TESTNET,
    tokensMap[`MBTPF_${ChainId.MATIC_TESTNET}`].address,
    tokensMap[`MBTPF_${ChainId.MATIC_TESTNET}`].decimals,
    tokensMap[`MBTPF_${ChainId.MATIC_TESTNET}`].symbol,
    tokensMap[`MBTPF_${ChainId.MATIC_TESTNET}`].name,
  ),
  [ChainId.MATIC_MAINNET]: new Token(
    ChainId.MATIC_MAINNET,
    tokensMap[`MBTPF_${ChainId.MATIC_MAINNET}`].address,
    tokensMap[`MBTPF_${ChainId.MATIC_MAINNET}`].decimals,
    tokensMap[`MBTPF_${ChainId.MATIC_MAINNET}`].symbol,
    tokensMap[`MBTPF_${ChainId.MATIC_MAINNET}`].name,
  ),
}


export const tokens = {
  bnb: {
    symbol: 'MBT',
    address: {
      56: '0x165c1eb65ea235dd75b26bca48bc8b1be8e82ec2',
      [ChainId.MATIC_TESTNET]: '0x165c1eb65ea235dd75b26bca48bc8b1be8e82ec2',
      137: '0x48F1935187D71990ded5c5c3fC65F2b9896380b5', // TODO:
      222: '0x48F1935187D71990ded5c5c3fC65F2b9896380b5',
    },
    decimals: 18,
    projectLink: 'https://dsgmetaverse.com/', // TODO:
  },
  dsg: {
    symbol: 'MBT',
    address: {
      56: '0x165c1eb65ea235dd75b26bca48bc8b1be8e82ec2',
      [ChainId.MATIC_TESTNET]: '0x165c1eb65ea235dd75b26bca48bc8b1be8e82ec2',
      137: '0x48F1935187D71990ded5c5c3fC65F2b9896380b5', // TODO:
      222: '0x48F1935187D71990ded5c5c3fC65F2b9896380b5',
    },
    decimals: 18,
    projectLink: 'https://dsgmetaverse.com/', // TODO:
  },
  dsgff: {
    symbol: 'MBTMF',
    address: {
      56: '0x6ba96910a07bf22610bff3e00484fd8b374f1918',
      [ChainId.MATIC_TESTNET]: '0x6ba96910a07bf22610bff3e00484fd8b374f1918',
      137: '0xbd8Ef499A1d70a0410a5B84365b11c166C28b040', // TODO:
      222: '0xbd8Ef499A1d70a0410a5B84365b11c166C28b040',
    },
    decimals: 18,
    projectLink: 'https://dsgmetaverse.com/', // TODO:
  },
  dsgtf: {
    symbol: 'MBTMDF',
    address: {
      56: '0x1feb33d5f5be14c407b9a7d3483f9f076eec0498',
      [ChainId.MATIC_TESTNET]: '0x1feb33d5f5be14c407b9a7d3483f9f076eec0498',
      137: '0xfae5D951cE711049C89679B18B776e6139ff3Ab3', // TODO:
      222: '0xfae5D951cE711049C89679B18B776e6139ff3Ab3',
    },
    decimals: 18,
    projectLink: 'https://dsgmetaverse.com/', // TODO:
  },
  busd: {
    symbol: 'MBTMDF',
    address: {
      56: '0x1feb33d5f5be14c407b9a7d3483f9f076eec0498',
      [ChainId.MATIC_TESTNET]: '0x1feb33d5f5be14c407b9a7d3483f9f076eec0498',
      137: '0xfae5D951cE711049C89679B18B776e6139ff3Ab3', // TODO:
      222: '0xfae5D951cE711049C89679B18B776e6139ff3Ab3',
    },
    decimals: 18,
    projectLink: 'https://dsgmetaverse.com/', // TODO:
  },
  busc: {
    symbol: 'MBTMDF',
    address: {
      56: '0x1feb33d5f5be14c407b9a7d3483f9f076eec0498',
      [ChainId.MATIC_TESTNET]: '0x1feb33d5f5be14c407b9a7d3483f9f076eec0498',
      137: '0xfae5D951cE711049C89679B18B776e6139ff3Ab3', // TODO:
      222: '0xfae5D951cE711049C89679B18B776e6139ff3Ab3',
    },
    decimals: 18,
    projectLink: 'https://dsgmetaverse.com/', // TODO:
  },
  usdt: {
    symbol: 'MBTMDF',
    address: {
      56: '0x1feb33d5f5be14c407b9a7d3483f9f076eec0498',
      [ChainId.MATIC_TESTNET]: '0x1feb33d5f5be14c407b9a7d3483f9f076eec0498',
      137: '0xfae5D951cE711049C89679B18B776e6139ff3Ab3', // TODO:
      222: '0xfae5D951cE711049C89679B18B776e6139ff3Ab3',
    },
    decimals: 18,
    projectLink: 'https://dsgmetaverse.com/', // TODO:
  },
}

