import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import { getFFragmentPoolAddress, getIncentivePoolAddress, getLiquidityPoolAddress, getTFragmentPoolAddress, getToken, getV1FarmPoolAddress } from 'utils/addressHelpers'
import { WETH } from 'dsgswap-sdk'
import { isBNBDSGLp, isBaseTokenLp } from 'utils/getLiquidityPool'
import fetchFarms from './fetchFarms'
import fetchFarmList, {
  fetchAdditionalRate,
  fetchAdditionalRates,
  fetchErc20EarnNftList,
  fetchNftApprovalFarm,
  fetchNftApprovalFarmLP
} from './fetchFarmList'
import {
  fetchNftEarnErc20List,
  fetchNftEarnErc20Fee,
  fetchNftEarnErc20Rate,
  fetchNftFramPoolInfo,
} from './fetchNftsFarmData'
import fetchFarmsPrices, { fetchFarmsPricesWithLp } from './fetchFarmsPrices'
import {
  fetchFarmUserAllowances,
  fetchFarmUserViews,
  fetchPoolsAllowance,
  fetchErc20PoolsUserInfo,
  fetchErc20FarmUserAllowances,
  fetchFarmUserTokenBalances,
  fetchNftsPoolsUserInfo,
  fetchNftsFarmUserAllowances,
  fetchNftPendingUser,
  fetchNftApprovalUser,
  fetchFarmUserAllowancesDsg,
} from './fetchFarmUser'

import { fetchFFragmentList, fetchTFragmentList, fetchIncentiveFarmragmentList, fetchV1FarmragmentList } from './fetchFragmentFarm'
// fetchNftsInfo
import {
  FarmsState,
  Farm,
  AppThunk,
  State,
  FarmUserData,
  Erc20Farm,
  NftFarm,
  FarmPoolType,
  DsgFarmUserData,
} from '../types'
import fetchNftsInfo from './fetchNftInfo'
import { getLpRewardsApr } from './fetchLpAprs'

const weth = getToken(WETH)

const initialState: FarmsState = {
  tokenPrices: {},
  data: [],
  v1Farm: {
    data: [],
    dataLoaded: false,
  },
  ffFarm: {
    data: [],
    dataLoaded: false,
  },
  incentiveFarm: {
    data: [],
    dataLoaded: false,
  },
  tfFarm: {
    data: [],
    dataLoaded: false,
  },
  nftFarm: {
    data: [],
    userLoaded: false,
    dataLoaded: false,
  },
  erc20Farm: {
    data: [],
    dataLoaded: false,
  },
  loadArchivedFarmsData: false,
  userDataLoaded: false,
  farmsDataLoaded: false,
  userData: {
    earnings: '0',
    dataLoaded: false,
  },
  levelRates: []
}

export const fetchFarmsPublicDataList =
  (account?: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const farmData = state.farms.data
    const data = await fetchFarmList(farmData)
    dispatch(setFarmsPublicDataList({ data, farmPoolType: FarmPoolType.DSG_FARM }))
    // dispatch(fetchLpRewardsApr(data))
    dispatch(fetchFarmsPublicDatasAsync(data, FarmPoolType.DSG_FARM, account))
  }

export const fetchLpRewardsApr =
  (data: Farm[]): AppThunk =>
  async (dispatch, getState) => {
    const lpFarm = await getLpRewardsApr(data)
    dispatch(setFarmsPublicDataList({ data: lpFarm, farmPoolType: FarmPoolType.DSG_FARM }))

  }

export const fetchFfFarmsPublicDataListAsync =
  (account?: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const farmData = state.farms.ffFarm.data
    const data = await fetchFFragmentList(farmData)
    dispatch(setFarmsPublicDataList({ data, farmPoolType: FarmPoolType.FF_FARM }))
    dispatch(fetchFarmsPublicDatasAsync(data, FarmPoolType.FF_FARM, account))
  }
export const fetchTfFarmsPublicDataListAsync =
  (account?: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const farmData = state.farms.ffFarm.data
    const data = await fetchTFragmentList(farmData)
    dispatch(setFarmsPublicDataList({ data, farmPoolType: FarmPoolType.TF_FARM }))
    dispatch(fetchFarmsPublicDatasAsync(data, FarmPoolType.TF_FARM, account))
  }
export const fetchIncentiveFarmragmentListAsync =
  (account?: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const farmData = state.farms.incentiveFarm.data
    const data = await fetchIncentiveFarmragmentList(farmData)
    dispatch(setFarmsPublicDataList({ data, farmPoolType: FarmPoolType.INCENTIVE_FARM }))
    dispatch(fetchFarmsPublicDatasAsync(data, FarmPoolType.INCENTIVE_FARM, account))
  }
export const fetchV1FarmragmentListAsync =
  (account?: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const farmData = state.farms.v1Farm.data
    const data = await fetchV1FarmragmentList(farmData)
    dispatch(setFarmsPublicDataList({ data, farmPoolType: FarmPoolType.V1_FARM }))
    dispatch(fetchFarmsPublicDatasAsync(data, FarmPoolType.V1_FARM, account))
  }

export const fetchErc20FarmsPublicDataList =
  (account?: string): AppThunk =>
  async (dispatch) => {
    const farmList = await fetchErc20EarnNftList()
    dispatch(setErc20FarmsPublicDataList(farmList))
    if (account) {
      dispatch(fetchErc20VaultUserAsync(account, farmList))
    }
  }

export const fetchNftsFarmsPublicDataList =
  (account?: string): AppThunk =>
  async (dispatch) => {
    const farmList = await fetchNftEarnErc20List()
    dispatch(fetchNftsFarmsPublicDataFees())
    dispatch(setNftsFarmsPublicDataList(farmList))
    if (account) {
      dispatch(fetchNftsVaultUserAsync(account, farmList))
    }
  }

export const fetchNftsFarmsPublicDataFees = (): AppThunk => async (dispatch) => {
  const farmFees = await fetchNftEarnErc20Fee()
  const farmRates = await fetchNftEarnErc20Rate()
  const poolInfo = await fetchNftFramPoolInfo()
  const farmInfo = {
    farmFees,
    farmRates,
    poolInfo,
  }
  dispatch(setNftsFarmsPublicDataFees(farmInfo))
}

// Async thunks
export const fetchFarmsPublicDataAsync = createAsyncThunk<Farm[]>(
  'farms/fetchFarmsPublicDataAsync',
  async (val, { getState }) => {
    const state = getState() as State
    let farmsData = state.farms.data
    if (!farmsData.length) {
      farmsData = await fetchFarmList([])
    }
    const farmsToFetch = farmsData.filter((farmConfig) => isBaseTokenLp(weth.symbol, farmConfig) || isBNBDSGLp(farmConfig))
    // Add price helper farms
    const farms = await fetchFarms(farmsToFetch)
    const farmsWithPrices = await fetchFarmsPrices(farms)
    // Filter out price helper LP config farms
    // const farmsWithoutHelperLps = farmsWithPrices.filter((farm: Farm) => {
    //   return farm.pid || farm.pid === 0
    // })
    return farmsWithPrices
  },
)

// Async thunks
export const fetchEarningVaultUserAsync = createAsyncThunk<FarmUserData[], string>(
  'farms/fetchEarningVaultUserAsync',
  async (account) => {
    const userViews = await fetchFarmUserViews(account)
    return userViews
  },
)

export const fetchFarmsPublicDatasAsync =
  (data: Farm[], farmPoolType: FarmPoolType, account?: string): AppThunk =>
  async (dispatch, getState) => {
    let farmBnbs = []
    const farms = await fetchFarms(data)
    if (farmPoolType !== FarmPoolType.DSG_FARM) {
      // const { data: farmLp } = state.farms
      const farmLp = await new Promise<Farm[]>((resolve) => {
        const getFarmDataHandle = () => {
          const state = getState()
          const { data: requestfarmLp } = state.farms
          if (requestfarmLp.length) {
            resolve(requestfarmLp)
            return true
          }
          return false
        }
        if (getFarmDataHandle()) return
        const requestfarmLpTimer = setInterval(() => {
          if (getFarmDataHandle()) {
            clearInterval(requestfarmLpTimer)
          }
        }, 1000)
      })
      const farmsToFetch = farmLp.filter((farmConfig) => isBaseTokenLp(weth.symbol, farmConfig) || isBNBDSGLp(farmConfig))
      farmBnbs = await fetchFarms(farmsToFetch)
    }
    const farmsWithPrices = await fetchFarmsPricesWithLp(farms, farmBnbs)
    if (farmPoolType === FarmPoolType.DSG_FARM) {
      dispatch(fetchLpRewardsApr(farmsWithPrices))
    }
    dispatch(setFarmsPublicDataList({ data: farmsWithPrices, farmPoolType }))
    if (account) {
      dispatch(fetchVaultUserAsync(account, farmPoolType, farmsWithPrices))
      if (farmPoolType === FarmPoolType.DSG_FARM) {
        dispatch(fetchNftApprovalFarmAsync(account, farmPoolType, farmsWithPrices))
      }
    }
  }


export const fetchAdditionalRatesAsync =
  (): AppThunk =>
  async (dispatch) => {
    const levelRate = await fetchAdditionalRates()
    dispatch(setAdditionalRates(levelRate))
  }
  
export const fetchNftApprovalFarmAsync =
  (account: string, farmPoolType: FarmPoolType, data?: Farm[]): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    let lpAddress
    let queryData = []
    switch (farmPoolType) {
      case FarmPoolType.DSG_FARM:
        queryData = data || state.farms.data
        lpAddress = getLiquidityPoolAddress()
        break
      case FarmPoolType.TF_FARM:
        queryData = data || state.farms.tfFarm.data
        lpAddress = getTFragmentPoolAddress()
        break
      case FarmPoolType.FF_FARM:
        queryData = data || state.farms.ffFarm.data
        lpAddress = getFFragmentPoolAddress()
        break
      case FarmPoolType.INCENTIVE_FARM:
        queryData = data || state.farms.incentiveFarm.data
        lpAddress = getIncentivePoolAddress()
        break
      case FarmPoolType.V1_FARM:
        queryData = data || state.farms.v1Farm.data
        lpAddress = getV1FarmPoolAddress()
        break
      default:
        console.error(`store:fetchVaultUserAsync, farmPoolType: ${farmPoolType} is not defined`)
        return
    }
    // const queryData = data || state.farms.data
    if (!queryData.length) return
    const userInfo = await fetchNftApprovalFarmLP(account, queryData, lpAddress)
    // const userInfo = await fetchNftApprovalFarm(account, queryData)
    // dispatch(setNftApprovedForAll(userInfo))
    dispatch(setNftApprovedForAllWithType({ data: userInfo, farmPoolType: FarmPoolType.DSG_FARM }))

  }

export const fetchVaultUserAsync =
  (account: string, farmPoolType: FarmPoolType, data?: Farm[]): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    let lpAddress
    let queryData = []
    switch (farmPoolType) {
      case FarmPoolType.DSG_FARM:
        queryData = data || state.farms.data
        lpAddress = getLiquidityPoolAddress()
        break
      case FarmPoolType.TF_FARM:
        queryData = data || state.farms.tfFarm.data
        lpAddress = getTFragmentPoolAddress()
        break
      case FarmPoolType.FF_FARM:
        queryData = data || state.farms.ffFarm.data
        lpAddress = getFFragmentPoolAddress()
        break
      case FarmPoolType.INCENTIVE_FARM:
        queryData = data || state.farms.incentiveFarm.data
        lpAddress = getIncentivePoolAddress()
        break
      case FarmPoolType.V1_FARM:
        queryData = data || state.farms.v1Farm.data
        lpAddress = getV1FarmPoolAddress()
        break
      default:
        console.error(`store:fetchVaultUserAsync, farmPoolType: ${farmPoolType} is not defined`)
        return
    }
    if (!queryData.length) return
    const userPoolsAllowance = await fetchPoolsAllowance(account, queryData)
    const userPoolsAllowanceDsg = await fetchFarmUserAllowancesDsg(account, lpAddress)
    const userFarmAllowances = await fetchFarmUserAllowances(account, queryData)
    const users = userPoolsAllowance.map((farmAllowance, index) => {
      return {
        pid: farmAllowance.pid,
        allowance: userFarmAllowances[index]?.hex,
        tokenBalance: farmAllowance.lpBalance.hex,
        allowanceDsg: userPoolsAllowanceDsg,
        stakedBalance: farmAllowance.stakedAmount.hex,
        earnings: farmAllowance.unclaimedRewards.hex,
        additionalNftId: farmAllowance.additionalNftId.hex,
        additionalRate: farmAllowance.additionalRate.hex,
      }
    })
    dispatch(setPoolUserData({ data: users, farmPoolType }))
    dispatch(fetchFarmNftsInfoAsync(farmPoolType, queryData, users))
  }

export const fetchErc20VaultUserAsync =
  (account: string, data?: Erc20Farm[]): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const queryData = data || state.farms.erc20Farm.data
    if (!queryData.length) return
    const userPoolsAllowance = await fetchErc20PoolsUserInfo(account, queryData)
    const userPoolsBalance = await fetchFarmUserTokenBalances(account, queryData)
    const userFarmAllowances = await fetchErc20FarmUserAllowances(account, queryData)
    const users = userPoolsAllowance.map((farmAllowance, index) => {
      return {
        pid: farmAllowance.pid,
        allowance: userFarmAllowances[index],
        tokenBalance: userPoolsBalance[index],
        stakedBalance: farmAllowance.accRewardAmount,
        earnings: farmAllowance.unclaimedRewards,
        stakeView: farmAllowance.stakeView,
      }
    })

    const userData = users.map((userDataEl) => {
      const { pid } = userDataEl
      const index = queryData.findIndex((farm) => farm.pid === pid)
      return { ...queryData[index], userData: userDataEl }
    })
    if (getState().farms.erc20Farm.data.length !== userData.length) return
    dispatch(setErc20FarmsPublicDataList(userData))
  }

export const fetchNftsVaultUserAsync =
  (account: string, data?: NftFarm[]): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const queryData = data || state.farms.nftFarm.data
    if (!queryData.length) return
    const userPoolsAllowance = await fetchNftsPoolsUserInfo(account, queryData)
    const userFarmAllowances = await fetchNftsFarmUserAllowances(account, queryData)
    const userFarmPendings = await fetchNftPendingUser(account, queryData)
    const userApprovals = await fetchNftApprovalUser(account, queryData)
    const users = userPoolsAllowance.map((farmAllowance, index) => {
      return {
        pid: index,
        allowance: userFarmAllowances[index],
        earnings: userFarmPendings[index],
        stakedBalance: farmAllowance.nfts.length,
        isApproval: userApprovals[index],
        ...farmAllowance,
      }
    })

    const userData = users.map((userDataEl) => {
      const { pid } = userDataEl
      const index = queryData.findIndex((farm) => farm.pid === pid)
      return { ...queryData[index], userData: userDataEl }
    })
    if (getState().farms.nftFarm.data.length !== userData.length) return
    dispatch(setNftsFarmsPublicDataList(userData))
    dispatch(fetchStakeNftsInfoAsync(userData))
    dispatch(fetchSlotsNftsInfoAsync(userData))
  }

export const fetchFarmNftsInfoAsync =
  (farmPoolType: FarmPoolType, data: Farm[], userData: DsgFarmUserData[]): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const queryData = data || state.farms.data
    if (!queryData.length) return
    const pendings = queryData.map(async (item, index) => {
      // const nftsInfo = await fetchNftsInfo(item.additionalNft, ["1015"])
      const nftId = userData?.[index]?.additionalNftId
      const nftsInfo = await fetchNftsInfo(item.additionalNft, [nftId])
      return nftsInfo
    })
    const res = await Promise.all(pendings)
    dispatch(setLPFarmUserNftInfo({ data: res, farmPoolType }))
  }
export const fetchStakeNftsInfoAsync =
  (data?: NftFarm[]): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const queryData = data || state.farms.nftFarm.data
    if (!queryData.length) return
    const pending = queryData.map(async (item) => {
      const nftsInfo = await fetchNftsInfo(item.nft, item.userData.nfts)
      return nftsInfo
    })
    const res = await Promise.all(pending)
    dispatch(setNftsInfoPublicDataList(res))
  }
export const fetchSlotsNftsInfoAsync =
  (data?: NftFarm[]): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const queryData = data || state.farms.nftFarm.data
    if (!queryData.length) return
    const pending = queryData.map(async (item) => {
      const pendingSub = item.userData.slots.map(async (slot) => {
        const nftsInfo = await fetchNftsInfo(item.nft, slot.tokenIds)
        return {
          nftsInfo,
          index: slot.index,
        }
      })
      const resSub = await Promise.all(pendingSub)
      return resSub
    })
    const res = await Promise.all(pending)
    dispatch(setSlotsNftsInfoPublicDataList(res))
  }

export const fetchPublicDataAsyncWidthType =
  (account, farmPoolType: FarmPoolType): AppThunk =>
  async (dispatch) => {
    switch (farmPoolType) {
      case FarmPoolType.DSG_FARM:
        dispatch(fetchFarmsPublicDataList(account))
        break
      case FarmPoolType.LP_FARM:
        dispatch(fetchErc20FarmsPublicDataList(account))
        break
      case FarmPoolType.NFT_FARM:
        dispatch(fetchNftsFarmsPublicDataList(account))
        break
      case FarmPoolType.FTS_FARM:
        dispatch(fetchFfFarmsPublicDataListAsync(account))
        dispatch(fetchTfFarmsPublicDataListAsync(account))
        break
      case FarmPoolType.TF_FARM:
        dispatch(fetchTfFarmsPublicDataListAsync(account))
        break
      case FarmPoolType.FF_FARM:
        dispatch(fetchFfFarmsPublicDataListAsync(account))
        break
      case FarmPoolType.DSG_AND_INCENTTIVE_FARM:
        dispatch(fetchFarmsPublicDataList(account))
        dispatch(fetchIncentiveFarmragmentListAsync(account))
        break
      // case FarmPoolType.V1_FARM:
      //   dispatch(fetchV1FarmragmentListAsync(account))
        // break
      default:
        console.error(`fetchPublicDataAsyncWidthType: farmPoolType:${farmPoolType} is not a FarmPoolType type`)
        break
    }
  }


/*  createSlice */
export const farmsSlice = createSlice({
  name: 'Farms',
  initialState,
  reducers: {
    setLoadArchivedFarmsData: (state, action) => {
      const loadArchivedFarmsData = action.payload
      state.loadArchivedFarmsData = loadArchivedFarmsData
    },
    setPoolUserData: (state, action) => {
      // state.data = action.payload;
      const { data, farmPoolType } = action.payload
      let dataKey
      switch (farmPoolType) {
        case FarmPoolType.DSG_FARM:
          if (state.data.length !== data.length) break
          state.data = data.map((user) => {
            const oldFarmData = state.data.find((farmData) => farmData.pid === user.pid)
            return {
              ...oldFarmData,
              userData: { ...user },
            }
          })
          state.userDataLoaded = true
          break
        case FarmPoolType.FF_FARM:
        case FarmPoolType.TF_FARM:
        case FarmPoolType.INCENTIVE_FARM:
        case FarmPoolType.V1_FARM:
          if (farmPoolType === FarmPoolType.FF_FARM) {
            dataKey = 'ffFarm'
          } else if (farmPoolType === FarmPoolType.TF_FARM) {
            dataKey = 'tfFarm'
          } else if (farmPoolType === FarmPoolType.V1_FARM) {
            dataKey = 'v1Farm'
          } else {
            dataKey = 'incentiveFarm'
          }
          // dataKey = farmPoolType === FarmPoolType.FF_FARM ? 'ffFarm' : 'tfFarm'
          if (state[dataKey].data.length !== data.length) break
          state[dataKey].data = data.map((item) => {
            const oldFarmData = state[dataKey].data.find((farmData) => farmData.pid === item.pid)
            return {
              ...oldFarmData,
              userData: { ...item },
            }
          })
          break
        default:
          break
      }
    },
    setLPFarmUserNftInfo: (state, action) => {
      const { data } = action.payload
      if (state.data.length === data.length) {
        state.data = state.data.map((item, index) => {
          return {
            ...item,
            nftsInfo: data[index],
          }
        })
      }
      state.userDataLoaded = true
    },
    setFarmsPublicDataList: (state, action) => {
      const { data, farmPoolType } = action.payload
      let dataKey
      switch (farmPoolType) {
        case FarmPoolType.DSG_FARM:
          state.data = data.map((item) => {
            const oldFarmData = state.data.find((farmData) => farmData.pid === item.pid)
            return {
              ...oldFarmData,
              ...item,
            }
          })
          state.farmsDataLoaded = true
          break
        case FarmPoolType.FF_FARM:
        case FarmPoolType.TF_FARM:
        case FarmPoolType.INCENTIVE_FARM:
        case FarmPoolType.V1_FARM:
          if (farmPoolType === FarmPoolType.FF_FARM) {
            dataKey = 'ffFarm'
          } else if (farmPoolType === FarmPoolType.TF_FARM) {
            dataKey = 'tfFarm'
          } else if (farmPoolType === FarmPoolType.V1_FARM) {
            dataKey = 'v1Farm'
          } else {
            dataKey = 'incentiveFarm'
          }
          // dataKey = farmPoolType === FarmPoolType.FF_FARM ? 'ffFarm' : 'tfFarm'
          state[dataKey].data = data.map((item) => {
            const oldFarmData = state[dataKey].data.find((farmData) => farmData.pid === item.pid)
            return {
              ...oldFarmData,
              ...item,
            }
          })
          state[dataKey].dataLoaded = true
          break
        default:
          break
      }
      const tempPrice = {}
      data.forEach(item => {
        if (Number(item.quoteToken?.busdPrice)) {
          if ((item.quoteToken.symbol === 'MBT' && item.token.symbol === 'USDC') || item.quoteToken.symbol !== 'MBT') {
            tempPrice[item.quoteToken.token] = item.quoteToken?.busdPrice
            tempPrice[item.quoteToken.symbol] = item.quoteToken?.busdPrice
          }
        }
        if (Number(item.token?.busdPrice)) {
          if ((item.token.symbol === 'MBT' && item.quoteToken.symbol === 'USDC') || item.token.symbol !== 'MBT') {
            tempPrice[item.token.token] = item.token?.busdPrice
            tempPrice[item.token.symbol] = item.token?.busdPrice
          }
        }

      })
      
      state.tokenPrices = {
        ...state.tokenPrices,
        ...tempPrice,
      }
    },
    setAdditionalRates: (state, action) => {
      const { payload } = action
      state.levelRates = payload
    },
    setNftApprovedForAll: (state, action) => {
      const { payload } = action
      state.data = state.data.map((item) => {
        const newFarmData = payload.find((farmData) => farmData.additionalNft === item.additionalNft)
        return {
          ...item,
          ...newFarmData,
        }
      })
      state.farmsDataLoaded = true
    },
    setNftApprovedForAllWithType: (state, action) => {
      const { data, farmPoolType } = action.payload
      let dataKey
      switch (farmPoolType) {
        case FarmPoolType.DSG_FARM:
          state.data = state.data.map((item) => {
            const newFarmData = data.find((farmData) => farmData.additionalNft === item.additionalNft)
            return {
              ...item,
              ...newFarmData,
            }
          })
          state.farmsDataLoaded = true
          break
        case FarmPoolType.FF_FARM:
        case FarmPoolType.TF_FARM:
        case FarmPoolType.INCENTIVE_FARM:
        case FarmPoolType.V1_FARM:
          if (farmPoolType === FarmPoolType.FF_FARM) {
            dataKey = 'ffFarm'
          } else if (farmPoolType === FarmPoolType.TF_FARM) {
            dataKey = 'tfFarm'
          } else if (farmPoolType === FarmPoolType.V1_FARM) {
            dataKey = 'v1Farm'
          } else {
            dataKey = 'incentiveFarm'
          }
          state[dataKey].data = data.map((item) => {
            const newFarmData = data.find((farmData) => farmData.additionalNft === item.additionalNft)
            return {
              ...item,
              ...newFarmData,
            }
          })
          break
        default:
          break
      }
    },
    setErc20FarmsPublicDataList: (state, action) => {
      const { payload } = action
      state.erc20Farm.data = payload.map((item) => {
        const oldFarmData = state.erc20Farm.data.find((farmData) => farmData.pid === item.pid)
        return {
          ...oldFarmData,
          ...item,
        }
      })
      state.erc20Farm.dataLoaded = true
    },

    setNftsFarmsPublicDataList: (state, action) => {
      const { payload } = action
      state.nftFarm.data = payload.map((item) => {
        const oldFarmData = state.nftFarm.data.find((farmData) => farmData.pid === item.pid)
        return {
          ...oldFarmData,
          ...item,
        }
      })
      state.nftFarm.dataLoaded = true
    },

    setNftsInfoPublicDataList: (state, action) => {
      const { payload } = action
      if (state.nftFarm.data.length === payload.length) {
        state.nftFarm.data = state.nftFarm.data.map((item, index) => {
          return {
            ...item,
            nftsInfo: payload[index],
          }
        })
      }
    },

    setSlotsNftsInfoPublicDataList: (state, action) => {
      const { payload } = action
      if (state.nftFarm.data.length === payload.length) {
        state.nftFarm.data = state.nftFarm.data.map((item, index) => {
          return {
            ...item,
            slotsNftsInfo: payload[index],
          }
        })
        state.nftFarm.userLoaded = true
      }
    },

    setNftsFarmsPublicDataFees: (state, action) => {
      const { farmFees, farmRates, poolInfo } = action.payload
      if (state.nftFarm.data.length === farmFees.length) {
        state.nftFarm.data = state.nftFarm.data.map((item, index) => {
          return {
            ...item,
            enableSlotFee: farmFees[index],
            slotAdditionRate: farmRates[index],
            poolInfo: { ...poolInfo[index] },
          }
        })
      }
    },
    setPoolUserEarningsData: (state, action) => {
      const { payload } = action
      state.userData = {
        earnings: payload,
        dataLoaded: true,
      }
    },
    // setFarmsPublicDataFarms: (state, action) => {
    //   state.data = action.payload;
    // }
  },

  extraReducers: (builder) => {
    // Update farms with live data
    builder.addCase(fetchFarmsPublicDataAsync.fulfilled, (state, action) => {
      if (!state.data.length || state.data.length === action.payload.length) {
        state.data = action.payload
        state.farmsDataLoaded = true
      } else {
        state.data = state.data.map((farm) => {
          const liveFarmData = action.payload.find((farmData) => farmData.pid === farm.pid)
          const quoteToken =
            liveFarmData?.quoteToken && Number(liveFarmData?.quoteToken.busdPrice)
              ? { ...liveFarmData?.quoteToken }
              : farm.quoteToken
          const token =
            liveFarmData?.token && Number(liveFarmData?.token.busdPrice) ? { ...liveFarmData?.token } : farm.token
          return {
            ...farm,
            quoteToken,
            token,
          }
        })
      }
      const tempPrice = {}
      action.payload.forEach(item => {
        if (Number(item.quoteToken?.busdPrice)) {
          if ((item.quoteToken.symbol === 'MBT' && item.token.symbol === 'USDC') || item.quoteToken.symbol !== 'MBT') {
            tempPrice[item.quoteToken.token] = item.quoteToken?.busdPrice
            tempPrice[item.quoteToken.symbol] = item.quoteToken?.busdPrice
          }
        }
        if (Number(item.token?.busdPrice)) {
          if ((item.token.symbol === 'MBT' && item.quoteToken.symbol === 'USDC') || item.token.symbol !== 'MBT') {
            tempPrice[item.token.token] = item.token?.busdPrice
            tempPrice[item.token.symbol] = item.token?.busdPrice
          }
        }
      })
      state.tokenPrices = {
        ...state.tokenPrices,
        ...tempPrice,
      }
    })
    builder.addCase(fetchEarningVaultUserAsync.fulfilled, (state, action) => {
      const { payload } = action
      if (payload.length === state.data.length) {
        state.data = state.data.map((farm, index) => {
          return {
            ...farm,
            userData: {
              ...farm.userData,
              ...payload[index],
            },
          }
        })
      }
      let earnings = new BigNumber(0)
      payload.forEach((item) => {
        earnings = earnings.plus(item?.earnings || '0')
      })
      state.userData = {
        earnings: earnings.toString(),
        dataLoaded: true,
      }
    })
  },
})

// Actions
export const {
  setLoadArchivedFarmsData,
  setPoolUserData,
  setFarmsPublicDataList,
  setErc20FarmsPublicDataList,
  setNftsFarmsPublicDataList,
  setNftsFarmsPublicDataFees,
  setNftsInfoPublicDataList,
  setSlotsNftsInfoPublicDataList,
  setNftApprovedForAll,
  setNftApprovedForAllWithType,
  setLPFarmUserNftInfo,
  setAdditionalRates,
} = farmsSlice.actions

export default farmsSlice.reducer
