import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 1024 1024" {...props}>
      <path d="M512 1024c-284.444444 0-512-227.555556-512-512s227.555556-512 512-512 512 227.555556 512 512-227.555556 512-512 512z m0-68.266667c244.622222 0 443.733333-199.111111 443.733333-443.733333S756.622222 68.266667 512 68.266667 68.266667 267.377778 68.266667 512s199.111111 443.733333 443.733333 443.733333z m11.377778-694.044444c45.511111 0 85.333333 11.377778 113.777778 39.822222 28.444444 22.755556 45.511111 62.577778 45.511111 102.4 0 34.133333-11.377778 68.266667-28.444445 91.022222-5.688889 5.688889-28.444444 28.444444-62.577778 56.888889-11.377778 11.377778-22.755556 22.755556-28.444444 39.822222-5.688889 17.066667-11.377778 34.133333-11.377778 45.511112v11.377777H472.177778v-11.377777c0-28.444444 5.688889-51.2 17.066666-68.266667 11.377778-22.755556 39.822222-51.2 79.644445-91.022222l11.377778-11.377778c11.377778-17.066667 22.755556-34.133333 22.755555-51.2 0-22.755556-5.688889-45.511111-22.755555-56.888889-17.066667-17.066667-34.133333-22.755556-62.577778-22.755556-34.133333 0-56.888889 11.377778-73.955556 28.444445-11.377778 17.066667-17.066667 39.822222-17.066666 73.955555H352.711111c0-51.2 17.066667-96.711111 45.511111-125.155555 28.444444-34.133333 73.955556-51.2 125.155556-51.2zM512 688.355556c17.066667 0 28.444444 5.688889 39.822222 17.066666 11.377778 11.377778 17.066667 22.755556 17.066667 39.822222 0 17.066667-5.688889 28.444444-17.066667 39.822223-11.377778 11.377778-22.755556 17.066667-39.822222 17.066666-28.444444 0-51.2-22.755556-51.2-51.2 0-17.066667 5.688889-28.444444 17.066667-39.822222 5.688889-17.066667 22.755556-22.755556 34.133333-22.755555z" p-id="1908"></path>
    </Svg>
  );
};

export default Icon;
