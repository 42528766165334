
import { getLiquidityPoolContract } from 'utils/contractHelpers'
import BigNumber from 'bignumber.js'
import { simpleRpcProvider } from 'utils/providers'
import { Farm, FarmPoolType } from '../types'

// getNftMarketContract
// getNftMarketContract

interface CulFarm {
  [pid:string]: {
    pid: number,
    multiplierVal: string
  }
}

interface EveDonate {
  [pid:string]: BigNumber
}
/**
 * @dev 计算交易手续费分红年化率
 * @param farms 赚取MBT农场
 * @returns 返回Aprs[]
 */
export const getLpRewardsApr = async (farms: Farm[]) => {
  try {
    const culFarm = {}
    farms.forEach(item => {
      culFarm[item.pid] = {
        pid: item.pid,
        multiplierVal: item.multiplierVal
      }
    })
    const blockNumber = await simpleRpcProvider.getBlockNumber()
    const contract = getLiquidityPoolContract()
    const filterFrom = contract.filters.Donate()
    const eveSales = await contract.queryFilter(filterFrom, blockNumber - 1000, blockNumber)
    const eveSales1 = await contract.queryFilter(filterFrom, blockNumber - 2000, blockNumber - 1000)

    const res = {}
    eveSales.concat(eveSales1).reverse().forEach((item, index) => {
      if (index > 11) return
      if (res[item.args.pid.toNumber()]) {
        // res[item.args.pid.toNumber()] = res[item.args.pid.toNumber()].plus(item.args.realAmount.toJSON().hex)
      } else {
        res[item.args.pid.toNumber()] = new BigNumber(item.args.realAmount.toJSON().hex)
      }
    })
    const result = culAprHandle(culFarm, res)
    return result
  } catch (error) {
    console.error(error)
    return []
  }
}

const culAprHandle = (culFarm: CulFarm, eveSales: EveDonate) => {
  const culIds = Object.keys(culFarm)
  const eveIds = Object.keys(eveSales)
  let totalMul = new BigNumber(0)
  Object.values(culFarm).forEach(item => {
    totalMul = totalMul.plus(item.multiplierVal)
    // if (!eveIds.includes(`${item.pid}`)) {
    // }
  })
  const totalId = eveIds.find(item => !culIds.includes(item))
  return Object.values(culFarm).map(item =>{
    if (eveIds.includes(`${item.pid}`)) {
      return {
        ...item,
        fourRealAmount: eveSales[totalId]?.times(item.multiplierVal).div(totalMul).plus(eveSales[item.pid]).times(12).times(365).toString()
      }
    }
    return {
      ...item,
      fourRealAmount: eveSales[totalId]?.times(item.multiplierVal).div(totalMul).times(12).times(365).toString()
    }
  })
}
