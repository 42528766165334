import NftEarnErc20PoolAbi from 'config/abi/NftEarnErc20Pool.json'
import multicall from 'utils/multicall'
import { getNftEarnErc20PoolAddress } from 'utils/addressHelpers'

const NftEarnErc20Pools = [getNftEarnErc20PoolAddress()]

export const fetchNftEarnErc20List = async () => {
  try {
    const calls = NftEarnErc20Pools.map((pool) => ({
      address: pool,
      name: 'getPoolView', // Function name on the contract (example: balanceOf)
    }))
    const lists = await multicall(NftEarnErc20PoolAbi, calls)
    return lists.map((data, index) => {
      const item = data[0]
      return {
        pid: index,
        dsgToken: item.dsgToken, // dsg token 地址
        rewardToken: item.rewardToken, // dsg token 地址
        dsgDecimals: item.dsgDecimals, // dsg 小数位
        lastRewardBlock: item.lastRewardBlock.toJSON().hex, // 最近一次奖励的区块
        rewardsPerBlock: item.rewardsPerBlock.toJSON().hex, // 每区块奖励数
        accRewardPerShare: item.accRewardPerShare.toJSON().hex, // 每算力累积的奖励
        allocRewardAmount: item.allocRewardAmount.toJSON().hex, // 总待领取的奖励数
        accRewardAmount: item.accRewardAmount.toJSON().hex, // 累计奖励数
        totalAmount: item.totalAmount.toJSON().hex, // 总质押的nft数量
        nft: item.nft, // nft地址
        nftSymbol: item.nftSymbol, // nft标识
        contractAddress: NftEarnErc20Pools[index],
      }
    })
  } catch (error) {
    console.error(error)
    return []
  }
}

export const fetchNftEarnErc20Fee = async () => {
  try {
    const calls = NftEarnErc20Pools.map((pool) => ({
      address: pool,
      name: 'enableSlotFee', // Function name on the contract (example: balanceOf)
    }))
    const lists = await multicall(NftEarnErc20PoolAbi, calls)
    return lists.map((data) => {
      const item = data[0]
      return item.toJSON().hex
    })
  } catch (error) {
    return []
  }
}

export const fetchNftEarnErc20Rate = async () => {
  try {
    const calls = NftEarnErc20Pools.map((pool) => ({
      address: pool,
      name: 'slotAdditionRate', // Function name on the contract (example: balanceOf)
    }))
    const lists = await multicall(NftEarnErc20PoolAbi, calls)
    return lists.map((data) => {
      const item = data[0]
      return item.toJSON().hex
    })
  } catch (error) {
    return []
  }
}

export const fetchNftFramPoolInfo = async () => {
  try {
    const calls = NftEarnErc20Pools.map((pool) => ({
      address: pool,
      name: 'getPoolInfo', // Function name on the contract (example: balanceOf)
    }))
    const lists = await multicall(NftEarnErc20PoolAbi, calls)
    return lists.map((item) => {
      return {
        accShare_: item[0].toJSON().hex,
        accDsgTokenPerShare_: item[1].toJSON().hex,
        dsgTokenPerBlock_: item[2].toJSON().hex,
      }
    })
  } catch (error) {
    return []
  }
}
