import { useCallback } from 'react'
import { emergencyWithdrawPool } from 'utils/calls'
import {
  useSinglePoolForAddress,
} from 'hooks/useContract'

export const useEmergencyFarms = (lpAddress: string) => {
  const masterChefContract = useSinglePoolForAddress(lpAddress)
  const handleHarvest = useCallback(async () => {
    return emergencyWithdrawPool(masterChefContract)
  }, [masterChefContract])

  return { onEmergency: handleHarvest }
}
