import React from 'react'
import { useWeb3React } from '@web3-react/core'
import { Flex, Box, Language, LangSelector } from 'pancake-uikit'
import ConnectWalletButton from 'components/ConnectWalletButton'
import UIUserWallet from './UIUserWallet'

interface UserMenuProps {
  currentLang: string
  langs: Language[]
  setLang: (lang: Language) => void
}

const UserMenu: React.FC<UserMenuProps> = ({ langs, setLang, currentLang }) => {

  const { account } = useWeb3React()

  if (!account) {
    return (
      <Box className="hide-media-nav">
        <Flex padding="16px 12px" width="100%" justifyContent="space-between" alignItems="center">
          <ConnectWalletButton scale="sm" />
          <Box ml="15px">
            <LangSelector position="top-right" currentLang={currentLang} langs={langs} setLang={setLang} />
          </Box>
        </Flex>
      </Box>
    )
  }
  return (
    <Box className="hide-media-nav">
      <Flex padding="16px 12px" width="100%" justifyContent="space-between" alignItems="center">
        <Flex flex="1">
          <UIUserWallet />
        </Flex>
        <Box ml="24px">
          <LangSelector position="top-right" currentLang={currentLang} langs={langs} setLang={setLang} />
        </Box>
      </Flex>
    </Box>
  )
}

export default UserMenu
