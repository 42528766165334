import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg color='primary' viewBox="0 0 1024 1024" {...props}>
      <path d="M512.09152 224A352 352 0 1 1 160.09152 576 352 352 0 0 1 512.09152 224m0-96a448 448 0 1 0 448 448A448 448 0 0 0 512.09152 128zM226.01152 0A48.96 48.96 0 0 0 192.09152 14.08L14.17152 192a47.68 47.68 0 0 0 0 67.84 47.04 47.04 0 0 0 33.92 14.08 47.04 47.04 0 0 0 33.92-14.08l177.92-177.92A48 48 0 0 0 226.01152 0zM798.17152 0a48 48 0 0 0-33.92 81.92l177.92 177.92a48 48 0 0 0 67.84 0 47.68 47.68 0 0 0 0-67.84L832.09152 14.08A48.96 48.96 0 0 0 798.17152 0z" p-id="2826"></path><path d="M512.09152 308.48a48 48 0 0 0-48 48v224a48 48 0 0 0 96 0v-224a48 48 0 0 0-48-48z"  p-id="2827"></path><path d="M512.09152 533.44a48.32 48.32 0 0 0-33.92 14.08 47.68 47.68 0 0 0 0 67.84l160 158.4a48 48 0 0 0 67.84 0 47.68 47.68 0 0 0 0-67.84l-160-158.4a48.32 48.32 0 0 0-33.92-14.08z"  p-id="2828"></path>
    </Svg>
  );
};

export default Icon;
