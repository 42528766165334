import { NftOwnerState } from 'state/types'

export enum poolsSortType {
  HOT,
  APR,
  MULTIPLIER,
  EARNED,
  TOTAL,
}

const getSortOption = (t) => [
  {
    label: t('Hot'),
    value: poolsSortType.HOT,
  },
  {
    label: t('APR'),
    value: poolsSortType.APR,
  },
  // {
  //   label: t('Multiplier'),
  //   value: poolsSortType.MULTIPLIER,
  // },
  {
    label: t('Earned'),
    value: poolsSortType.EARNED,
  },
  {
    label: t('Total'),
    value: poolsSortType.TOTAL,
  },
]


export enum methods {
  POOLS_GET_SORT_OPTION = 'pools/getSortOption',
}

export default {
  [methods.POOLS_GET_SORT_OPTION]: getSortOption,
}
