import React, { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { Button, Flex, Heading, IconButton, AddIcon, MinusIcon, useModal } from 'pancake-uikit'
import { useLocation } from 'react-router-dom'
import Balance from 'components/Balance'
import { fetchFarmsPublicDataList } from 'state/pools'
import { useTranslation } from 'contexts/Localization'
import { useAppDispatch } from 'state'
import { FarmTypes } from 'config/constants/types'
import { Erc20FarmStakeView, FarmPoolType, NftInfo } from 'state/types'
import { fetchPublicDataAsyncWidthType, fetchErc20VaultUserAsync } from 'state/farms'
import { getBalanceAmount, getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance'
import DepositModal from '../DepositModal'
import WithdrawModal from '../WithdrawModal'
import { useUnstakeFarms } from '../../hooks/useUnstakeFarms'
import { useStakeFarms } from '../../hooks/useStakeFarms'
import { useEmergencyFarms } from '../../hooks/useEmergencyFarms'
// eslint-disable-next-line import/named
import { useHarvestFarmTx } from '../../hooks/useHarvestFarm'

// Erc20FarmStakeView

interface FarmCardActionsProps {
  stakedBalance: BigNumber
  tokenBalance: BigNumber
  depositTokenPriceBusd: BigNumber
  tokenName: string
  addLiquidityUrl: string
  lpAddress: string
  removed: boolean
  isStakeOnce?: boolean
  decimals?: number
}

const IconButtonWrapper = styled.div`
  display: flex;
  svg {
    width: 20px;
  }
`

const StakeAction: React.FC<FarmCardActionsProps> = ({
  stakedBalance,
  tokenBalance,
  tokenName,
  addLiquidityUrl,
  depositTokenPriceBusd,
  lpAddress,
  removed,
  isStakeOnce,
  decimals,
}) => {
  const { t } = useTranslation()
  const { onStake } = useStakeFarms(lpAddress)
  const { onUnstake } = useUnstakeFarms(lpAddress)
  const { onEmergency } = useEmergencyFarms(lpAddress)
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const lpPrice = new BigNumber(depositTokenPriceBusd || 0)
  // const lpPrice = useLpTokenPrice(tokenName)

  const handleStake = async (amount: string) => {
    await onStake(amount, decimals)
    dispatch(fetchFarmsPublicDataList(account))
  }


  const handleUnstake = async (amount: string) => {
    await onUnstake(amount, decimals)
    dispatch(fetchFarmsPublicDataList(account))
  }


  const displayBalance = useCallback(() => {
    const stakedBalanceBigNumber = getBalanceAmount(stakedBalance, decimals)
    if (stakedBalanceBigNumber.gt(0) && stakedBalanceBigNumber.lt(0.0000001)) {
      return stakedBalanceBigNumber.toFixed(10, BigNumber.ROUND_DOWN)
    }
    if (stakedBalanceBigNumber.gt(0) && stakedBalanceBigNumber.lt(0.0001)) {
      return getFullDisplayBalance(stakedBalance, decimals).toLocaleString()
    }
    return stakedBalanceBigNumber.toFixed(3, BigNumber.ROUND_DOWN)
  }, [stakedBalance, decimals])

  const [onPresentDeposit] = useModal(
    <DepositModal max={tokenBalance} decimals={decimals} onConfirm={handleStake} tokenName={tokenName} addLiquidityUrl={addLiquidityUrl} />,
  )


  const onPresentDepositHandle = useCallback(() => {
    onPresentDeposit()
  }, [onPresentDeposit])

  const [onPresentWithdraw] = useModal(
    <WithdrawModal max={stakedBalance} decimals={decimals} onConfirm={handleUnstake} tokenName={tokenName} />,
  )

  const MinusHandle = useCallback(async () => {
    if (lpAddress === '0x1bceCBa533780f5ac3a76867255EA4A7e824B069') {
      const tx = await onEmergency()
      await tx.wait()
    } else {
      onPresentWithdraw()
    }
    dispatch(fetchFarmsPublicDataList(account))

  }, [account, dispatch, onEmergency, onPresentWithdraw, lpAddress])

  const renderStakingButtons = () => {
    return stakedBalance.eq(0) ? (
      <Button onClick={onPresentDeposit} disabled={removed}>
        {t('Stake')}
      </Button>
    ) : (
      <IconButtonWrapper>
        <IconButton variant="tertiary" onClick={MinusHandle} mr="6px">
          <MinusIcon color="primary" width="14px" />
        </IconButton>
        {
          !isStakeOnce && (
            <IconButton variant="tertiary" onClick={onPresentDeposit} disabled={removed}>
              <AddIcon color="primary" width="14px" />
            </IconButton>
          )
        }
      </IconButtonWrapper>
    )
  }

  return (
    <Flex mt="8px" justifyContent="space-between" alignItems="center">
      <Flex flexDirection="column" alignItems="flex-start">
        <Heading color={stakedBalance.eq(0) ? 'textDisabled' : 'text'}>{displayBalance()}</Heading>
        {stakedBalance.gt(0) && lpPrice.gt(0) && (
          <Balance
            fontSize="12px"
            color="textSubtle"
            decimals={2}
            value={getBalanceNumber(lpPrice.times(stakedBalance), decimals)}
            unit=" USD"
            prefix="~"
          />
        )}
      </Flex>
      {renderStakingButtons()}
    </Flex>
  )
}

export default StakeAction
