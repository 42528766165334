import { useTranslation } from 'contexts/Localization'
import { ChainId, contractAddress } from 'dsgswap-sdk'
import { useMemo } from 'react'
import { getAddress } from 'utils/addressHelpers'
import { Address } from './types'



export enum DsgNftType {
  FOOD_NFT,
  MD_NFT,
  MYSTERY_NFT,
}

export interface DsgNftFactory {
  address: Address
  label: string
  dsgNftType?: DsgNftType
  canUpgrade?: boolean
}
export interface DsgNftFactorys {
  foodNft: DsgNftFactory
  mdNft: DsgNftFactory
  mysteryNft: DsgNftFactory
  [elem: string]: DsgNftFactory
}

export const dsgNftFactorys: DsgNftFactorys = {
  foodNft: {
    address: {
      ...contractAddress.DsgFoodNft
    },
    label: 'Magic potion',
    dsgNftType: DsgNftType.FOOD_NFT,
    canUpgrade: false,
  },
  mdNft: {
    address: {
      ...contractAddress.DsgMdNft

    },
    label: 'Magic crystal',
    dsgNftType: DsgNftType.MD_NFT,
    canUpgrade: true,
  },
  mysteryNft: {
    address: {
      ...contractAddress.MysteryBox,
    },
    label: 'Mystery Box',
    dsgNftType: DsgNftType.MYSTERY_NFT,
    canUpgrade: false,
  },
}
// export const canUpgradeNfts = ['0x9d5766b810386049d3f0d4f5413c00a26cd4b0e0']
export const canUpgradeNfts: string[] = (() => {
  const res = []
  Object.values(dsgNftFactorys).forEach(item => {
    if (item.canUpgrade) {
      res.push(getAddress(item.address))
    }
  })
  return res
})()


export const getNftFactoryByAddress = (address: string): DsgNftFactory => {
  return Object.values(dsgNftFactorys).find((item) => getAddress(item.address).toLowerCase() === address?.toLowerCase())
}

export const checkTheNftIsMystery = (address: string): boolean => {
  if (address?.toLowerCase() === getAddress(dsgNftFactorys.mysteryNft.address).toLowerCase()) return true
  return false
}

type NFTDesc = Array<Array<string>>

const baseMhdDesc = [
  'LV %level% Magic crystal,with %power% power.',
  'The crystals that magicians dream of can automatically attract all the exotic treasures around them. According to some wise old magician, the top crystals are refined from fossilised dinosaurs.'
]

export const moneyHungryDionDesc: NFTDesc = [
  [],
  baseMhdDesc,
  baseMhdDesc,
  baseMhdDesc,
  baseMhdDesc,
  baseMhdDesc,
  baseMhdDesc,
  // [
  //   'LV %level% Magic crystal,with %power% power.',
  //   'The crystals that magicians dream of can automatically attract all the exotic treasures around them. According to some wise old magician, the top crystals are refined from fossilised dinosaurs.'
  // ],
  // [
  //   'LV 2 Magic crystal,with 2 power.',
  //   'The crystals that magicians dream of can automatically attract all the exotic treasures around them. According to some wise old magician, the top crystals are refined from fossilised dinosaurs.'
  // ],
  // [
  //   'LV 3 Magic crystal,with 3 power.',
  //   'The crystals that magicians dream of can automatically attract all the exotic treasures around them. According to some wise old magician, the top crystals are refined from fossilised dinosaurs.'
  // ],
  // [
  //   'LV 4 Magic crystal,with 4 power.',
  //   'The crystals that magicians dream of can automatically attract all the exotic treasures around them. According to some wise old magician, the top crystals are refined from fossilised dinosaurs.'
  // ],
  // [
  //   'LV 5 Magic crystal,with 5 power.',
  //   'The crystals that magicians dream of can automatically attract all the exotic treasures around them. According to some wise old magician, the top crystals are refined from fossilised dinosaurs.'
  // ],
  // [
  //   'LV 6 Magic crystal,with 6 power.',
  //   'The crystals that magicians dream of can automatically attract all the exotic treasures around them. According to some wise old magician, the top crystals are refined from fossilised dinosaurs.'
  // ],
]
const PotionDesc = [
  "Lv %level% potion, can boost %rate%% of LP yield. Magic potion, the magician can drink the magic potion to improve the output speed of magic balls",
]
export const meatDesc: NFTDesc = [
  [],
  PotionDesc,
  PotionDesc,
  PotionDesc,
  PotionDesc,
  PotionDesc,
  PotionDesc,
]

export const eggMysteryDesc: NFTDesc = [
  [
    'Crystal Mystery Box：Open the crystal mystery box to get level 1-6 crystals',
    'The crystals that magicians dream of can automatically attract all the exotic treasures around them. According to some wise old magician, the top crystals are refined from fossilised dinosaurs.'
  ]
]

export const meatMysteryDesc: NFTDesc = [
  [
    'Potion Mystery Box：Open the potion mystery box to get level 1-6 potions'
  ]
]

const getNftDesc = (token: string, nft?: string) => {
  const factory = getNftFactoryByAddress(token)
  let nftFactory = null
  if (!factory) return null
  switch (factory.dsgNftType) {
    case DsgNftType.MYSTERY_NFT:
      nftFactory = getNftFactoryByAddress(nft)
      switch (nftFactory.dsgNftType) {
        case DsgNftType.FOOD_NFT:
          return meatMysteryDesc
        case DsgNftType.MD_NFT:
          return eggMysteryDesc
        default:
          return null
      }
    case DsgNftType.FOOD_NFT:
      return meatDesc
    case DsgNftType.MD_NFT:
      return moneyHungryDionDesc
    default:
      return null
  }
}

export const useNftDesc = (token, level, nft, power, levelRates) => {
  const { t } = useTranslation()
  return useMemo(() => {
    if (!token) return []
    const desc = getNftDesc(token, nft)
    if (!desc) return []
    return desc[level || 0].map((item) => t(item, { power, level, rate: levelRates[level] || '--' }))
  }, [token, level, nft, power, levelRates, t])
}