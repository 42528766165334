import { ChainId } from './chainId'
import tokenList from './default.tokenlist.json'
import { formatTokenList } from './utils'

export const contractAddress = {
  masterChef: {
    [ChainId.MATIC_TESTNET]: '0x675e77aeb7F50CAbCE65B9d7114aeB402557679f',
    [ChainId.MATIC_MAINNET]: '0x675e77aeb7F50CAbCE65B9d7114aeB402557679f',
    [ChainId.MAINNET]: '0x675e77aeb7F50CAbCE65B9d7114aeB402557679f',
  },
  sousChef: {
    [ChainId.MATIC_TESTNET]: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    [ChainId.MATIC_MAINNET]: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    [ChainId.MAINNET]: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  multiCall: {
    [ChainId.MATIC_TESTNET]: '0x29723121199A25C446A36e5E8D0A7dcF1ac5291B',
    [ChainId.MAINNET]: '0x29723121199A25C446A36e5E8D0A7dcF1ac5291B',
    [ChainId.MATIC_MAINNET]: '0x4BaCa75ee473e6fc474EB1023ddEB7668E53430C',
  },
  cakeVault: {
    [ChainId.MATIC_TESTNET]: '0xa80240Eb5d7E05d3F250cF000eEc0891d00b51CC',
    [ChainId.MAINNET]: '0xa80240Eb5d7E05d3F250cF000eEc0891d00b51CC',
    [ChainId.MATIC_MAINNET]: '',
  },
  oracle: {
    [ChainId.MATIC_TESTNET]: '0xd2c250e7967edbe8812f91a86982e0e1d4d7d88e',
    [ChainId.MAINNET]: '0x79549758bdd539a19cd4e918989a424a6530af49',
    [ChainId.MATIC_MAINNET]: '0x7c9F923Aac8a7e3270A516A215fC3eb25Eb37B6c',
  },
  aggregator: {
    [ChainId.MATIC_TESTNET]: '0x0637b5a1aa60ebb293b9ab512b7d2ef136ac38bb',
    [ChainId.MAINNET]: '0xdf6e0fdf55aaffe6a5ee6902a55179cfa6cde93e',
    [ChainId.MATIC_MAINNET]: '0x07Aa21e18aF013d29F72A4bC8A00D9fA46A3603E',
  },
  liquidityPool: {
    [ChainId.MATIC_TESTNET]: '0x9165628fa4f59ec72da254a32d053d22afd05997',
    [ChainId.MAINNET]: '0x219be861274f5b4c06a657566e0c49ec408b19fd',
    [ChainId.MATIC_MAINNET]: '0x7a9185267E32726f436fE6c6823b5D3e11b026f5',
  },
  tradingPool: {
    [ChainId.MATIC_TESTNET]: '0xf511b83b13a6b94d02da243d08363ad4c585ea76',
    [ChainId.MAINNET]: '0x0ab66f50a8bda45f2c5621b92c788f4903c0c7bd',
    [ChainId.MATIC_MAINNET]: '0xc7497F36B96b95613F1Efe63Bc01c124Ba764a19',
  },
  vDSGToken: {
    [ChainId.MATIC_TESTNET]: '0xab7da5b9340abc67270d9f50dc6f174a9ee10368',
    [ChainId.MAINNET]: '0xc9e05fc26a7cf9843b013d3b9974eb6ef97e8e48',
    [ChainId.MATIC_MAINNET]: '0x8E627d70354C229Bea160E1045DC6351372a67c7',
  },
  NftEarnErc20Pool: {
    [ChainId.MATIC_TESTNET]: '0xa695a0c8c2c382b5f5f8825ca1e29e32659940b1',
    [ChainId.MAINNET]: '0xe266dce6f3fc8634e24251390b617215b693064a',
    [ChainId.MATIC_MAINNET]: '0x7264794d9E8465af4926d7Eb295f84efe1977203',
  },
  Erc20EarnNftPool: {
    [ChainId.MATIC_TESTNET]: '0x5c29cdc538b4fb05dc056082b89872f94db3cce0',
    [ChainId.MAINNET]: '0xbe16b278b319bb77d5eb38ca2f85bf375beb3639',
    [ChainId.MATIC_MAINNET]: '0x9126Fa2B97FA71E89A08513CB9dc94d5281f8574',
  },
  nftMarket: {
    [ChainId.MATIC_TESTNET]: '0x34a806874dfedacfbf86b11317a2ab2bba33cb63',
    [ChainId.MAINNET]: '0x39b3159fd27d9cdaabcb0862eccec06a24133592',
    [ChainId.MATIC_MAINNET]: '0x0dd631571c553f4BC23633c4A1e65E9b3bed5497',
  },
  TFragmentPool: {
    [ChainId.MATIC_TESTNET]: '0x4b3198a5f89625647cca9b26908ce9878e4e3c69',
    [ChainId.MAINNET]: '0x86cae2e9ea49a31980a14b0f49a81dfc73e03b4c',
    [ChainId.MATIC_MAINNET]: '0x0722cF64083EC68E90B8403Ff8Ecf4Fb91140DBd',
  },
  LiquidityPoolOther: {
    [ChainId.MATIC_TESTNET]: '0x4ae66ff652cebaac99faf2fb947e9e8621ad0d93',
    [ChainId.MAINNET]: '0xfBD1e13D0611693561C23a84d8C52b3D7cb0c070',
    [ChainId.MATIC_MAINNET]: '0xC44D1D0670815E451E5035Cdd5439aCe80A1Cd65',
  },
  liquidityV1Pool: {
    [ChainId.MATIC_TESTNET]: '0x219be861274f5b4c06a657566e0c49ec408b19fd',
    [ChainId.MAINNET]: '0x219be861274f5b4c06a657566e0c49ec408b19fd',
    [ChainId.MATIC_MAINNET]: '0x62A4b8cD2D46E4541AeF12352664a5cb74ecA61F',
  },
  FFragmentPool: {
    [ChainId.MATIC_TESTNET]: '0x86e89bee3ab249ff2b12a182bdfb70ab1f8cd189',
    [ChainId.MAINNET]: '0x947d20d51a8b27798d00fc62edb0a38325cc2340',
    [ChainId.MATIC_MAINNET]: '0x7BDc160b2ab2E39245D46dDBbE6CC78f9f2B3588',
  },
  MysteryBox: {
    [ChainId.MATIC_TESTNET]: '0x7504dc3edf73cda3acaafcdfe2561b5fe8ead2f8',
    [ChainId.MAINNET]: '0xc5d72d78bc378355c607f6ad9c0e4b491a31f962',
    [ChainId.MATIC_MAINNET]: '0x68bdfd72410e4DD39438057e40470b2C0E867003',
  },
  DsgFoodNft: {
    [ChainId.MATIC_TESTNET]: '0x8150aad64f811f272e02f82b4dd52546eeff35f5',
    [ChainId.MAINNET]: '0xed2c000c1ff42d4e67876eee4fd247053b0a9bdc',
    [ChainId.MATIC_MAINNET]: '0x3056e5Eabb081672070ef0Ad9A53237F8f819B6b',
  },
  DsgMdNft: {
    [ChainId.MATIC_TESTNET]: '0xcf31a0abe9a026166a66b12ef207e959eb95ed7c',
    [ChainId.MAINNET]: '0xed2c000c1ff42d4e67876eee4fd247053b0a9bdc',
    [ChainId.MATIC_MAINNET]: '0x8d495C8A10C89Bc0e24d667280F9FC55e380A300',
  },
  DsgNftFactorys: {
    [ChainId.MATIC_TESTNET]: '0xa3b8b055324c6dd750495a7688e466640f8ad5fe',
    [ChainId.MAINNET]: '0xed2c000c1ff42d4e67876eee4fd247053b0a9bdc',
    [ChainId.MATIC_MAINNET]: '0x6A2ccE44e5cAB492C26DCAcbDEc4DA4313b7ED16',
  },
  SinglePoolFactory: {
    [ChainId.MATIC_TESTNET]: '0x55be7890cdd37c84509d022820e95e289fb4bf60',
    [ChainId.MAINNET]: '0x367588beb726f33b6eac52628caacd96af253848',
    [ChainId.MATIC_MAINNET]: '0x74D612bD9f4D77D5AF87dF5CCD7542906782f712',
  },
  vDsgAirdrop: {
    [ChainId.MATIC_TESTNET]: '0x25743f3a73d63c407a381a22d8705751ff97a8f5',
    [ChainId.MAINNET]: '0xB31d0faf058C7578cee6212Afe8E6DA58e20b4f7',
    [ChainId.MATIC_MAINNET]: '0x1387cD29bcBe444D70B6c31CA31a2D006ac42d5b',
  },
  SwapRouter: {
    [ChainId.MATIC_TESTNET]: '0xa89628c4d8d3eb60892eb90dbbf4b4e18f256831',
    [ChainId.MAINNET]: '0xd7640e400ebf44cb8d5803e7a34d936d294a3fc5',
    [ChainId.MATIC_MAINNET]: '0xD0b5335BE74480F9303B88f5B55ACD676598882A',
  },
  SwapFactory: {
    [ChainId.MATIC_TESTNET]: '0x72f3e913159399fb4f0dc18974a90791aa595bc3',
    [ChainId.MAINNET]: '0xd7640e400ebf44cb8d5803e7a34d936d294a3fc5',
    [ChainId.MATIC_MAINNET]: '0xf08aE17C2A2728a788bb1b6C243fe7eb3E5bBaDc',
  },
  pairCodeHash: {
    [ChainId.MATIC_TESTNET]: '0x32890cd19dfef8a8745fd19c794ab655c6d1ee5ab4cd7af4e9312327ed7ee208',
    [ChainId.MAINNET]: '0x772d76e02b5a3aeeef00b07a18a744c893227f52ecb6e3d431da85a229ed6a1d',
    [ChainId.MATIC_MAINNET]: '0x32890cd19dfef8a8745fd19c794ab655c6d1ee5ab4cd7af4e9312327ed7ee208',
  },
}


export const EmptyAddress = {
  [ChainId.MATIC_TESTNET]: '',
  [ChainId.MATIC_MAINNET]: '',
  [ChainId.MAINNET]: '',
}
export const DSG_TOKENS_TOP100 = {
  [ChainId.MATIC_TESTNET]: 'https://mbt.api.siliconoffice.com/tokens/tokens-top100.json',
  [ChainId.MATIC_MAINNET]: 'https://mbt.api.siliconoffice.com/tokens/tokens-top100.json',
  [ChainId.MAINNET]: 'https://mbt.api.siliconoffice.com/tokens/tokens-top100.json',
}
export const DSG_TOKENS_EXTENDED = {
  [ChainId.MATIC_TESTNET]: 'https://mbt.api.siliconoffice.com/tokens/tokens.json',
  [ChainId.MATIC_MAINNET]: 'https://mbt.api.siliconoffice.com/tokens/tokens.json',
  [ChainId.MAINNET]: 'https://mbt.api.siliconoffice.com/tokens/tokens.json',
}

export const BASE_BSC_SCAN_URLS = {
  [ChainId.MAINNET]: 'https://polygonscan.com',
  [ChainId.MATIC_MAINNET]: 'https://polygonscan.com/',
  [ChainId.MATIC_TESTNET]: 'https://mumbai.polygonscan.com/',
  [ChainId.TESTNET]: 'https://mumbai.polygonscan.com',
}

export const ETHEREUM_CHAIN = {
  [ChainId.MAINNET]: {
    chainId: `0x${ChainId.MAINNET.toString(16)}`,
    chainName: 'Binance Smart Chain Mainnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'bnb',
      decimals: 18,
    },
    rpcUrls: [
      'https://bsc-dataseed1.ninicoin.io',
      'https://bsc-dataseed1.defibit.io',
      'https://bsc-dataseed.binance.org',
    ],
    blockExplorerUrls: [`${BASE_BSC_SCAN_URLS[ChainId.MAINNET]}/`],
  },
  [ChainId.MATIC_TESTNET]: {
    chainId: `0x${ChainId.MATIC_TESTNET.toString(16)}`,
    chainName: 'Polygon PoS Chain Testnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: [
      'https://matic-mumbai.chainstacklabs.com/',
      'https://matic-testnet-archive-rpc.bwarelabs.com/',
      'https://rpc-mumbai.maticvigil.com/',
      'https://polygon-mumbai.infura.io/v3/330472ed44dd4692a16dfcb4cc41f122',
    ],
    blockExplorerUrls: [`${BASE_BSC_SCAN_URLS[ChainId.MATIC_TESTNET]}/`],
  },
  [ChainId.MATIC_MAINNET]: {
    chainId: `0x${ChainId.MATIC_MAINNET.toString(16)}`,
    chainName: 'Polygon PoS Chain Mainnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: [
      'https://polygon-rpc.com/',
      // 'https://rpc-mainnet.matic.network/',
      'https://rpc-mainnet.maticvigil.com',
      'https://rpc-mainnet.matic.quiknode.pro',
    ],
    blockExplorerUrls: [`${BASE_BSC_SCAN_URLS[ChainId.MATIC_MAINNET]}/`],
  },
}

export const THEGRAPH_API_MEMBER = {
  [ChainId.MAINNET]: `https://api.thegraph.com/subgraphs/name/dinosaur-eggs/vdsg`,
  [ChainId.MATIC_TESTNET]: `https://api.thegraph.com/subgraphs/name/magicballswap/vbmtts`,
  [ChainId.MATIC_MAINNET]: `https://api.thegraph.com/subgraphs/name/magicballswap/vbmt`,
  [ChainId.TESTNET]: `https://api.thegraph.com/subgraphs/name/vbm290/vdsgts`,
}
export const THEGRAPH_API_SWAP = {
  [ChainId.MAINNET]: `https://api.thegraph.com/subgraphs/name/dinosaur-eggs/swap`,
  [ChainId.MATIC_TESTNET]: `https://api.thegraph.com/subgraphs/name/magicballswap/swapts`,
  [ChainId.MATIC_MAINNET]: `https://api.thegraph.com/subgraphs/name/magicballswap/swap`,
  [ChainId.TESTNET]: `https://api.thegraph.com/subgraphs/name/vbm290/swapts`,
}

export const BASE_URL = {
  [ChainId.MAINNET]: 'http://192.168.101.110:8300',
  [ChainId.MATIC_TESTNET]: 'https://testapi.siliconoffice.com',
  [ChainId.MATIC_MAINNET]: 'https://mbt.api.siliconoffice.com',
  [ChainId.TESTNET]: 'http://192.168.101.110:8300',
}

export const POLY_BASE_URL = {
  [ChainId.MAINNET]: 'https://poly.dsgmetaverse.com/v3.0',
  [ChainId.MATIC_TESTNET]: 'https://poly.dsgmetaverse.com/v3.0',
  [ChainId.MATIC_MAINNET]: 'https://poly.dsgmetaverse.com/v3.0',
  [ChainId.TESTNET]: 'https://poly.dsgmetaverse.com/v3.0',
}
