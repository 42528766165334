import { useCallback } from 'react'
import { harvestPool } from 'utils/calls'
import {
  useSinglePoolForAddress,
} from 'hooks/useContract'

export const useHarvestFarmTx = (lpAddress: string) => {
  const masterChefContract = useSinglePoolForAddress(lpAddress)
  const handleHarvest = useCallback(async () => {
    return harvestPool(masterChefContract)
  }, [masterChefContract])

  return { onReward: handleHarvest }
}
