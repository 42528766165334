import { ETHER, WETHER, ChainId, Token } from "dsgswap-sdk";

const formatWETH = (address: string) => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  const token: Token = WETHER[chainId] ? WETHER[chainId] : WETHER[ChainId.MAINNET]
  if (token.address.toUpperCase() === address?.toUpperCase()) return ETHER.symbol
  return null
}

export default formatWETH
