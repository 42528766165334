import BigNumber from 'bignumber.js'
import sousChefABI from 'config/abi/sousChef.json'
import wbnbABI from 'config/abi/weth.json'
import tradingPoolABI from 'config/abi/tradingPool.json'
import multicall from 'utils/multicall'
import { getAddress, getWbnbAddress, getTradingPoolAddress } from 'utils/addressHelpers'
import { BIG_ZERO } from 'utils/bigNumber'


export const fetchPoolsDtaList = async () => {
  try {
    const calls = [
      {
        address: getTradingPoolAddress(),
        name: 'getAllPoolViews', // Function name on the contract (example: balanceOf)
      },
    ]

    const [data] = await multicall(tradingPoolABI, calls)
    return data[0]
  } catch (error) {
    return []
  }
}
