import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { Button, Flex, Text, InputPanel } from 'pancake-uikit'
import { SWAP_TOKEN } from 'dsgswap-sdk'
import { getAddress, getToken } from 'utils/addressHelpers'
import { useAppDispatch } from 'state'
import { fetchVaultUserAsync } from 'state/pools'
import { FarmTypes } from 'config/constants/types'
import { Farm, NftInfo, SinglePoolView } from 'state/types'
import { useTranslation } from 'contexts/Localization'
import Dots from 'components/Loader/Dots'
import CircleLoader from 'components/Loader/CircleLoader'
import Loading from 'components/Loading'
import { useERC20 } from 'hooks/useContract'
import ConnectWalletButton from 'components/ConnectWalletButton'
import StakeAction from './StakeAction'
import HarvestAction from './HarvestAction'
import useApproveFarm from '../../hooks/useApproveFarm'


const Action = styled.div`
  padding-top: 16px;
`
export interface FarmWithStakedValue extends SinglePoolView {
  apr?: number
  rewardsTokenPriceBusd?: BigNumber
  depositTokenPriceBusd?: BigNumber

}

interface FarmCardActionsProps {
  farm: FarmWithStakedValue
  account?: string
  addLiquidityUrl?: string
  nftInfo?: NftInfo
  removed?: boolean
  userDataLoaded?: boolean
  onLockClick?: () => void
  isLock?: boolean
  hideLock?: boolean
}

const CardActions: React.FC<FarmCardActionsProps> = ({
  farm,
  account,
  nftInfo,
  addLiquidityUrl,
  removed,
  userDataLoaded,
  onLockClick,
  isLock,
  hideLock,
}) => {
  const { t } = useTranslation()
  const [requestedApproval, setRequestedApproval] = useState(false)
  const {
    allowance: allowanceAsString = 0,
    tokenBalance: tokenBalanceAsString = 0,
    stakedBalance: stakedBalanceAsString = 0,
    earnings: earningsAsString = 0,
  } = farm.userData || {}
  const allowance = new BigNumber(allowanceAsString)
  const tokenBalance = new BigNumber(tokenBalanceAsString)
  const stakedBalance = new BigNumber(stakedBalanceAsString)
  const earnings = new BigNumber(earningsAsString)
  const isApproved = account && allowance && allowance.isGreaterThan(0)
  const dispatch = useAppDispatch()

  // const lpContract = useERC20(farm.depositToken)
  const lpContract = useERC20(farm.depositToken || getToken(SWAP_TOKEN).address)


  const { onApprove } = useApproveFarm(lpContract, farm.poolAddress)

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      await onApprove()
      dispatch(fetchVaultUserAsync(account))
      setRequestedApproval(false)
    } catch (e) {
      console.error(e)
    }
  }, [onApprove, dispatch, account])

  const renderApprovalOrStakeButton = () => {
    return isApproved ? (
      <StakeAction
        stakedBalance={stakedBalance}
        tokenBalance={tokenBalance}
        tokenName={farm.depositSymbol}
        lpAddress={farm.poolAddress}
        decimals={Number(farm.depositDecimals)}
        isStakeOnce={farm.isStakeOnce}
        depositTokenPriceBusd={farm.depositTokenPriceBusd}
        addLiquidityUrl={addLiquidityUrl}
        removed={removed}
      />
    ) : (
      <Flex justifyContent="flex-end">
        <Button mt="8px" disabled={requestedApproval || removed} isLoading={!userDataLoaded} onClick={handleApprove}>
          {
            requestedApproval
            ?
            <Dots>{t('Enabling')}</Dots>
            :
            t('Enable')
          }
          {!userDataLoaded && <CircleLoader stroke="white" />}
        </Button>
      </Flex>
    )
  }

  return (
    <Action>
      <InputPanel>
        <HarvestAction
          lpAddress={farm.poolAddress}
          rewardToken={farm.rewardsToken}
          rewardsTokenPriceBusd={farm.rewardsTokenPriceBusd}
          earnings={earnings}
          decimals={Number(farm.rewardsDecimals)}
          removed={removed}
        />
      </InputPanel>
      <InputPanel mt="16px">
        <Flex pb="8px" justifyContent="space-between">
          <Flex>
            <Text bold textTransform="uppercase" fontSize="12px" pr="4px">
              {farm.depositSymbol}
            </Text>
            <Text bold textTransform="uppercase" fontSize="12px">
              {t('Staked')}
            </Text>
          </Flex>
          {/* <Button>加仓</Button> */}
        </Flex>
        {!account ? <ConnectWalletButton mt="8px" width="100%" /> : renderApprovalOrStakeButton()}
      </InputPanel>
    </Action>
  )
}

export default CardActions
