import { MenuEntry, DropdownMenuItemType } from 'pancake-uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Home'),
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: t('Trade'),
    icon: 'TradeIcon',
    href: '/swap',
    // items: [
    //   {
    //     label: t('Exchange'),
    //     href: '/swap',
    //   },
    //   {
    //     label: t('Liquidity'),
    //     href: '/pool',
    //   },
    //   {
    //     label: t('LP Migration'),
    //     href: 'https://v1exchange.pancakeswap.finance/#/migrate',
    //   },
    // ],
  },
  {
    label: t('Liquidity'),
    icon: 'LiquidityIcon',
    href: '/liquidity',
  },
  {
    label: t('Pterosaur Farm'),
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: t('Magic house'),
    icon: 'NestIcon',
    href: '/nest',
  },
  {
    label: t('Mall'),
    icon: 'MallIcon',
    href: '/mall',
  },
  {
    label: t('NFT'),
    icon: 'CardIcon',
    items: [
      {
        label: t('Magic crystal'),
        href: '/nftfarm',
        icon: 'CoinIcon',
      },
      {
        label: t('NFT Market'),
        href: '/nft',
        icon: 'MarketIcon',
      },
    ],
  },
  // {
  //   label: t('Pools'),
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: t('Prediction (BETA)'),
  //   icon: 'PredictionsIcon',
  //   href: '/prediction',
  // },
  // {
  //   label: t('Lottery'),
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  //   status: {
  //     text: t('Win').toLocaleUpperCase(),
  //     color: 'success',
  //   },
  // },
  // {
  //   label: t('Collectibles'),
  //   icon: 'NftIcon',
  //   href: '/collectibles',
  // },
  // {
  //   label: t('Team Battle'),
  //   icon: 'TeamBattleIcon',
  //   href: '/competition',
  // },
  // {
  //   label: t('Teams & Profile'),
  //   icon: 'GroupsIcon',
  //   items: [
  //     {
  //       label: t('Leaderboard'),
  //       href: '/teams',
  //     },
  //     {
  //       label: t('Task Center'),
  //       href: '/profile/tasks',
  //     },
  //     {
  //       label: t('Your Profile'),
  //       href: '/profile',
  //     },
  //   ],
  // },
  // {
  //   label: t('Info'),
  //   icon: 'InfoIcon',
  //   href: 'https://pancakeswap.info',
  // },
  // {
  //   label: t('Info'),
  //   icon: 'IfoIcon',
  //   href: '/ifo',
  // },
  {
    label: t('Trading'),
    icon: 'MiningIcon',
    href: '/trading',
  },
  {
    label: t('Member'),
    icon: 'MemberIcon',
    href: '/vmbt',
  },
  {
    label: t('Airdrop'),
    icon: 'AirIcon',
    href: '/airdrop',
  },
  {
    label: t('Info'),
    icon: 'InfoIcon',
    href: 'https://mbt.info.siliconoffice.com',
    type: DropdownMenuItemType.EXTERNAL_LINK,
  },
  {
    label: t('More'),
    icon: 'MoreIcon',
    items: [
      // {
      //   label: t('Bug bounty'),
      //   href: 'https://www.immunefi.com/bounty/dinosaureggs',
      //   type: DropdownMenuItemType.EXTERNAL_LINK,
      //   icon: t('BuyBountyIcon'),
      // },
      {
        label: t('Audit Report'),
        href: 'https://www.certik.org/projects/magician',
        type: DropdownMenuItemType.EXTERNAL_LINK,
        icon: t('ReportIcon'),
      },
      {
        label: t('Docs'),
        href: t('https://magicianmv.gitbook.io'),
        icon: t('DocsIcon'),
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Github'),
        href: 'https://github.com/MagicBallSwap/core',
        icon: t('GithubIcon'),
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
    ],
  },
]

export default config
