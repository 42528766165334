import BigNumber from 'bignumber.js'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AriDropView, AriDropState } from 'state/types'
import { ariDropInfo } from './ariDrop'

export const initialState: AriDropState = {
    data: {
        myRewards: 0,
        totalShare: 0,
        accRewardPerShare: 0,
        lastRewardBlock: 0,
        minEpochPeriod: 0,
        currentEpochStartAt: 0,
    }
}
export const fetchAriDropInfoAsync = createAsyncThunk<AriDropView, string>(
    'air/fetchAriDropInfoAsync',
    async (account) => {
        const info = await ariDropInfo(account)
        return info
    },
)
export const AriDropSlice = createSlice({
    name: 'AriDrop',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Update farms with live data
        builder.addCase(fetchAriDropInfoAsync.fulfilled, (state, action) => {
            // state.data.myRewards = action.payload.myRewards
            // state.data.totalShare = action.payload.totalShare
            // state.data.accRewardPerShare = action.payload.accRewardPerShare
            // state.data.lastRewardBlock = action.payload.lastRewardBlock
            state.data = {
                ...state.data,
                ...action.payload
            }
        })

    },
})

export default AriDropSlice.reducer