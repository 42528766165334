import multicall from 'utils/multicall'
import vDSGTokenABI from 'config/abi/vDSGToken.json'
import erc20ABI from 'config/abi/erc20.json'
import { getVDSGTokenAddress, getDsgAddress } from 'utils/addressHelpers'
import isZero from 'utils/isZero'

export const fetchFarmUserAllowances = async (account: string) => {
  try {
    const masterChefAddress = getVDSGTokenAddress()
    const calls = [{ address: getDsgAddress(), name: 'allowance', params: [account, masterChefAddress] }]
    const rawLpAllowances = await multicall(erc20ABI, calls)
    return {
      allowance: rawLpAllowances[0][0].toJSON().hex,
    }
  } catch (err) {
    return {
      allowance: '0',
    }
  }
}

export const fetchUserBanlanceOf = async (account: string) => {
  try {
    const masterChefAddress = getVDSGTokenAddress()
    const calls = [
      { address: masterChefAddress, name: 'balanceOf', params: [account] },
      { address: masterChefAddress, name: 'mbtBalanceOf', params: [account] },
      { address: masterChefAddress, name: 'getSuperior', params: [account] },
    ]

    const [vDsgAmount, dsgAmount, superior] = await multicall(vDSGTokenABI, calls)
    return {
      vDsgAmount: vDsgAmount.vMbtAmount.toHexString(16),
      dsgAmount: dsgAmount.mbtAmount.toHexString(16),
      superior: isZero(superior[0]) ? '' : superior[0],
    }
  } catch (err) {
    return {
      vDsgAmount: '0',
      dsgAmount: '0',
      superior: '',
    }
  }
}
export const fetchUsersBanlanceOf = async (account: string[]) => {
  try {
    const masterChefAddress = getVDSGTokenAddress()
    const calls = account.map(item => ({
      address: masterChefAddress,
      name: 'mbtBalanceOf',
      params: [item]
    }))

    const dsgAmounts = await multicall(vDSGTokenABI, calls)
    
    return dsgAmounts
  } catch (err) {
    console.error(err)
    return {
      vDsgAmount: '0',
      dsgAmount: '0',
      superior: '',
    }
  }
}
