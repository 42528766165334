import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 1024 1024" {...props}>
      <path d="M736.62464 389.12l-222.33088 235.9296-223.69621333-220.85632z" fill="#4dcdb3" p-id="2209"></path><path d="M521.28426667 948.14208c-39.64928 0-77.76938667-16.60245333-104.80298667-45.60213333l-363.06944-389.82997334c-48.93354667-52.53802667-51.22730667-133.80266667-5.35210667-189.07136l182.29930667-219.5456c27.30666667-32.87722667 67.44746667-51.71882667 110.15509333-51.71882666h354.33130667c43.30837333 0 83.83146667 19.27850667 111.13813333 52.92032L983.31306667 323.584c44.67370667 54.99562667 42.76224 133.14730667-4.53290667 185.90378667l-350.94528 391.14069333a143.45284267 143.45284267 0 0 1-105.23989333 47.56821333c-0.43690667-0.05461333-0.87381333-0.05461333-1.31072-0.05461333zM340.51413333 117.9648c-23.15605333 0-44.94677333 10.21269333-59.74698666 28.01664l-182.29930667 219.5456c-24.90368 29.98272-23.64757333 74.00106667 2.89450667 102.50922667l363.06944 389.82997333c14.85482667 15.94709333 35.00714667 24.73984 56.79786666 24.73984h0.76458667c22.06378667-0.21845333 42.32533333-9.33888 57.07093333-25.77749333l350.94528-391.14069334c25.66826667-28.61738667 26.70592-70.99733333 2.4576-100.81621333L755.13856 146.6368a77.22325333 77.22325333 0 0 0-60.23850667-28.672H340.51413333z" fill="#067d73" p-id="2210"></path><path d="M514.29376 657.8176c-8.62890667 0-16.87552-3.38602667-23.04682667-9.44810667L267.55072 427.51317333a32.77892267 32.77892267 0 0 1-0.27306667-46.36672 32.77892267 32.77892267 0 0 1 46.36672-0.27306666l199.83018667 197.31797333 199.33866667-211.57205333a32.72977067 32.72977067 0 0 1 46.31210666-1.36533334 32.72977067 32.72977067 0 0 1 1.36533334 46.31210667l-222.33088 235.98421333a32.44578133 32.44578133 0 0 1-23.26528 10.26730667h-0.60074667z" fill="#067d73" p-id="2211"></path>
    </Svg>
  );
};

export default Icon;
