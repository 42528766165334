import React from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { Flex, IconButton, useModal, CalculateIcon } from 'pancake-uikit'
import RoiCalculatorModal from 'components/RoiCalculatorModal'
import { useTranslation } from 'contexts/Localization'
import { useFarmUser, useLpTokenPrice } from 'state/farms/hooks'
import { BIG_ZERO } from 'utils/bigNumber'

const ApyLabelContainer = styled(Flex)`
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`

export interface ApyButtonProps {
  variant?: 'text' | 'text-and-button'
  pid?: number
  lpSymbol: string
  lpLabel?: string
  multiplier?: string
  cakePrice?: BigNumber
  apr?: number
  displayApr?: string
  addLiquidityUrl?: string
  stakingSymbol?: string
  rewardsSymbol?: string
  stakingTokenBalance?: string
  stakingTokenPrice?: BigNumber
  rewardsTokenPrice?: BigNumber
}

const ApyButton: React.FC<ApyButtonProps> = ({
  variant,
  lpLabel,
  lpSymbol,
  apr,
  multiplier,
  displayApr,
  addLiquidityUrl,
  stakingSymbol,
  rewardsSymbol,
  stakingTokenBalance,
  stakingTokenPrice,
  rewardsTokenPrice
}) => {
  const { t } = useTranslation()
  // const lpPrice = useLpTokenPrice(lpSymbol)
  // const { tokenBalance, stakedBalance } = useFarmUser(pid)
  const stakingBalance = new BigNumber(stakingTokenBalance || 0)

  const [onPresentApyModal] = useModal(
    <RoiCalculatorModal
      linkLabel={t('Get %symbol%', { symbol: stakingSymbol })}
      stakingTokenBalance={stakingBalance}
      stakingTokenSymbol={stakingSymbol}
      earningTokenSymbol={rewardsSymbol}
      stakingTokenPrice={stakingTokenPrice?.toNumber() || 0}
      earningTokenPrice={rewardsTokenPrice?.toNumber() || 0}
      apr={apr}
      multiplier={multiplier}
      displayApr={displayApr}
      linkHref={addLiquidityUrl}
    />,
  )

  const handleClickButton = (event): void => {
    event.stopPropagation()
    onPresentApyModal()
  }

  return (
    <ApyLabelContainer alignItems="center" onClick={handleClickButton}>
      {displayApr}%
      {variant === 'text-and-button' && (
        <IconButton variant="text" scale="sm" ml="4px">
          <CalculateIcon width="24px" />
        </IconButton>
      )}
    </ApyLabelContainer>
  )
}

export default ApyButton
