import BigNumber from 'bignumber.js'
import { DEFAULT_TOKEN_DECIMAL } from 'config'
import { BIG_ZERO } from 'utils/bigNumber'
import { MemberRedeemList } from '../types'

export const getResidueDsgAmount = (
  mintAmount: string,
  redeemRecieveAmount: string,
  redeemFeeAmount: string,
): BigNumber => {
  const dsgAmount = new BigNumber(mintAmount).minus(redeemRecieveAmount).minus(redeemFeeAmount)
  if (dsgAmount.isGreaterThan(0)) return dsgAmount
  return BIG_ZERO
}

// 纯收益
export const getUserNetDsgEarnings = (
  balanceOfDsg: BigNumber,
  redeemRecieveAmount: string,
  mintAmount: string,
): BigNumber => {
  const totalAmount = new BigNumber(balanceOfDsg).div(DEFAULT_TOKEN_DECIMAL).plus(redeemRecieveAmount).minus(mintAmount)
  if (totalAmount.isLessThan(0)) return BIG_ZERO
  if (totalAmount.isFinite()) return totalAmount
  return BIG_ZERO
}

// 总奖励
export const getUserTotalDsgEarnings = (
  totalBlockReward: string,
  curDistribution: string,
  totalDonate: string,
  feeAmount: string,
): BigNumber => {
  const totalAmount = new BigNumber(curDistribution)
    .div(DEFAULT_TOKEN_DECIMAL)
    .plus(totalBlockReward)
    .plus(totalDonate)
    .plus(feeAmount)
  if (totalAmount.isLessThan(0)) return BIG_ZERO
  if (totalAmount.isFinite()) return totalAmount
  return BIG_ZERO
}

// 用户的会员奖励
export const getUserTotalMemberEarnings = (
  netDsgEarnings: BigNumber,
  totalDsgEarnings: BigNumber,
  totalBlockReward: string,
  curDistribution: string,
): BigNumber => {
  const curDistri = new BigNumber(curDistribution).div(DEFAULT_TOKEN_DECIMAL).plus(totalBlockReward)
  const totalAmount = netDsgEarnings.times(curDistri).div(totalDsgEarnings)
  if (totalAmount.isLessThan(0)) return BIG_ZERO
  if (totalAmount.isFinite()) return totalAmount
  return BIG_ZERO
}

// 用户的分红奖励
export const getUserTotalDonateEarnings = (
  netDsgEarnings: BigNumber,
  totalDsgEarnings: BigNumber,
  totalDonate: string,
): BigNumber => {
  const totalAmount = netDsgEarnings.times(totalDonate).div(totalDsgEarnings)
  if (totalAmount.isLessThan(0)) return BIG_ZERO
  if (totalAmount.isFinite()) return totalAmount
  return BIG_ZERO
}

// 用户的手续费奖励
export const getUserTotalFeeEarnings = (
  netDsgEarnings: BigNumber,
  totalDsgEarnings: BigNumber,
  feeAmount: string,
): BigNumber => {
  const totalAmount = netDsgEarnings.times(feeAmount).div(totalDsgEarnings)
  if (totalAmount.isLessThan(0)) return BIG_ZERO
  if (totalAmount.isFinite()) return totalAmount
  return BIG_ZERO
}

export const caleExitFeeRate = (redeem: MemberRedeemList) => {
  const { recieve, burn, fee } = redeem
  if (recieve) {
    const burnN = new BigNumber(burn)
    const recieveN = new BigNumber(recieve)
    const total = recieveN.plus(burn).plus(fee).plus(burnN.times(0.66667))
    // return (total.minus(recieveN)).div(total).times(100).toFixed(2)
    return {
      exitFeeRate: (total.minus(recieveN)).div(total).times(100).toFixed(2),
      total: total.toString(),
      exitFee: total.minus(recieve).toString(),
    }
  }
  return {
    exitFeeRate: '',
    total: '',
    exitFee: '',
  }
}

