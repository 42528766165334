import aggregatorABI from 'config/abi/aggregator.json'
import multicall from 'utils/multicall'
import { getAggregatorAddress } from 'utils/addressHelpers'

const fetchUserMiningInfos = async (account: string) => {
  try {
    const calls = [
      {
        address: getAggregatorAddress(),
        name: 'getUserMiningInfos', // Function name on the contract (example: balanceOf)
        params: [account],
      },
    ]

    const [data] = await multicall(aggregatorABI, calls)

    const userInfo = data[0].map((item) => {
      return {
        userAmount: item.userAmount.toJSON().hex,
        userUnclaimedReward: item.userUnclaimedReward.toJSON().hex,
        userAccReward: item.userAccReward.toJSON().hex,
        poolType: item.poolType,
        pid: item.pid.toJSON().hex,
        pair: item.pair,
        totalAmount: item.totalAmount.toJSON().hex,
        rewardsPerBlock: item.rewardsPerBlock.toJSON().hex,
        allocPoint: item.allocPoint.toJSON().hex,
        token0: item.token0,
        name0: item.name0,
        symbol0: item.symbol0,
        decimals0: item.decimals0,
        token1: item.token1,
        name1: item.name1,
        symbol1: item.symbol1,
        decimals1: item.decimals1,
      }
    })
    return userInfo
  } catch (error) {
    console.error(error)
    return []
  }
}

export default fetchUserMiningInfos
