import { getNftInfo, getMysteryNftInfo } from 'config/axios'
import isZero from 'utils/isZero'

const fetchNftsInfo = async (nft: string, nftsId: string[]) => {
  try {
    const requests = nftsId.map((id) => {
      if (Number(id) && !isZero(nft)) {
        return getNftInfo(nft, Number(id).toString(10))
      }
      return null
    })
    const infos = await Promise.all(requests)
    return infos.filter(item => !!item).map((item) => ({
      ...item,
      key: `${item.properties.token}_${item.properties.token_id}_${item.properties.owner_status}`,
    }))
  } catch (error) {
    console.error(error);
    return []
  }
}

export const fetchMysteryNftsInfo = async (nft: string, nftsId: string[]) => {
  try {
    const requests = nftsId.map((id) => {
      if (Number(id) && !isZero(nft)) {
        return getMysteryNftInfo(Number(id).toString(10))
      }
      return null
    })
    const infos = await Promise.all(requests)
    return infos.map((item) => ({
      ...item,
      key: `${item.properties.token}_${item.properties.token_id}_${item.properties.owner_status}`,
    }))
  } catch (error) {
    return []
  }
}

export default fetchNftsInfo
