import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 1024 1024" {...props}>
      <path d="M509.92 795.84c157.904 0 285.92-128.016 285.92-285.92C795.84 352 667.808 224 509.92 224 352 224 224 352 224 509.92c0 157.904 128 285.92 285.92 285.92z m0 48C325.504 843.84 176 694.32 176 509.92 176 325.504 325.504 176 509.92 176c184.416 0 333.92 149.504 333.92 333.92 0 184.416-149.504 333.92-333.92 333.92z m58.272-487.296a16 16 0 0 1 0 22.624l-129.12 129.12 129.12 129.12a16 16 0 0 1 0 22.64l-11.312 11.312a16 16 0 0 1-22.624 0l-151.76-151.76a16 16 0 0 1 0-22.624l151.76-151.744a16 16 0 0 1 22.624 0l11.312 11.312z" p-id="2877"></path>
    </Svg>
  );
};

export default Icon;
