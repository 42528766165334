import BigNumber from 'bignumber.js'
import { BIG_ONE, BIG_ZERO } from 'utils/bigNumber'
import { filterFarmsByQuoteToken, filterFarmsByToken } from 'utils/farmsPriceHelpers'
import { Farm } from 'state/types'
import { isBNBBUSDLp, isBNBDSGLp, isBaseTokenLp } from 'utils/getLiquidityPool'
import { stableSymbols, WETH, SWAP_TOKEN } from 'dsgswap-sdk'
// import { stableSymbols } from 'config/constants/tokens'
import { getBalanceAmount } from 'utils/formatBalance'
import { getToken } from 'utils/addressHelpers'

const weth = getToken(WETH)

const getFarmFromTokenSymbol = (farms: Farm[], tokenSymbol: string, preferredQuoteTokens?: string[]): Farm => {
  const farmsWithTokenSymbol = farms.filter((farm) => farm.token.symbol === tokenSymbol)

  const filteredFarm = filterFarmsByQuoteToken(farmsWithTokenSymbol, preferredQuoteTokens)
  return filteredFarm
}
const getFarmFromTokenQuoteSymbol = (farms: Farm[], tokenSymbol: string, preferredQuoteTokens?: string[]): Farm => {
  const farmsWithTokenSymbol = farms.filter((farm) => farm.quoteToken.symbol === tokenSymbol)
  const filteredFarmQuote = filterFarmsByToken(farmsWithTokenSymbol, preferredQuoteTokens)
  return filteredFarmQuote
}

const getFarmBaseTokenPrice = (farm: Farm, quoteTokenFarm: Farm, bnbPriceBusd: BigNumber): BigNumber => {
  const hasTokenPriceVsQuote = Boolean(farm.tokenPriceVsQuote)

  if (stableSymbols.includes(farm.quoteToken.symbol)) {
    return hasTokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (farm.quoteToken.symbol === weth.symbol) {
    return hasTokenPriceVsQuote ? bnbPriceBusd.times(farm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (stableSymbols.includes(farm.token.symbol)) {
    return BIG_ONE
  }

  if (farm.token.symbol === weth.symbol) {
    return bnbPriceBusd
  }

  // We can only calculate profits without a quoteTokenFarm for BUSD/BNB farms
  if (!quoteTokenFarm) {
    return BIG_ZERO
  }

   /**
   * 
   * 1. 如果farm的 token === quoteTokenFarm 的 token 直接返回 quoteTokenFarm 的 token Price
   * 2. 如果farm的 token === quoteTokenFarm 的 quoteTokenn 直接返回 quoteTokenFarm 的 quoteTokenn Price
   * 
   * 3. 如果farm的 quoteToken === quoteTokenFarm 的 token
   *  说明现在 已经知道 farm 的 quoteToken = quoteTokenFarm.token 的值
   *  可以得出 (tokenPriceVsQuote = token / quoteTokenn ) =》 token = tokenPriceVsQuote * quoteTokenn
   * 
   * 4. 如果farm的 quoteToken === quoteTokenFarm 的 quoteTokenn
   *  说明现在 已经知道 farm的 quoteToken = quoteTokenFarm.quoteToken 的值
   *  可以得出 (tokenPriceVsQuote = token / quoteTokenn ) =》 token = tokenPriceVsQuote * quoteTokenn
   */
    if (Number(quoteTokenFarm.token.busdPrice)) {
      if (farm.token.symbol === quoteTokenFarm.token.symbol) {
        return new BigNumber(quoteTokenFarm.token.busdPrice)
      }
      if (farm.quoteToken.symbol === quoteTokenFarm.token.symbol && hasTokenPriceVsQuote) {
        return new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenFarm.token.busdPrice)
      }
    }
    if (Number(quoteTokenFarm.quoteToken.busdPrice)) {
      if (farm.token.symbol === quoteTokenFarm.quoteToken.symbol) {
        return new BigNumber(quoteTokenFarm.quoteToken.busdPrice)
      }
      if (farm.quoteToken.symbol === quoteTokenFarm.quoteToken.symbol && hasTokenPriceVsQuote) {
        return new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenFarm.quoteToken.busdPrice)
      }
    }

    /**
     * 
     * 如果quoteTokenFarm.token.busdPrice 没有值
     * 1. 如果farm的 token === quoteTokenFarm 的 token, 计算并直接返回 quoteTokenFarm 的 token Price
     *  (tokenPriceVsQuote = token / quoteTokenn ) =》 token = tokenPriceVsQuote * quoteTokenn
     * 
     * 2. 如果farm的 token === quoteTokenFarm 的 quoteTokenn 直接返回 quoteTokenFarm 的 quoteTokenn Price
     * 
     */

  // Possible alternative farm quoteTokens:
  // UST (i.e. MIR-UST), pBTC (i.e. PNT-pBTC), BTCB (i.e. bBADGER-BTCB), ETH (i.e. SUSHI-ETH)
  // If the farm's quote token isn't BUSD or wBNB, we then use the quote token, of the original farm's quote token
  // i.e. for farm PNT - pBTC we use the pBTC farm's quote token - BNB, (pBTC - BNB)
  // from the BNB - pBTC price, we can calculate the PNT - BUSD price

  /**
   * 
   * if quoteTokenFarm.quoteToken.symbol === weth.symbol
   * quoteTokenFarm.token.price: 
   * (tokenPriceVsQuote = token / quoteTokenn ) =》 token = tokenPriceVsQuote * bnbPrice
   * 
   * if quoteTokenFarm.token.symbol === weth.symbol
   * quoteTokenFarm.quoteToken.price: :
   * (tokenPriceVsQuote = token / quoteTokenn ) =》 quoteTokenn = bnbPrice / tokenPriceVsQuote
   */
  // IF START
  if (!Number(quoteTokenFarm.token.busdPrice)) {
    let tokenPrice = BIG_ZERO
    // (tokenPriceVsQuote = token / quoteTokenn ) =》 token = tokenPriceVsQuote * quoteTokenPrice
    if (quoteTokenFarm.quoteToken.symbol === weth.symbol) {
      tokenPrice = new BigNumber(quoteTokenFarm.tokenPriceVsQuote).times(bnbPriceBusd)
    } else if (quoteTokenFarm.token.symbol === weth.symbol) {
      tokenPrice = bnbPriceBusd
    } else if (stableSymbols.includes(quoteTokenFarm.quoteToken.symbol)) {
      tokenPrice = new BigNumber(quoteTokenFarm.tokenPriceVsQuote).times(BIG_ONE)
    } else if (stableSymbols.includes(quoteTokenFarm.token.symbol)) {
      tokenPrice = BIG_ONE
    }

    if (farm.token.symbol === quoteTokenFarm.token.symbol) {
      return new BigNumber(tokenPrice)
    }
    if (farm.quoteToken.symbol === quoteTokenFarm.token.symbol && hasTokenPriceVsQuote) {
      return new BigNumber(farm.tokenPriceVsQuote).times(tokenPrice)
    }
  }
  // IF END

  // IF START
  if (!Number(quoteTokenFarm.quoteToken.busdPrice)) {

    let quoteTokenPrice = BIG_ZERO
    // (tokenPriceVsQuote = token / quoteTokenn ) =》 quoteTokenn = tokenPrice / tokenPriceVsQuote
    if (quoteTokenFarm.quoteToken.symbol === weth.symbol) {
      quoteTokenPrice = bnbPriceBusd
    } else if (quoteTokenFarm.token.symbol === weth.symbol) {
      quoteTokenPrice = bnbPriceBusd.div(quoteTokenFarm.tokenPriceVsQuote)
    } else if (stableSymbols.includes(quoteTokenFarm.quoteToken.symbol)) {
      quoteTokenPrice = BIG_ONE
    } else if (stableSymbols.includes(quoteTokenFarm.token.symbol)) {
      quoteTokenPrice = BIG_ONE.div(quoteTokenFarm.tokenPriceVsQuote)
    }

    if (farm.token.symbol === quoteTokenFarm.token.symbol) {
      return new BigNumber(quoteTokenPrice)
    }
    if (farm.quoteToken.symbol === quoteTokenFarm.token.symbol && hasTokenPriceVsQuote) {
      return new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenPrice)
    }
  }
  // IF END

  if (quoteTokenFarm.quoteToken.symbol === weth.symbol) {
    const quoteTokenInBusd = bnbPriceBusd.times(quoteTokenFarm.tokenPriceVsQuote)
    return hasTokenPriceVsQuote && quoteTokenInBusd
      ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInBusd)
      : BIG_ZERO
  }

  if (stableSymbols.includes(quoteTokenFarm.quoteToken.symbol)) {
    const quoteTokenInBusd = quoteTokenFarm.tokenPriceVsQuote
    return hasTokenPriceVsQuote && quoteTokenInBusd
      ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInBusd)
      : BIG_ZERO
  }
  if (quoteTokenFarm.quoteToken.symbol === weth.symbol) {
    // const quoteTokenInBusd = bnbPriceBusd.times(quoteTokenFarm.tokenPriceVsQuote)
    return quoteTokenFarm.tokenPriceVsQuote
      ? new BigNumber(quoteTokenFarm.tokenPriceVsQuote).times(bnbPriceBusd)
      : BIG_ZERO
  }

  if (stableSymbols.includes(quoteTokenFarm.quoteToken.symbol)) {
    // const quoteTokenInBusd = quoteTokenFarm.tokenPriceVsQuote
    return quoteTokenFarm.tokenPriceVsQuote ? new BigNumber(quoteTokenFarm.tokenPriceVsQuote).div(BIG_ONE) : BIG_ZERO
  }

  // Catch in case token does not have immediate or once-removed BUSD/wBNB quoteToken
  return BIG_ZERO
}

const getFarmQuoteTokenPrice = (farm: Farm, quoteTokenFarm: Farm, bnbPriceBusd: BigNumber): BigNumber => {
  if (stableSymbols.includes(farm.quoteToken.symbol)) {
    return BIG_ONE
  }

  const hasTokenPriceVsQuote = Boolean(farm.tokenPriceVsQuote)

  if (farm.quoteToken.symbol === weth.symbol) {
    return bnbPriceBusd
  }
  if (stableSymbols.includes(farm.token.symbol)) {
    return farm.tokenPriceVsQuote ? BIG_ONE.div(farm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (farm.token.symbol === weth.symbol) {
    return farm.tokenPriceVsQuote ? bnbPriceBusd.div(farm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (!quoteTokenFarm) {
    return BIG_ZERO
  }

  /**
   * 
   * 1. 如果farm的 quoteToken === quoteTokenFarm 的 quoteToken 直接返回 quoteTokenFarm 的 quoteToken Price
   * 2. 如果farm的 quoteToken === quoteTokenFarm 的 token 直接返回 quoteTokenFarm 的 token Price
   * 
   * 3. 如果farm的 token === quoteTokenFarm 的 quoteToken
   *  说明现在 已经知道 farm 的 quoteToken = quoteTokenFarm.quoteToken 的值
   *  可以得出 (tokenPriceVsQuote = token / quoteTokenn ) =》 quoteTokenFarm = token / tokenPriceVsQuote
   * 
   * 4. 如果farm的 token === quoteTokenFarm 的 token
   *  说明现在 已经知道 farm的 token = quoteTokenFarm.token 的值
   *  可以得出 (tokenPriceVsQuote = token / quoteTokenn ) =》 quoteTokenFarm = token / tokenPriceVsQuote
   */
   if (Number(quoteTokenFarm.quoteToken.busdPrice)) {
    if (farm.quoteToken.symbol === quoteTokenFarm.quoteToken.symbol) {
      return new BigNumber(quoteTokenFarm.quoteToken.busdPrice)
    }
    if (farm.token.symbol === quoteTokenFarm.quoteToken.symbol && hasTokenPriceVsQuote) {
      return new BigNumber(quoteTokenFarm.quoteToken.busdPrice).div(farm.tokenPriceVsQuote)
    }
  }
  if (Number(quoteTokenFarm.token.busdPrice)) {
    if (farm.quoteToken.symbol === quoteTokenFarm.token.symbol) {
      return new BigNumber(quoteTokenFarm.token.busdPrice)
    }
    if (farm.token.symbol === quoteTokenFarm.token.symbol && hasTokenPriceVsQuote) {
      return new BigNumber(quoteTokenFarm.token.busdPrice).div(farm.tokenPriceVsQuote)
    }
  }

  // IF START
  if (!Number(quoteTokenFarm.quoteToken.busdPrice)) {
    let quoteTokenPrice = BIG_ZERO
    // (tokenPriceVsQuote = token / quoteTokenn ) =》 quoteTokenn = tokenPrice / tokenPriceVsQuote
    if (quoteTokenFarm.quoteToken.symbol === weth.symbol) {
      quoteTokenPrice = bnbPriceBusd
    } else if (quoteTokenFarm.token.symbol === weth.symbol) {
      quoteTokenPrice = bnbPriceBusd.div(quoteTokenFarm.tokenPriceVsQuote)
    } else if (stableSymbols.includes(quoteTokenFarm.quoteToken.symbol)) {
      quoteTokenPrice = BIG_ONE
    } else if (stableSymbols.includes(quoteTokenFarm.token.symbol)) {
      quoteTokenPrice = BIG_ONE.div(quoteTokenFarm.tokenPriceVsQuote)
    }

    if (farm.quoteToken.symbol === quoteTokenFarm.quoteToken.symbol) {
      return new BigNumber(quoteTokenPrice)
    }
    if (farm.token.symbol === quoteTokenFarm.quoteToken.symbol && hasTokenPriceVsQuote) {
      return new BigNumber(quoteTokenPrice).div(farm.tokenPriceVsQuote)
    }
  }
  // IF END

  // IF START
  if (!Number(quoteTokenFarm.token.busdPrice)) {
    let tokenPrice = BIG_ZERO
    // (tokenPriceVsQuote = token / quoteTokenn ) =》 token = tokenPriceVsQuote * quoteTokenPrice
    if (quoteTokenFarm.quoteToken.symbol === weth.symbol) {
      tokenPrice = bnbPriceBusd.times(quoteTokenFarm.tokenPriceVsQuote)
    } else if (quoteTokenFarm.token.symbol === weth.symbol) {
      tokenPrice = bnbPriceBusd
    } else if (stableSymbols.includes(quoteTokenFarm.token.symbol)) {
      tokenPrice = BIG_ONE
    } else if (stableSymbols.includes(quoteTokenFarm.quoteToken.symbol)) {
      tokenPrice = BIG_ONE.times(quoteTokenFarm.tokenPriceVsQuote)
    }
    if (farm.quoteToken.symbol === quoteTokenFarm.token.symbol) {
      return new BigNumber(tokenPrice)
    }
    if (farm.token.symbol === quoteTokenFarm.token.symbol && hasTokenPriceVsQuote) {
      return new BigNumber(tokenPrice).div(farm.tokenPriceVsQuote)
    }
  }
  // IF END


  if (quoteTokenFarm.quoteToken.symbol === weth.symbol) {
    return quoteTokenFarm.tokenPriceVsQuote ? bnbPriceBusd.times(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (stableSymbols.includes(quoteTokenFarm.quoteToken.symbol)) {
    return quoteTokenFarm.tokenPriceVsQuote ? new BigNumber(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }
  if (quoteTokenFarm.token.symbol === weth.symbol) {
    const quoteTokenInBusd = bnbPriceBusd.times(quoteTokenFarm.tokenPriceVsQuote)
    return farm.tokenPriceVsQuote && quoteTokenInBusd
      ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInBusd)
      : BIG_ZERO
  }

  if (stableSymbols.includes(quoteTokenFarm.token.symbol)) {
    const quoteTokenInBusd = quoteTokenFarm.tokenPriceVsQuote
    return farm.tokenPriceVsQuote && quoteTokenInBusd
      ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInBusd)
      : BIG_ZERO
  }

  return BIG_ZERO
}


const getBnbPriceBusd = (farm) => {
  if (farm) {
    if (farm.token.symbol === weth.symbol) return BIG_ONE.div(farm.tokenPriceVsQuote)
    if (farm.quoteToken.symbol === weth.symbol) return farm.tokenPriceVsQuote
  }
  return null
}

const fetchFarmsPrices = async (farms) => {
  const bnbBusdFarm = farms.find((farm: Farm) => isBaseTokenLp(weth.symbol, farm))
  const tokenPriceVsQuote = getBnbPriceBusd(bnbBusdFarm)
  const bnbPriceBusd = tokenPriceVsQuote ? BIG_ONE.div(tokenPriceVsQuote) : BIG_ZERO

  const farmsWithPrices = farms.map((farm) => {
    const quoteTokenFarm = getFarmFromTokenSymbol(farms, farm.quoteToken.symbol, ['USDC', weth.symbol])
    const TokenFarm = getFarmFromTokenQuoteSymbol(farms, farm.quoteToken.symbol, ['USDC', weth.symbol])
    const baseTokenPrice = getFarmBaseTokenPrice(farm, TokenFarm, bnbPriceBusd)
    const quoteTokenPrice = getFarmQuoteTokenPrice(farm, quoteTokenFarm, bnbPriceBusd)
    const token = {
      ...farm.token,
      busdPrice: baseTokenPrice.toJSON()
    }
    const quoteToken = {
      ...farm.quoteToken,
      busdPrice: quoteTokenPrice.toJSON(),
    }

    let totalLiquidity = ''
    let lpTokenPrice = BIG_ZERO
    if (farm?.lpTotalSupply && farm?.lpTotalInQuoteToken) {
      // Total value of base token in LP
      const valueOfBaseTokenInFarm = baseTokenPrice.times(farm.tokenAmountTotal)
      // Double it to get overall value in LP
      const overallValueOfAllTokensInFarm = valueOfBaseTokenInFarm.times(2)
      // Divide total value of all tokens, by the number of LP tokens
      const totalLpTokens = getBalanceAmount(new BigNumber(farm.lpTotalSupply))
      lpTokenPrice = overallValueOfAllTokensInFarm.div(totalLpTokens)
    }
    if (farm?.lpTotalInQuoteToken) {
      totalLiquidity = new BigNumber(farm.lpTotalInQuoteToken).times(quoteTokenPrice).toJSON()
    }
    return { ...farm, token, quoteToken, totalLiquidity, lpTokenPrice: lpTokenPrice.toJSON() }
  })
  return farmsWithPrices
}

export const fetchFarmsPricesWithLp = async (farms, farmsPrice) => {
  const baseFarm = farmsPrice.length ? farmsPrice : farms
  const bnbBusdFarm = baseFarm.find((farm: Farm) => isBaseTokenLp(weth.symbol, farm))
  const tokenPriceVsQuote = getBnbPriceBusd(bnbBusdFarm)
  const bnbPriceBusd = tokenPriceVsQuote ? BIG_ONE.div(tokenPriceVsQuote) : BIG_ZERO

  const farmsWithPrices = farms.map((farm: Farm) => {
    // let filterToken
    // eslint-disable-next-line
    // debugger
    let filterToken = getFarmFromTokenSymbol(baseFarm, farm.quoteToken.symbol, ['USDC', weth.symbol])
    if (!filterToken) {
      filterToken = getFarmFromTokenQuoteSymbol(baseFarm, farm.quoteToken.symbol, ['USDC', weth.symbol])
    }
    if (!filterToken) {
      filterToken = getFarmFromTokenSymbol(baseFarm, farm.token.symbol, ['USDC', weth.symbol])
    }
    if (!filterToken) {
      filterToken = getFarmFromTokenQuoteSymbol(baseFarm, farm.token.symbol, ['USDC', weth.symbol])
    }
    // const quoteTokenFarm1 = getFarmFromTokenSymbol(baseFarm, farm.token.symbol, ['USDC', weth.symbol])
    // const TokenFarm1 = getFarmFromTokenQuoteSymbol(baseFarm, farm.token.symbol, ['USDT', weth.symbol])
    // const quoteTokenFarm = getFarmFromTokenSymbol(baseFarm, farm.quoteToken.symbol, ['USDT', weth.symbol])
    // const TokenFarm = getFarmFromTokenQuoteSymbol(baseFarm, farm.quoteToken.symbol, ['USDT', weth.symbol])
    // const quoteTokenFarm1 = getFarmFromTokenSymbol(baseFarm, farm.token.symbol, ['USDT', weth.symbol])
    // const TokenFarm1 = getFarmFromTokenQuoteSymbol(baseFarm, farm.token.symbol, ['USDT', weth.symbol])
    const baseTokenPrice = getFarmBaseTokenPrice(farm, filterToken, bnbPriceBusd)
    const quoteTokenPrice = getFarmQuoteTokenPrice(farm, filterToken, bnbPriceBusd)
    const token = {
      ...farm.token,
      busdPrice: baseTokenPrice.toJSON()
    }
    const quoteToken = {
      ...farm.quoteToken,
      busdPrice: quoteTokenPrice.toJSON(),
    }

    // const quoteTokenFarm = getFarmFromTokenSymbol(farms, farm.quoteToken.symbol, ['USDC', weth.symbol])
    // const TokenFarm = getFarmFromTokenQuoteSymbol(farms, farm.quoteToken.symbol, ['USDC', weth.symbol])
    // const baseTokenPrice = getFarmBaseTokenPrice(farm, TokenFarm, bnbPriceBusd)
    // const quoteTokenPrice = getFarmQuoteTokenPrice(farm, quoteTokenFarm, bnbPriceBusd)

    // const token = {
    //   ...farm.token,
    //   busdPrice: baseTokenPrice.toJSON()
    // }
    // const quoteToken = {
    //   ...farm.quoteToken,
    //   busdPrice: quoteTokenPrice.toJSON(),
    // }

    let lpTokenPrice = BIG_ZERO
    let totalLiquidity = ''
    if (farm?.lpTotalSupply && farm?.lpTotalInQuoteToken) {
      // Total value of base token in LP
      const valueOfBaseTokenInFarm = baseTokenPrice.times(farm.tokenAmountTotal)
      // Double it to get overall value in LP
      const overallValueOfAllTokensInFarm = valueOfBaseTokenInFarm.times(2)
      // Divide total value of all tokens, by the number of LP tokens
      const totalLpTokens = getBalanceAmount(new BigNumber(farm.lpTotalSupply))
      lpTokenPrice = overallValueOfAllTokensInFarm.div(totalLpTokens)
    }
    if (farm?.lpTotalInQuoteToken) {
      totalLiquidity = new BigNumber(farm.lpTotalInQuoteToken).times(quoteTokenPrice).toJSON()
    }
    return { ...farm, token, quoteToken, lpTokenPrice: lpTokenPrice.toJSON(), totalLiquidity }
  })

  return farmsWithPrices
}

export default fetchFarmsPrices
