import React from "react";
import { useModal } from "../Modal";
import ConnectModal from "./ConnectModal";
import AccountModal from "./AccountModal";
import connectToast from "./connectToast";
import { Login, ToastCustom } from "./types";

interface ReturnType {
  onPresentConnectModal: () => void;
  onPresentAccountModal: () => void;
}

const useWalletModal = (login: Login, logout: () => void, toastCustom: ToastCustom, clear: () => void, account?: string): ReturnType => {
  // const [onPresentConnectModal] = useModal(<ConnectModal toastCustom={toastCustom} login={login} />);
  const onPresentConnectModal = () => {
    connectToast({toastCustom, login, clear});
  };
  const [onPresentAccountModal] = useModal(<AccountModal account={account || ""} logout={logout} />);
  return { onPresentConnectModal, onPresentAccountModal };
};

export default useWalletModal;
