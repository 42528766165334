import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import singlePoolFactoryABI from 'config/abi/singlePoolFactory.json'
import multicall from 'utils/multicall'
import {
  getSinglePoolFactoryAddress,
} from 'utils/addressHelpers'
import { SinglePoolView, NftFarm } from '../types'

export const fetchPoolsAllowance = async (account: string, farmsToFetch: SinglePoolView[]) => {
  try {
    // const masterChefAddress = getLiquidityPoolAddress()
    const calls = farmsToFetch.map((farm) => {
      return { address: farm.depositToken, name: 'allowance', params: [account, farm.poolAddress] }
    })

    const rawLpAllowances = await multicall(erc20ABI, calls)
    const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
      return lpBalance[0].toJSON()
    })
    return parsedLpAllowances
  } catch (error) {
    console.error(error)
    return []
  }
}

export const fetchFarmUserViews = async (account: string) => {
  try {
    const masterChefAddress = getSinglePoolFactoryAddress()
    const calls = [
      {
        address: masterChefAddress,
        name: 'getUserViews',
        params: [account],
      },
    ]

    const [userViews] = await multicall(singlePoolFactoryABI, calls)
    const parsedLpAllowances = userViews[0].map((userView) => {
      return {
        tokenBalance: userView.tokenBalance.toJSON().hex,
        stakedBalance: userView.stakedAmount.toJSON().hex,
        earnings: userView.unclaimedRewards.toJSON().hex,
      }
    })
    return parsedLpAllowances
  } catch (error) {
    console.error(error)
    return []
  }
}


