import BigNumber from 'bignumber.js'
import { request, gql } from 'graphql-request'
import { GRAPH_API_SWAPTS } from 'config/constants/endpoints'
import { LotteryRoundGraphEntity, DsgTotalData, swapDayData } from 'state/types'
import { BIG_ZERO } from 'utils/bigNumber'

export const MAX_LOTTERIES_REQUEST_SIZE = 100

export const getSwapDayData = async (first = MAX_LOTTERIES_REQUEST_SIZE, where = {}): Promise<swapDayData[]> => {
  try {
    const response = await request(
      GRAPH_API_SWAPTS,
      gql`
        query getSwapDayData($first: Int!) {
          swapDayDatas(first: $first, where: $where) {
            id
            totalVolumeUSD
            totalLiquidityUSD
            date
          }
        }
      `,
      { first, where },
    )
    return response.swapDayDatas
  } catch (error) {
    return []
  }
}
// reserveUSD
export const getDsgTotalData = async (first = 10, where = {}): Promise<DsgTotalData> => {
  try {
    const response = await request(
      GRAPH_API_SWAPTS,
      gql`
        query getSwapDayData($first: Int!) {
          swapDayDatas(first: $first, where: $where, orderBy: id, orderDirection: desc) {
            dailyVolumeUSD
            totalLiquidityUSD
            id
          }
        }
      `,
      { first, where },
    )
    return {
      totalVolumeUSD: response.swapDayDatas[0]?.dailyVolumeUSD,
      reserveUSD: response.swapDayDatas[0]?.totalLiquidityUSD,
    }
  } catch (error) {
    console.error(error)
    return null
  }
}
// reserveUSD
export const get24HTotalData = async (first = 1000, where = {}): Promise<DsgTotalData> => {
  try {
    const pairResponse = await request(
      GRAPH_API_SWAPTS,
      gql`
        query getPairHourDatas($first: Int!) {
          pairs(first: $first, where: { volumeUSD_gt: "0"}) {
            name
            id
            volumeUSD
            pairHourData(first:24, orderBy: hourStartUnix, orderDirection: desc) {
              hourlyVolumeUSD
              hourStartUnix
            }
          }
        }
      `,
      { first, where },
    )
    let volume = new BigNumber(0)
    pairResponse.pairs.forEach(item => {
      if (item.pairHourData && item.pairHourData.length) {
        item.pairHourData.forEach(subItem => {
          volume = volume.plus(subItem.hourlyVolumeUSD)
        })      }
    })
    return {
      totalVolumeUSD: volume.toString(),
      reserveUSD: '',
    }
  } catch (error) {
    console.error(error)
    return null
  }
}
