// import { Web3ReactProvider } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import AirdropABI from 'config/abi/vDSGAirdrop.json'
import erc20ABI from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
import { getAriDropAddress } from 'utils/addressHelpers'
import { getBalanceAmount, getBalanceNumber } from 'utils/formatBalance'
import { BSC_BLOCK_TIME } from 'config'


// 获取空投精度
const tokenDecimals = async (rewardAddr: string) => {
    try {
        // 获取代币精度
        const decimalsCall = [
            {
                address: rewardAddr,
                name: 'decimals'
            },
        ]
        const [getDecimal] = await multicall(erc20ABI, decimalsCall)
        const Decimal = getDecimal[0]
        return Decimal
    } catch (error) {
        // 默认18
        console.error(error)
        const defullt = 18
        return defullt
    }
}

export const ariDropInfo = async (account: string) => {
    // 空投合约地址
    const AriDropAddress = getAriDropAddress()
    try {
        // 获取空投信息
        const calls1 = [
            {
                address: AriDropAddress,
                name: 'getPoolView'
            },
            {
                address: AriDropAddress,
                name: 'minEpochPeriod'
            },
        ]
        const [PoolView, minEpochPeriod_] = await multicall(AirdropABI, calls1)
        // 空投代币地址
        const rewardAddr = PoolView.rewardToken_
        // 周期
        const minEpochPeriod = Number(minEpochPeriod_[0].toJSON().hex)
        // 精度
        const decimals = await tokenDecimals(rewardAddr)
        // 本期空投总量
        // const accRewardPerShare = Number(getBalanceAmount(PoolView.accRewardPerShare_.toJSON().hex, decimals).toString())
        const accRewardPerShare = getBalanceNumber(new BigNumber(minEpochPeriod)
            .div(BSC_BLOCK_TIME)
            .times(PoolView.rewardTokenPerBlock_.toJSON().hex), decimals)
        const lastRewardBlock = Number(new BigNumber(PoolView.lastRewardBlock_.toJSON().hex).toString())
        const currentEpochStartAt = PoolView.currentEpochStartAt_.toJSON().hex
        const currentAccRewardAmount = getBalanceNumber(new BigNumber(PoolView.currentAccRewardAmount_.toJSON().hex), decimals)
        // 未领取总量
        const totalShare = new BigNumber(accRewardPerShare).minus(currentAccRewardAmount).toNumber()
        // 获取当前账户可领取数量
        let myRewards: number
        if (account) {
            const calls = [
                {
                    address: AriDropAddress,
                    name: 'pendingRewards',
                    params: [account]
                }
            ]
            try {
                const [pendingRewards] = await multicall(AirdropABI, calls)
                // 当前账户可领取数量
                myRewards = Number(getBalanceAmount(pendingRewards, decimals).toString())
            } catch (error) {
                console.error(error)
                myRewards = 0
            }
        } else {
            myRewards = 0
        }
        return { myRewards, totalShare, accRewardPerShare, lastRewardBlock, currentEpochStartAt: Number(currentEpochStartAt), minEpochPeriod }
    } catch (error) {
        console.error(error)
        return { myRewards: 0, totalShare: 0, accRewardPerShare: 0, lastRewardBlock: 0, currentEpochStartAt: 0, minEpochPeriod: 0, }
    }
}
