import { createSlice } from '@reduxjs/toolkit'
import { getMysteryBoxFactories } from './fetchMysteryBoxFactories'
import { MysteryBoxState, MysteryBoxFactory, AppThunk } from '../types'
import { fetchMysteryBoxAllowances } from './fetchUserAllowance'

const initialState: MysteryBoxState = {
  data: [],
  loaded: false,
  userLoaded: false,
}

export const fetchMysteryBoxFactoriesAsync =
  (account?: string): AppThunk =>
  async (dispatch) => {
    const data = await getMysteryBoxFactories()
    dispatch(setMysteryBoxFactories(data))
    if (account) {
      dispatch(fetchMysteryBoxAllowancesAsync(account, data))
    }
  }

export const fetchMysteryBoxAllowancesAsync =
  (account, factorys?: MysteryBoxFactory[]): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const fetchFactorys = factorys || state.mysteryBox.data
    const allowances = await fetchMysteryBoxAllowances(account, fetchFactorys)
    const data = fetchFactorys.map((factory, index) => ({
      ...factory,
      allowance: allowances[index],
    }))
    dispatch(setMysteryBoxAllowances(data))
  }

export const mysteryBox = createSlice({
  name: 'MysteryBox',
  initialState,
  reducers: {
    setMysteryBoxFactories: (state, action) => {
      const { payload } = action
      state.data = payload.map((item) => {
        const oldFarmData = state.data.find((farmData) => farmData.id === item.id)
        return {
          ...oldFarmData,
          ...item,
        }
      })
      state.loaded = true
    },
    setMysteryBoxAllowances: (state, action) => {
      const { payload } = action
      state.data = payload.map((item: MysteryBoxFactory) => {
        const oldFactory = state.data.find((factory) => factory.id === item.id)
        return {
          ...oldFactory,
          ...item,
        }
      })
      state.userLoaded = true
    },
  },
})

// Actions
export const { setMysteryBoxFactories, setMysteryBoxAllowances } = mysteryBox.actions

export default mysteryBox.reducer
