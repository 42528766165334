import React from "react";
import { TransitionGroup } from "react-transition-group";
import styled from "styled-components";
import Toast from "./Toast";
import { ToastContainerProps } from "./types";

const ZINDEX = 1000;
const TOP_POSITION = 80; // Initial position from the top

const StyledToastContainer = styled.div`
  /* background: red; */
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    height: 0;
  }
  .enter,
  .appear {
    opacity: 0.01;
    transform: translateX(100%);
  }

  .enter.enter-active,
  .appear.appear-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
  }

  .exit {
    opacity: 1;
  }

  .exit.exit-active {
    opacity: 0.01;
    transition: opacity 250ms ease-out;
  }
`;
const StyledToastContainerInner = styled.div<{show?: boolean}>`
  /* height: 150vh; */
  position: relative;
  /* background: pink; */
  margin-bottom: 50px;
  float: right;
  transition: all 250ms ease-in;
  max-width: 100vw;
  width: ${({ show }) => show ? '432px' : '0' };
  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 432px;
  }
`

const ToastContainer: React.FC<ToastContainerProps> = ({ toasts, onRemove }) => {
  const { length } = toasts;
  return (
    <StyledToastContainer>
        <StyledToastContainerInner show={!!length}>
      <TransitionGroup>
          {toasts.map((toast, index) => {
            const zIndex = (ZINDEX - index).toString();
            const top = TOP_POSITION + (length - index - 1) * (toast.stackSpacing || 64);

            return (
              <Toast
                key={toast.id}
                toast={toast}
                onRemove={onRemove}
                ttl={typeof toast.ttl === 'number' ? toast.ttl : 6000}
                style={{ top: `${top}px`, width: toast.width, zIndex }}
                />
            );
          })}
      </TransitionGroup>
        </StyledToastContainerInner>
    </StyledToastContainer>
  );
};

export default ToastContainer;
