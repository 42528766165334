import { useCallback } from 'react'
import { ethers, Contract } from 'ethers'

const useApproveFarm = (lpContract: Contract, lpAddress: string) => {
  const handleApprove = useCallback(async () => {
    try {
      const tx = await lpContract.approve(lpAddress, ethers.constants.MaxUint256)
      const receipt = await tx.wait()
      return receipt.status
    } catch (e) {
      return false
    }
  }, [lpContract, lpAddress])

  return { onApprove: handleApprove }
}

export default useApproveFarm
