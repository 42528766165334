import BigNumber from 'bignumber.js'
import { DEFAULT_TOKEN_DECIMAL } from 'config'
import vDSGTokenABI from 'config/abi/vDSGToken.json'
import multicall from 'utils/multicall'
import { getVDSGTokenAddress } from 'utils/addressHelpers'

const fetchMemberInfo = async () => {
  const VDSGAddress = getVDSGTokenAddress()
  const calls = [
    {
      address: VDSGAddress,
      name: '_mbtTeam', // Function name on the contract (example: balanceOf)
    },
    {
      address: VDSGAddress,
      name: '_mbtRatio', // Function name on the contract (example: balanceOf)
    },
    {
      address: VDSGAddress,
      name: '_mbtFeeBurnRatio', // Function name on the contract (example: balanceOf)
    },
    {
      address: VDSGAddress,
      name: 'alpha', // Function name on the contract (example: balanceOf)
    },
    {
      address: VDSGAddress,
      name: 'getLatestAlpha', // Function name on the contract (example: balanceOf)
    },
    {
      address: VDSGAddress,
      name: '_superiorRatio', // Function name on the contract (example: balanceOf)
    },
    {
      address: VDSGAddress,
      name: 'getMbtWithdrawFeeRatio', // Function name on the contract (example: balanceOf)
    },
    {
      address: VDSGAddress,
      name: '_superiorMinMBT', // Function name on the contract (example: balanceOf)
    },
    {
      address: VDSGAddress,
      name: 'decimals', // Function name on the contract (example: balanceOf)
    },
  ]
  try {
    const res = await multicall(vDSGTokenABI, calls)
    return res
  } catch (error) {
    console.error(error)
    return []
  }
}

export const fetchMemberAlpha = async () => {
  const VDSGAddress = getVDSGTokenAddress()
  const calls = [
    {
      address: VDSGAddress,
      name: 'alpha', // Function name on the contract (example: balanceOf)
    },
    {
      address: VDSGAddress,
      name: '_mbtRatio', // Function name on the contract (example: balanceOf)
    },
  ]
  try {
    const res = await multicall(vDSGTokenABI, calls)
    return res
  } catch (error) {
    console.error(error)
    return []
  }
}

export const fetchMemberSuperiorRatio = async () => {
  const calls = [
    {
      address: getVDSGTokenAddress(),
      name: '_superiorRatio', // Function name on the contract (example: balanceOf)
    },
  ]

  const res = await multicall(vDSGTokenABI, calls)
  return res
}

export default fetchMemberInfo

export const fetchWithdrawResult = async (value: string) => {
  try {
    const amount = new BigNumber(value).times(DEFAULT_TOKEN_DECIMAL).toString()
    const calls = [
      {
        address: getVDSGTokenAddress(),
        name: 'getWithdrawResult',
        params: [amount],
      },
    ]

    const res = await multicall(vDSGTokenABI, calls)
    return res
  } catch (error) {
    console.error(error)
    return []
  }
}
