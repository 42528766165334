const variants = {
  warning: {
    background: "#FFB23719",
    borderColor: "warning",
  },
  danger: {
    background: "#ED4B9E19",
    borderColor: "failure",
  },
};

export default variants;
