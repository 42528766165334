import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import Erc20EarnNftPoolAbi from 'config/abi/Erc20EarnNftPool.json'
import NftEarnErc20PoolAbi from 'config/abi/NftEarnErc20Pool.json'
import dsgnftAbi from 'config/abi/dsgnft.json'
import masterchefABI from 'config/abi/masterchef.json'
import liquidityPoolABI from 'config/abi/liquidityPool.json'
import multicall from 'utils/multicall'
import {
  getAddress,
  getLiquidityPoolAddress,
  getErc20EarnNftPoolAddress,
  getNftEarnErc20PoolAddress,
  getDsgAddress,
} from 'utils/addressHelpers'
import { FarmConfig } from 'config/constants/types'
import { Erc20Farm, NftFarm } from '../types'

export const fetchFarmUserAllowances = async (account: string, farmsToFetch: FarmConfig[]) => {
  try {
    // const masterChefAddress = getLiquidityPoolAddress()
    const calls = farmsToFetch.map((farm) => {
      const lpContractAddress = getAddress(farm.lpAddresses)
      return { address: lpContractAddress, name: 'allowance', params: [account, farm.liquidityPoolAddress] }
    })

    const rawLpAllowances = await multicall(erc20ABI, calls)
    const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
      return lpBalance[0].toJSON()
    })
    return parsedLpAllowances
  } catch (err) {
    return []
  }
}
export const fetchFarmUserAllowancesDsg = async (account: string, lpAddress: string) => {
  try {
    // const masterChefAddress = getLiquidityPoolAddress()
    const calls = [{ address: getDsgAddress(), name: 'allowance', params: [account, lpAddress] }]

    const [rawLpAllowances] = await multicall(erc20ABI, calls)
    return rawLpAllowances[0].toJSON().hex
  } catch (err) {
    return '0'
  }
}
export const fetchErc20FarmUserAllowances = async (account: string, farmsToFetch: Erc20Farm[]) => {
  try {
    const masterChefAddress = getErc20EarnNftPoolAddress()
    const calls = farmsToFetch.map((farm) => {
      return { address: farm.tokenAddress, name: 'allowance', params: [account, masterChefAddress] }
    })

    const rawLpAllowances = await multicall(erc20ABI, calls)
    const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
      return lpBalance[0].toJSON().hex
    })
    return parsedLpAllowances
  } catch (err) {
    return []
  }
}
export const fetchNftsFarmUserAllowances = async (account: string, farmsToFetch: NftFarm[]) => {
  try {
    const masterChefAddress = getNftEarnErc20PoolAddress()
    const calls = farmsToFetch.map((farm) => {
      return { address: getDsgAddress(), name: 'allowance', params: [account, masterChefAddress] }
    })

    const rawLpAllowances = await multicall(erc20ABI, calls)
    const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
      return lpBalance[0].toJSON().hex
    })
    return parsedLpAllowances
  } catch (err) {
    return []
  }
}

export const fetchFarmUserViews = async (account: string) => {
  try {
    const masterChefAddress = getLiquidityPoolAddress()
    const calls = [
      {
        address: masterChefAddress,
        name: 'getUserViews',
        params: [account],
      },
    ]

    const [userViews] = await multicall(liquidityPoolABI, calls)
    const parsedLpAllowances = userViews[0].map((userView) => {
      return {
        accRewardAmount: userView.accRewardAmount.toJSON().hex,
        lpBalance: userView.lpBalance.toJSON().hex,
        stakedAmount: userView.stakedAmount.toJSON().hex,
        earnings: userView.unclaimedRewards.toJSON().hex,
      }
    })
    return parsedLpAllowances
  } catch (err) {
    return []
  }
}

export const fetchFarmUserTokenBalances = async (account: string, farmsToFetch: Erc20Farm[]) => {
  try {
    const calls = farmsToFetch.map((farm) => {
      return {
        address: farm.tokenAddress,
        name: 'balanceOf',
        params: [account],
      }
    })

    const rawTokenBalances = await multicall(erc20ABI, calls)
    const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
      return tokenBalance.balance.toJSON().hex
    })
    return parsedTokenBalances
  } catch (err) {
    return []
  }
}

export const fetchFarmUserStakedBalances = async (account: string, farmsToFetch: FarmConfig[]) => {
  const masterChefAddress = getLiquidityPoolAddress()

  const calls = farmsToFetch.map((farm) => {
    return {
      address: masterChefAddress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawStakedBalances = await multicall(masterchefABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  return parsedStakedBalances
}

export const fetchFarmUserEarnings = async (account: string, farmsToFetch: FarmConfig[]) => {
  const masterChefAddress = getLiquidityPoolAddress()

  const calls = farmsToFetch.map((farm) => {
    return {
      address: masterChefAddress,
      name: 'pendingCake',
      params: [farm.pid, account],
    }
  })

  const rawEarnings = await multicall(masterchefABI, calls)
  const parsedEarnings = rawEarnings.map((earnings) => {
    return new BigNumber(earnings).toJSON()
  })
  return parsedEarnings
}

export const fetchPoolsAllowance = async (account, farmsToFetch: FarmConfig[]) => {
  // const calls = [
  //   {
  //     address: getLiquidityPoolAddress(),
  //     name: 'getUserViews',
  //     params: [account],
  //   }
  // ]
  try {
    const calls = farmsToFetch.map((farm) => {
      return {
        address: farm.liquidityPoolAddress,
        name: 'getUserView',
        params: [getAddress(farm.lpAddresses), account],
      }
    })
    const userInfo = await multicall(liquidityPoolABI, calls)
    const res = userInfo.map((item, index) => {
      return {
        pid: farmsToFetch[index].pid,
        accRewardAmount: item[0].accRewardAmount.toJSON(),
        lpBalance: item[0].lpBalance.toJSON(),
        stakedAmount: item[0].stakedAmount.toJSON(),
        unclaimedRewards: item[0].unclaimedRewards.toJSON(),
        additionalNftId: item[0].additionalNftId.toJSON(),
        additionalRate: item[0].additionalRate.toJSON(),
      }
    })
    return res
  } catch (error) {
    return []
  }
}

export const fetchErc20PoolsUserInfo = async (account, farmsToFetch: Erc20Farm[]) => {
  const erc20EarnNftPoolAddress = getErc20EarnNftPoolAddress()
  try {
    const calls = farmsToFetch.map((farm) => {
      return {
        address: erc20EarnNftPoolAddress,
        name: 'getUserStakes',
        params: [farm.pid, account],
      }
    })
    const userInfo = await multicall(Erc20EarnNftPoolAbi, calls)
    const res = userInfo.map((item, index) => {
      if (item[0].length) {
        let accRewardAmount = new BigNumber(0)
        let unclaimedRewards = 0
        const stakeView = item[0].map((element, sid) => {
          const amount = element.amount.toJSON().hex
          accRewardAmount = accRewardAmount.plus(amount)
          unclaimedRewards += element.isCompleted ? 1 : 0
          return {
            amount,
            sid,
            pid: element.pid.toNumber(),
            isCompleted: element.isCompleted,
            beginTime: element.beginTime.toJSON().hex,
            endTime: element.endTime.toJSON().hex,
          }
        })
        return {
          unclaimedRewards,
          stakeView,
          accRewardAmount: accRewardAmount.toString(),
          pid: farmsToFetch[index].pid,
        }
      }

      return {
        unclaimedRewards: '0',
        stakeView: [],
        accRewardAmount: '0',
        pid: farmsToFetch[index].pid,
      }
    })
    return res
  } catch (error) {
    return []
  }
}

export const fetchNftsPoolsUserInfo = async (account, farmsToFetch: NftFarm[]) => {
  try {
    const calls = farmsToFetch.map((farm) => {
      return {
        address: getNftEarnErc20PoolAddress(),
        name: 'getFullUserInfo',
        params: [account],
      }
    })
    const userInfo = await multicall(NftEarnErc20PoolAbi, calls)
    const res = userInfo.map((item, index) => {
      const slots = item[3].map((slot) => ({
        index: slot[0].toJSON().hex,
        tokenIds: slot[1].map((tokenIds) => tokenIds.toJSON().hex),
      }))
      return {
        slots, // 对应的插槽信息
        share: item[0].toJSON().hex, // 总算力
        nfts: item[1].map((tokenIds) => tokenIds.toJSON().hex), // 普通质押的nft id列表
        slotNum: item[2].toJSON().hex, // 已开通的插槽数
        accRewardAmount_: item[4].toJSON().hex, // 总已领取的奖励
        rewardDebt: item[5].toJSON().hex, // 已领取收益
      }
    })
    return res
  } catch (error) {
    return []
  }
}

export const fetchNftApprovalUser = async (account, farmsToFetch: NftFarm[]) => {
  try {
    const calls = farmsToFetch.map((farm) => {
      return {
        address: farm.nft,
        name: 'isApprovedForAll',
        params: [account, farm.contractAddress],
      }
    })
    const userInfo = await multicall(dsgnftAbi, calls)
    return userInfo.map((item) => item[0])
  } catch (error) {
    return []
  }
}

export const fetchNftPendingUser = async (account: string, farmsToFetch: NftFarm[]) => {
  try {
    const calls = farmsToFetch.map((farm) => {
      return {
        address: farm.contractAddress,
        name: 'pendingToken',
        params: [account],
      }
    })
    const pendingToken = await multicall(NftEarnErc20PoolAbi, calls)
    return pendingToken.map((item) => item[0].toJSON().hex)
  } catch (error) {
    return []
  }
}
