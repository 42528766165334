import aggregatorABI from 'config/abi/aggregator.json'
import multicall from 'utils/multicall'
import { getAggregatorAddress } from 'utils/addressHelpers'

const fetchTreasuryInfo = async () => {
  const calls = [
    {
      address: getAggregatorAddress(),
      name: 'getTreasuryInfo', // Function name on the contract (example: balanceOf)
    },
    {
      address: getAggregatorAddress(),
      name: 'getCirculationSupply', // Function name on the contract (example: balanceOf)
    },
  ]

  const [treasuryInfo, circulationSupply] = await multicall(aggregatorABI, calls)
  return {
    treasuryInfo: {
      nftBonusRatio: treasuryInfo[0].nftBonusRatio.toJSON(),
      investorRatio: treasuryInfo[0].investorRatio.toJSON(),
      totalFee: treasuryInfo[0].totalFee.toJSON(),
      nftBonusAmount: treasuryInfo[0].nftBonusAmount.toJSON(),
      totalDistributedFee: treasuryInfo[0].totalDistributedFee.toJSON(),
      totalBurnedDSG: treasuryInfo[0].totalBurnedDSG.toJSON(),
      totalRepurchasedUSDT: treasuryInfo[0].totalRepurchasedUSDT.toJSON(),
    },
    supply: circulationSupply.supply.toJSON(),
  }
}

export default fetchTreasuryInfo
