import { createElement } from 'react';
import WalletItem from './WalletItem';
import config, { walletLocalStorageKey } from "./config";
import { Config, Login, ToastCustom } from "./types";
interface Props {
  login: Login;
  toastCustom: ToastCustom;
  onDismiss?: () => void;
  displayCount?: number;
}

const getPreferredConfig = (walletConfig: Config[]) => {
  const preferredWalletName = localStorage.getItem(walletLocalStorageKey);
  const sortedConfig = walletConfig.sort((a: Config, b: Config) => a.priority - b.priority);

  if (!preferredWalletName) {
    return sortedConfig;
  }

  const preferredWallet = sortedConfig.find((sortedWalletConfig) => sortedWalletConfig.title === preferredWalletName);

  if (!preferredWallet) {
    return sortedConfig;
  }

  return [
    preferredWallet,
    ...sortedConfig.filter((sortedWalletConfig) => sortedWalletConfig.title !== preferredWalletName),
  ];
};

let show = false;


const connectToast = ({ login, toastCustom, clear, showMore = true, displayCount = 3 }) => {
  if (show) return;
  show = true;
  const closeToast = () => {
    show = false;
    clear();
    window.removeEventListener('click', closeToast);
  }
  const sortedConfig = getPreferredConfig(config);
  const displayListConfig = showMore ? sortedConfig : sortedConfig.slice(0, displayCount);
  displayListConfig.forEach((wallet, index) => {
    setTimeout(() => {
      toastCustom({
        title: '',
        ttl: 0,
        width: '300px',
        stackSpacing: 80,
        hideRemove: true,
        description: createElement(WalletItem, {
          login,
          walletConfig: wallet,
        }, null),
      });
      if (index === displayListConfig.length - 1) {
        window.addEventListener('click', closeToast);
      }
    }, index * 100);
  });
}

export default connectToast;
